import { combineReducers } from 'redux';
import app from './app/duck/reducers';
import admin from './app/pages/Admin/duck/reducers';
import fileUploadReducer from './app/pages/Admin/subPages/AdminImportPage/duck';
import invoicesReducer from './app/pages/InvoicesPage/duck';
import locationsReducer from './app/pages/LocationPage/duck';
import profileReducer from './app/pages/ProfilePage/duck/reducers';
import reservationsReducer from './app/pages/ReservationsPage/duck/reservationReducers';
import searchReducer from './app/pages/SearchPage/duck/reducers';
import situationsReducer from './app/pages/SolutionPage/duck/situationsReducers';
import errorReducer from './app/pages/ErrorPage/duck/reducer';

export default combineReducers({
    profileReducer,
    invoicesReducer,
    fileUploadReducer,
    searchReducer,
    reservationsReducer,
    app,
    locationsReducer,
    admin,
    situations: situationsReducer,
    error: errorReducer,
});
