import React from 'react';
import Button from '../Buttons/Button';
import { CloseModalButton } from '../Buttons/CloseModalButton';
import { IssuesApiCall } from '../IssuesApiCall';
import Modal from '../Modal';
import { H4 } from '../Typography';

const AdminModalConfirmComponent = ({
    ownerId,
    issueCall,
    heading,
    text,
    content,
    onClose,
    showCancelButton,
    theme,
    styleOverride,
}) => (
    <Modal
        closeModal={onClose}
        modalBackground={theme.modalBackgroundColor}
        optionalModalClass={'o-modal--small'}
        onEnterPress={() => {
            if (
                !(
                    // if a button is focused, enter should trigger that button's action.
                    (
                        document.activeElement &&
                        document.activeElement.tagName === 'BUTTON'
                    )
                )
            ) {
                issueCall([ownerId]);
            }
        }}
        styleOverride={styleOverride}
    >
        <section className="adminModal">
            <CloseModalButton className={'o-closeButton'} onClick={onClose} />
            <H4>{heading}</H4>
            {text && (
                <div className="adminModal__body" style={theme.text.body2}>
                    {text}
                </div>
            )}
            {content && content}
            <div className="adminModal__buttonWrapper">
                <Button
                    label={'Bekreft'}
                    onClick={() => {
                        issueCall([ownerId]);
                    }}
                    buttonType={
                        showCancelButton ? 'secondaryButton' : 'primary'
                    }
                />
                {showCancelButton ? (
                    <Button
                        label={'Avbryt'}
                        onClick={onClose}
                        buttonType={'primary'}
                    />
                ) : (
                    ''
                )}
            </div>
        </section>
    </Modal>
);

export const AdminModalConfirm = IssuesApiCall(AdminModalConfirmComponent);
