import classnames from 'classnames';
import React, {
    ChangeEventHandler,
    CSSProperties,
    FocusEventHandler,
} from 'react';
import { uuidv4 } from '../../utilities/utils';
import { H5 } from './Typography';
import { Theme } from '../../utilities/types';
import { connect } from 'react-redux';
import { State } from '../../state';
import { selectTheme } from '../duck/selectors';

type Props = {
    heading?: string | React.ReactElement;
    inlineHeading?: string;
    value: string;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    disabled?: boolean;
    customStyle?: CSSProperties;
    theme: Theme;
};
const AdminTextAreaInputComponent = ({
    heading,
    inlineHeading,
    value,
    onChange,
    onBlur,
    disabled,
    customStyle,
    theme,
}: Props) => {
    const id = uuidv4();
    const textAreaHeader = inlineHeading ? (
        <label
            htmlFor={id}
            className={'o-textAreaWithHeading__inlineHeading'}
            style={theme.text?.inputField__label}
        >
            {inlineHeading}
        </label>
    ) : heading ? (
        <H5
            style={{
                marginBottom: '3px',
            }}
        >
            {heading}
        </H5>
    ) : (
        ''
    );

    return (
        <div
            className={classnames('o-textAreaWithHeading', 'resizable', {
                'o-textAreaWithHeading--disabledTextArea': disabled,
            })}
        >
            {textAreaHeader}
            <textarea
                id={id}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                style={customStyle ? customStyle : {}}
            />
        </div>
    );
};

export const AdminTextAreaInput = connect(
    (state: State) => ({
        theme: selectTheme(state),
    }),
    () => ({})
)(AdminTextAreaInputComponent);
