import React from 'react';

import { ReactComponent as GreenDotIcon } from './icon/green_dot.svg';
import { ReactComponent as MembershipPausedIcon } from './icon/membership_paused.svg';
import { ReactComponent as MembershipBlockedIcon } from './icon/membership_blocked.svg';
import { Membership } from '../types/common';

type Props = {
    membership: Membership;
};

export const MembershipStateIndicator = ({ membership }: Props) => {
    let icon;
    let stateString;

    switch (membership.state) {
        case 'ACTIVE':
            if (membership.externalIdCardVerificationRequired) {
                icon = membership.externalIdCard ? (
                    <MembershipPausedIcon />
                ) : (
                    <MembershipBlockedIcon />
                );
                stateString = membership.externalIdCard
                    ? 'venter på godkjenning av ID-kort'
                    : 'mangler ID-kort';
            } else {
                icon = <GreenDotIcon />;
                stateString = 'er aktivt';
            }
            break;
        case 'PAUSED':
            icon = <MembershipPausedIcon />;
            stateString = 'er satt på pause';
            break;
        case 'PAYMENT_REQUIRED':
            icon = <MembershipBlockedIcon />;
            stateString = 'er ikke betalt';
            break;
        case 'BLOCKED':
            icon = <MembershipBlockedIcon />;
            stateString = 'er sperret';
            break;
        case 'CANCELED':
            icon = <MembershipBlockedIcon />;
            stateString = 'er avsluttet';
            break;
        default:
            break;
    }

    return stateString ? (
        <div
            className="membershipStateIndicator"
            title={`Medlemskapet ${stateString}`}
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {icon}
        </div>
    ) : (
        <></>
    );
};
