import React from 'react';
import { MembershipStateIndicator } from '../MembershipStateIndicator';
import { shortName } from '../../../utilities/utils';
import { ReactComponent as UserSettings } from '../icon/userSettings.svg';
import { ReactComponent as OwnedMembership } from '../icon/owned_membership.svg';
import { ReactComponent as ConnectedMembership } from '../icon/connected_membership.svg';
import { ReactComponent as ArrowDropdown } from '../icon/arrowDropdown.svg';
import { Membership } from '../../types/common';
import { Theme } from '../../../utilities/types';

type Props = {
    theme: Theme;
    membershipOrPersonName?: string;
    selectedMembership?: Membership;
};

export const ProfileMenuButton = (props: Props) => (
    <div
        data-test="profile-menu-button"
        className="headerOptionButton profileMenuButton"
        title="Velg medlemskap, rediger bruker eller logg ut"
    >
        {props.selectedMembership ? (
            props.selectedMembership.isOwner ? (
                <OwnedMembership />
            ) : (
                <ConnectedMembership />
            )
        ) : (
            <UserSettings />
        )}
        <div className="menuText" style={props.theme.text?.icon_menu}>
            {props.membershipOrPersonName
                ? shortName(props.membershipOrPersonName)
                : ''}
        </div>
        {props.selectedMembership &&
            props.selectedMembership.state !== 'ACTIVE' && (
                <MembershipStateIndicator
                    membership={props.selectedMembership}
                />
            )}
        <div className="profileMenuButton__desktopArrow u-displayDesktopOnly">
            <ArrowDropdown />
        </div>
    </div>
);
