import classnames from 'classnames';
import React from 'react';
import { membershipStateTranslator } from '../../../utilities/utils';
import { PAGE_CHOOSE_CAR } from '../../duck/constants';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import { ReactComponent as ConnectedMembership } from '../icon/connected_membership.svg';
import { ReactComponent as OwnedMembership } from '../icon/owned_membership.svg';
import { ReactComponent as Settings } from '../icon/settings.svg';
import { ProfileMenuSection } from './ProfileMenuSection';

/*
TODO FLOW:
type Props = {
    theme: Object,
    auth: Object,
    memberships: Object,
    selectedMembership: Object,
    setSelectedMembership: Object => void,
    logout: () => void,
    setPage: string => void
};
*/

export const ProfileMenu = (props) => (
    <div className="profileMenu" style={props.theme.text.body1}>
        <div className="profileMenu__triangle" />
        <ProfileMenuSection label="Medlemskap">
            {membershipConditionalRender(props)}
        </ProfileMenuSection>

        <ProfileMenuSection label="Innstillinger">
            {renderUserAccountButton(props)}
        </ProfileMenuSection>

        <ProfileMenuSection>
            {renderlogoutLoginButton(props)}
        </ProfileMenuSection>
    </div>
);

const membershipConditionalRender = (props) =>
    props.auth
        ? !props.memberships || props.memberships.length === 0
            ? renderNoMembershipsInfo(props)
            : renderMembershipList(props)
        : '';

const renderNoMembershipsInfo = (props) => (
    <p
        className="profileMenuOption profileMenuOption__noMembershipWarning"
        style={props.theme.text.body2}
    >
        Du er ikke med i noen medlemskap. Du kan opprette et i brukermenyen,
        eller bli invitert av noen andre.
    </p>
);

const renderMembershipList = (props) =>
    props.memberships.map((membership) => {
        let selected =
            props.selectedMembership && props.selectedMembership.id
                ? membership.id === props.selectedMembership.id
                : false;

        let active = membership.state === 'ACTIVE';

        let warning;
        const externalIdCard = membership.externalIdCard,
            externalIdCardVerificationRequired = membership.externalIdCardVerificationRequired;

        if (!active) warning = (
            <span className="u-noWrap"
                  style={{
                      color: props.theme.colors.alert_error,
                  }}
            >
                {membershipStateTranslator(membership.state)}
            </span>
        )
        else if (externalIdCardVerificationRequired)
            warning = (
                <span className={`profileMenuOption__idCardWarning profileMenuOption__idCardWarning--${externalIdCard ? 'pending' : 'missing'} u-noWrap`}>
                    <div className="profileMenuOption__idCardWarningIndicator"/>
                    ID-kort
                </span>
            )

        return (
            <ButtonWithIcon
                buttonType={'PROFILE_MENU_OPTION'}
                theme={props.theme}
                key={membership.id}
                active={selected}
                onClick={() => {
                    props.setSelectedMembership(membership);
                }}
                title={membership.name}
            >
                {membership.isOwner ? (
                    <OwnedMembership className="profileMenuOption__Icon" />
                ) : (
                    <ConnectedMembership className="profileMenuOption__Icon" />
                )}

                <span
                    className={classnames('profileMenuOption__membershipName', {
                        'profileMenuOption__membershipName--nonActive': !active,
                    })}
                >
                    {membership.name}
                </span>
                {warning ? (
                    <div
                        className={
                            'profileMenuOption__membershipBlockedWarning'
                        }
                    >
                        <p
                            className="profileMenuOption__membershipBlockedWarningText"
                        >
                            {warning}
                        </p>
                    </div>
                ) : (
                    ''
                )}
            </ButtonWithIcon>
        );
    });

const renderUserAccountButton = (props) =>
    props.auth && (
        <ButtonWithIcon
            buttonType={
                navigator.onLine
                    ? 'PROFILE_MENU_LINK'
                    : 'PROFILE_MENU_LINK_DISABLED'
            }
            theme={props.theme}
        >
            <Settings />
            Min bruker
        </ButtonWithIcon>
    );

const renderlogoutLoginButton = (props) => (
    <ButtonWithIcon
        buttonType={'PROFILE_MENU_OPTION'}
        onClick={() => {
            if (!props.auth) {
                props.setShouldAuthenticate(true);
            } else {
                props.logout();
            }
            props.setPage(PAGE_CHOOSE_CAR);
        }}
        theme={props.theme}
        testId={'profile-menu.log-out-button'}
    >
        <span className="profileMenuOption__logoutLoginButtonText">
            {props.auth ? 'Logg ut' : 'Logg inn'}
        </span>
    </ButtonWithIcon>
);
