import React from 'react';
import { connect } from 'react-redux';
import { TypographyProps } from './typography';
import { selectTheme } from '../../duck/selectors';
import { State } from '../../../state';

type Props = {
    small?: boolean;
    associated?: boolean;
} & React.DetailedHTMLProps<
    React.LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
> &
    TypographyProps;

const Label = ({
    children,
    className,
    small,
    associated,
    style,
    theme,
    ...rest
}: Props): JSX.Element => {
    const textStyles = small
        ? theme?.text?.body2
        : associated
        ? theme?.text?.associatedText
        : theme?.text?.body1;

    return (
        <label
            className={className}
            style={{ ...textStyles, ...style }}
            {...rest}
        >
            {children}
        </label>
    );
};

const mapStateToProps = (state: State) => ({
    theme: selectTheme(state),
});

export default connect(mapStateToProps, () => ({}))(Label);
