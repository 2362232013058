import classnames from 'classnames';
import React from 'react';
import { ReactComponent as Close } from '../icon/close.svg';

type Props = {
    className?: string;
    onClick: () => void;
    testId?: string;
};

export const CloseModalButton = (props: Props) => (
    <button
        className={classnames('closeModalButton', props.className)}
        onClick={props.onClick}
        data-test={props.testId}
    >
        <Close />
    </button>
);
