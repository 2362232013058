import React, { Component, Fragment } from 'react';

class DropdownMenu extends Component {
    constructor() {
        super();
        this.state = {
            showMenu: false,
        };
    }

    showMenu = () => {
        this.setState({ showMenu: true });
        setTimeout(
            () => document.addEventListener('click', this.closeMenu),
            10
        );
    };

    closeMenu = () => {
        this.setState({ showMenu: false });
        document.removeEventListener('click', this.closeMenu);
    };

    render = () =>
        this.props.disabled ? (
            ''
        ) : (
            <Fragment>
                <button
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                    }}
                    onClick={() => {
                        this.state.showMenu || this.showMenu();
                        this.props.onClick();
                    }}
                >
                    {this.props.menuButton}
                </button>
                {this.state.showMenu ? this.props.children : ''}
            </Fragment>
        );
}

export default DropdownMenu;
