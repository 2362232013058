import { doLog, doUploadFile, getApiCall } from '../../../../../utilities/api';
import {
    selectAuthentication,
    selectSelectedOrganization,
} from '../../../../duck/selectors';
import { createAction, handleActions } from 'redux-actions';
import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../utilities/constants';
import { handleApiCallAndParseData } from '../../../../../utilities/utils';
import { createApiCallObject } from '../../../../../utilities/apiUtils';

const extractFileNameFromContentDisposition = (contentDisposition) =>
    contentDisposition.split('"')[1];

export function fetchImportFileTypes() {
    return (dispatch, getState) => {
        dispatch(actions.fetchImportFileTypesPending());
        return handleApiCallAndParseData(
            getApiCall(
                selectAuthentication(getState()),
                'import',
                createApiCallObject(dispatch, 'fetchLocation'),
                'filetypes',
                true
            ),
            dispatch,
            (parsedData) =>
                dispatch(actions.fetchImportFileTypesCompleted(parsedData)),
            (error) => {
                dispatch(actions.fetchImportFileTypesError());
                console.log(error);
            }
        );
    };
}

export function uploadFiles() {
    return (dispatch, getState) => {
        const fileObjects = selectFileObjects(getState());
        fileObjects.forEach((fileObj, index) => {
            if (fileObj.state === 'ready') dispatch(uploadFile(fileObj, index));
        });
    };
}

function uploadFile(fileObj, index) {
    return (dispatch, getState) => {
        const fileName = fileObj.file.name;
        dispatch(
            actions.uploadFilePending({
                obj: {
                    ...fileObj,
                    state: 'loading',
                },
                index,
            })
        );
        return doUploadFile(
            selectAuthentication(getState()),
            fileObj.fileType,
            selectSelectedOrganization(getState())?.id,
            fileObj.file
        ).then(
            (response) => {
                if (!response.ok) {
                    const errorObj = {
                        fileName,
                        state: 'error',
                        errorText: `${response.status} : ${response.statusText}`,
                    };
                    dispatch(
                        actions.uploadFileError({
                            obj: errorObj,
                            index,
                        })
                    );
                    console.log(errorObj);
                    return errorObj;
                }

                const responseFileName = extractFileNameFromContentDisposition(
                    response.headers.get('content-disposition')
                );
                response.blob().then((blob) => {
                    const url = URL.createObjectURL(blob);
                    const responseObj = {
                        state: 'success',
                        url,
                        responseFileName,
                        fileName,
                    };
                    dispatch(
                        actions.uploadFileCompleted({
                            obj: responseObj,
                            index,
                        })
                    );
                });
            },
            (error) => {
                const errorObj = {
                    fileName,
                    state: 'error',
                    errorText: `Something strange went wrong. If this persists please notify developers.`,
                };
                dispatch(
                    actions.uploadFileError({
                        obj: errorObj,
                        index,
                    })
                );
                doLog(
                    JSON.stringify({
                        note: 'Promise rejected in admin uploadFile operation',
                        errorFromReject: error,
                        fileName,
                        fileType: fileObj.fileType,
                    })
                );
            }
        );
    };
}

export function selectFiles(files, fileType) {
    return (dispatch) => {
        files.forEach((file) => {
            const fileObj = {
                state: 'ready',
                fileName: file.name,
                file,
                fileType,
            };
            dispatch(actions.selectFile({ obj: fileObj }));
        });
    };
}

export function resetUploadFileResult() {
    return (dispatch) => dispatch(actions.resetUploadFileResult());
}

// SELECTORS:
export const selectUploadingFile = (state) =>
    state.fileUploadReducer.uploadingFile;
export const selectUploadFileResult = (state) =>
    state.fileUploadReducer.uploadFileResult;
export const selectUploadFileError = (state) =>
    state.fileUploadReducer.uploadFileError;

export const selectFileObjects = (state) => state.fileUploadReducer.fileObjects;

export const selectFetchingImportFileTypes = (state) =>
    state.fileUploadReducer.fetchingImportFileTypes;
export const selectFetchImportFileTypesResult = (state) =>
    state.fileUploadReducer.fetchImportFileTypesResult;
export const selectImportFileTypes = (state) =>
    state.fileUploadReducer.importFileTypes;

// ACTIONS:
const actions = {
    uploadFilePending: createAction('UPLOAD_FILE_PENDING'),
    uploadFileCompleted: createAction('UPLOAD_FILE_COMPLETED'),
    uploadFileError: createAction('UPLOAD_FILE_ERROR'),
    resetUploadFileResult: createAction('RESET_UPLOAD_FILE_RESULT'),
    fetchImportFileTypesPending: createAction(
        'FETCH_IMPORT_FILE_TYPES_PENDING'
    ),
    fetchImportFileTypesCompleted: createAction(
        'FETCH_IMPORT_FILE_TYPES_COMPLETED'
    ),
    fetchImportFileTypesError: createAction('FETCH_IMPORT_FILE_TYPES_ERROR'),
    selectFile: createAction('SELECT_FILE'),
};

// REDUCER:
const fileUploadReducer = handleActions(
    {
        [actions.selectFile]: (state, action) => ({
            ...state,
            fileObjects: combineFileObjects(
                state.fileObjects,
                action.payload.obj,
                action.payload.index
            ),
        }),

        [actions.uploadFilePending]: (state, action) => ({
            ...state,
            uploadingFile: true,
            uploadFileResult: undefined,
            fileObjects: combineFileObjects(
                state.fileObjects,
                action.payload.obj,
                action.payload.index
            ),
        }),
        [actions.uploadFileCompleted]: (state, action) => ({
            ...state,
            uploadingFile: false,
            uploadFileResult: apiCallSuccess,
            fileObjects: combineFileObjects(
                state.fileObjects,
                action.payload.obj,
                action.payload.index
            ),
        }),
        [actions.uploadFileError]: (state, action) => ({
            ...state,
            uploadingFile: false,
            uploadFileResult: apiCallError,
            fileObjects: combineFileObjects(
                state.fileObjects,
                action.payload.obj,
                action.payload.index
            ),
        }),

        [actions.resetUploadFileResult]: (state) => ({
            ...state,
            uploadingFile: false,
            uploadFileResult: undefined,
            uploadFileError: undefined,
            fileObjects: [],
        }),

        [actions.fetchImportFileTypesPending]: (state) => ({
            ...state,
            fetchingImportFileTypes: true,
            fetchImportFileTypesResult: undefined,
        }),
        [actions.fetchImportFileTypesCompleted]: (state, action) => ({
            ...state,
            fetchingImportFileTypes: false,
            fetchImportFileTypesResult: apiCallSuccess,
            importFileTypes: action.payload,
        }),
        [actions.fetchImportFileTypesError]: (state) => ({
            ...state,
            fetchingImportFileTypes: false,
            fetchImportFileTypesResult: apiCallError,
        }),
    },
    {
        invoices: false,
        fileObjects: [],
    }
);

// Accessing old fileObjects from inside the reducer in order
// to avoid parallel calls overwriting one another
const combineFileObjects = (oldFileObjects, fileObj, index) => {
    let fileObjects = [...oldFileObjects];
    if (index !== undefined) fileObjects[index] = fileObj;
    else fileObjects.push(fileObj);
    return fileObjects;
};

export default fileUploadReducer;
