import {
    apiCallError,
    apiCallSuccess
} from '../../../../../../utilities/constants';
import { handleActions } from 'redux-actions';
import actions from './actions';

const defaultPersonState = {
    fetchingAdminPersons: false,
    fetchAdminPersonsResult: undefined,
    adminPersons: undefined,

    fetchingAdminPersonsMetadata: false,
    fetchAdminPersonsMetadataResult: undefined,
    adminPersonsMetadata: undefined,

    fetchingAdminPerson: false,
    fetchAdminPersonResult: undefined,
    adminPerson: undefined,

    fetchingAdminPersonMemberships: false,
    fetchAdminPersonMembershipsResult: undefined,
    adminPersonMemberships: undefined,

    fetchingAdminPersonCards: false,
    fetchAdminPersonCardsResult: undefined,
    adminPersonCards: undefined,

    removingPersonCard: false,
    removePersonCardResult: undefined,

    addingPersonCard: false,
    addPersonCardResult: undefined,

    puttingAdminPerson: false,
    putAdminPersonResult: undefined,

    creatingPerson: false,
    createPersonResult: undefined,
    newPersonId: undefined,

    disconnectingPersonVippsUser: false,
    disconnectPersonVippsUserResult: undefined,
    disconnectPersonVippsUserErrors: undefined,

    puttingAdminPersonDriversLicenseValid: false,
    putAdminPersonDriversLicenseValidResult: undefined
};

const personReducer = handleActions(
    {
        [actions.fetchAdminPersonsPending]: state => ({
            ...state,
            fetchingAdminPersons: true,
            fetchAdminPersonsResult: undefined
        }),
        [actions.fetchAdminPersonsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminPersons: false,
            fetchAdminPersonsResult: apiCallSuccess,
            adminPersons: action.payload
        }),
        [actions.fetchAdminPersonsError]: state => ({
            ...state,
            fetchingAdminPersons: false,
            fetchAdminPersonsResult: apiCallError
        }),

        [actions.fetchAdminPersonsMetadataPending]: state => ({
            ...state,
            fetchingAdminPersonsMetadata: true,
            fetchAdminPersonsMetadataResult: undefined
        }),
        [actions.fetchAdminPersonsMetadataCompleted]: (state, action) => ({
            ...state,
            fetchingAdminPersonsMetadata: false,
            fetchAdminPersonsMetadataResult: apiCallSuccess,
            adminPersonsMetadata: action.payload
        }),
        [actions.fetchAdminPersonsMetadataError]: state => ({
            ...state,
            fetchingAdminPersonsMetadata: false,
            fetchAdminPersonsMetadataResult: apiCallError
        }),

        [actions.deleteAdminUnusedPersonPending]: state => ({
            ...state,
            deletingAdminUnusedPerson: true,
            deleteAdminUnusedPersonResult: undefined
        }),
        [actions.deleteAdminUnusedPersonCompleted]: state => ({
            ...state,
            deletingAdminUnusedPerson: false,
            deleteAdminUnusedPersonResult: apiCallSuccess
        }),
        [actions.deleteAdminUnusedPersonError]: (state, action) => ({
            ...state,
            deletingAdminUnusedPerson: false,
            deleteAdminUnusedPersonResult: apiCallError,
            deleteAdminUnusedPersonErrors: action.payload
        }),

        [actions.fetchAdminPersonPending]: state => ({
            ...state,
            fetchingAdminPerson: true,
            fetchAdminPersonResult: undefined
        }),
        [actions.fetchAdminPersonCompleted]: (state, action) => ({
            ...state,
            fetchingAdminPerson: false,
            fetchAdminPersonResult: apiCallSuccess,
            adminPerson: action.payload
        }),
        [actions.fetchAdminPersonError]: state => ({
            ...state,
            fetchingAdminPerson: false,
            fetchAdminPersonResult: apiCallError
        }),

        [actions.fetchAdminPersonMembershipsPending]: state => ({
            ...state,
            fetchingAdminPersonMemberships: true,
            fetchAdminPersonMembershipsResult: undefined
        }),
        [actions.fetchAdminPersonMembershipsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminPersonMemberships: false,
            fetchAdminPersonMembershipsResult: apiCallSuccess,
            adminPersonMemberships: action.payload
        }),
        [actions.fetchAdminPersonMembershipsError]: state => ({
            ...state,
            fetchingAdminPersonMemberships: false,
            fetchAdminPersonMembershipsResult: apiCallError
        }),

        [actions.fetchAdminPersonCardsPending]: state => ({
            ...state,
            fetchingAdminPersonCards: true,
            fetchAdminPersonCardsResult: undefined
        }),
        [actions.fetchAdminPersonCardsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminPersonCards: false,
            fetchAdminPersonCardsResult: apiCallSuccess,
            adminPersonCards: action.payload
        }),
        [actions.fetchAdminPersonCardsError]: state => ({
            ...state,
            fetchingAdminPersonCards: false,
            fetchAdminPersonCardsResult: apiCallError
        }),

        [actions.fetchAdminPersonCardPending]: state => ({
            ...state,
            fetchingAdminPersonCard: true,
            fetchAdminPersonCardResult: undefined
        }),
        [actions.fetchAdminPersonCardCompleted]: state => ({
            ...state,
            fetchingAdminPersonCard: false,
            fetchAdminPersonCardResult: apiCallSuccess
        }),
        [actions.fetchAdminPersonCardError]: state => ({
            ...state,
            fetchingAdminPersonCard: false,
            fetchAdminPersonCardResult: apiCallError
        }),

        [actions.showAddPersonCardConfirmationModal]: (state, action) => ({
            ...state,
            showAddPersonCardConfirmationModal: action.payload
        }),
        [actions.closeAddPersonCardConfirmationModal]: state => ({
            ...state,
            showAddPersonCardConfirmationModal: undefined
        }),

        [actions.removePersonCardPending]: state => ({
            ...state,
            removingPersonCard: true,
            removePersonCardResult: undefined
        }),
        [actions.removePersonCardCompleted]: state => ({
            ...state,
            removingPersonCard: false,
            removePersonCardResult: apiCallSuccess
        }),
        [actions.removePersonCardError]: state => ({
            ...state,
            removingPersonCard: false,
            removePersonCardResult: apiCallError
        }),

        [actions.changePersonPasswordPending]: state => ({
            ...state,
            changingPersonPassword: true,
            changePersonPasswordResult: undefined
        }),
        [actions.changePersonPasswordCompleted]: state => ({
            ...state,
            changingPersonPassword: false,
            changePersonPasswordResult: apiCallSuccess
        }),
        [actions.changePersonPasswordError]: state => ({
            ...state,
            changingPersonPassword: false,
            changePersonPasswordResult: apiCallError
        }),

        [actions.disconnectPersonVippsUserPending]: state => ({
            ...state,
            disconnectingPersonVippsUser: true,
            disconnectPersonVippsUserResult: undefined,
            disconnectPersonVippsUserError: undefined
        }),
        [actions.disconnectPersonVippsUserCompleted]: state => ({
            ...state,
            disconnectingPersonVippsUser: false,
            disconnectPersonVippsUserResult: apiCallSuccess,
            adminPerson: {
                ...state.adminPerson,
                oidcId: null
            }
        }),
        [actions.disconnectPersonVippsUserError]: (state, action) => ({
            ...state,
            disconnectingPersonVippsUser: false,
            disconnectPersonVippsUserResult: apiCallError,
            disconnectPersonVippsUserError: action.payload
        }),
        [actions.disconnectPersonVippsUserResetError]: state => ({
            ...state,
            disconnectPersonVippsUserResult: undefined,
            disconnectPersonVippsUserError: undefined
        }),

        [actions.resetChangePersonPasswordResponse]: state => ({
            ...state,
            changePersonPasswordResult: undefined
        }),

        [actions.addPersonCardPending]: state => ({
            ...state,
            addingPersonCard: true,
            addPersonCardResult: undefined
        }),
        [actions.addPersonCardCompleted]: state => ({
            ...state,
            addingPersonCard: false,
            addPersonCardResult: apiCallSuccess
        }),
        [actions.addPersonCardError]: state => ({
            ...state,
            addingPersonCard: false,
            addPersonCardResult: apiCallError
        }),

        [actions.putAdminPersonPending]: state => ({
            ...state,
            puttingAdminPerson: true,
            putAdminPersonResult: undefined,
            putAdminPersonErrors: undefined
        }),
        [actions.putAdminPersonCompleted]: state => ({
            ...state,
            puttingAdminPerson: false,
            putAdminPersonResult: apiCallSuccess,
            putAdminPersonErrors: undefined
        }),
        [actions.putAdminPersonError]: (state, action) => ({
            ...state,
            puttingAdminPerson: false,
            putAdminPersonResult: apiCallError,
            putAdminPersonErrors: action.payload
        }),
        [actions.putAdminPersonReset]: state => ({
            ...state,
            puttingAdminPerson: false,
            putAdminPersonResult: undefined,
            putAdminPersonErrors: undefined
        }),
        [actions.createAdminPersonPending]: state => ({
            ...state,
            creatingPerson: true,
            createPersonResult: undefined
        }),
        [actions.createAdminPersonCompleted]: (state, action) => ({
            ...state,
            creatingPerson: false,
            createPersonResult: apiCallSuccess,
            newPersonId: action.payload.id
        }),
        [actions.createAdminPersonError]: state => ({
            ...state,
            creatingPerson: false,
            createPersonResult: apiCallError
        }),

        [actions.putAdminPersonDriversLicenceValidPending]: state => ({
            ...state,
            puttingAdminPersonDriversLicenseValid: true,
            putAdminPersonDriversLicenseValidResult: undefined
        }),
        [actions.putAdminPersonDriversLicenceValidCompleted]: (state, action) => ({
            ...state,
            puttingAdminPersonDriversLicenseValid: false,
            putAdminPersonDriversLicenseValidResult: apiCallSuccess,
            adminPerson: action.payload
        }),
        [actions.putAdminPersonDriversLicenceValidError()]: state => ({
            ...state,
            puttingAdminPersonDriversLicenseValid: false,
            putAdminPersonDriversLicenseValidResult: apiCallError
        }),

        CLEAR_STATE: () => defaultPersonState
    },
    defaultPersonState
);

export default personReducer;
