import classnames from 'classnames';
import React from 'react';
import { generateInputId } from '../../../utilities/utils';

type Props = {
    wrapperClass?: string;
    checked?: boolean;
    infoTextElement?: any;
    onClick?: () => void;
    disabled?: boolean;
    inline?: boolean;
};

export const CheckBox = ({
    wrapperClass,
    checked,
    infoTextElement,
    onClick,
    disabled,
    inline,
}: Props) => {
    const id = generateInputId();
    return (
        <div className={classnames('checkBox__container', { inline: inline })}>
            <label
                htmlFor={id}
                className={classnames('checkBox', wrapperClass, {
                    disabled: disabled,
                })}
            >
                <div className="checkBoxWrapper">
                    <input
                        id={id}
                        onChange={() => disabled || !onClick || onClick()}
                        type="checkbox"
                        checked={checked}
                        readOnly={true}
                    />
                    <div className="checkBoxWrapper__checkbox" />
                </div>
                {infoTextElement}
            </label>
        </div>
    );
};
