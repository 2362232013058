export const selectTheme = (state) => state.app.ui.theme;
export const selectShowHamburgerMenu = (state) =>
    state.app.ui.showHamburgerMenu;
export const selectHeading = (state) => state.app.ui.heading;

/* Pagination selectors */
export const selectFetchingNewPage = (state) =>
    state.app.pagination.fetchingNewPage;
export const selectLastPage = (state) => state.app.pagination.lastPage;
export const selectPageNumber = (state) => state.app.pagination.pageNumber;

export const selectJoinPending = (state) => state.app.data.joinPending;
export const selectJoinResult = (state) => state.app.data.joinResult;
export const selectJoinedMembership = (state) =>
    state.app.data.joinedMembership;
export const selectJoinErrors = (state) => state.app.data.joinErrors;

export const selectGcreAuthPending = (state) => state.app.data.gcreAuthPending;
export const selectGcreAuthResult = (state) => state.app.data.gcreAuthResult;
export const selectGcreAuthResponse = (state) =>
    state.app.data.gcreAuthResponse;

export const selectAuthentication = (state) => state.app.data.authentication;
export const selectShouldAuthenticate = (state) =>
    state.app.data.shouldAuthenticate;

export const selectVippsJoinPending = (state) =>
    state.app.data.vippsJoinPending;
export const selectVippsJoinResult = (state) => state.app.data.vippsJoinResult;
export const selectVippsJoinErrors = (state) => state.app.data.vippsJoinErrors;

export const selectLinkVippsUserPending = (state) =>
    state.app.data.linkVippsUserPending;
export const selectLinkVippsUserResult = (state) =>
    state.app.data.linkVippsUserResult;
export const selectLinkVippsUserErrors = (state) =>
    state.app.data.linkVippsUserErrors;

export const selectShowIncorrectLoginFeedback = (state) =>
    state.app.ui.showIncorrectLoginFeedback;
export const selectPerformAfterLogin = (state) =>
    state.app.data.performAfterLogin;
export const selectUserName = (state) => state.app.data.userName;
export const selectAdmin = (state) => state.app.data.admin;

export const selectSendPasswordResetEmailPending = (state) =>
    state.app.data.sendPasswordResetEmailPending;
export const selectSendPasswordResetEmailResult = (state) =>
    state.app.data.sendPasswordResetEmailResult;

export const selectResetPasswordPending = (state) =>
    state.app.data.resetPasswordPending;
export const selectResetPasswordResult = (state) =>
    state.app.data.resetPasswordResult;

export const selectPage = (state) => state.app.ui.page;

export const selectInitializingAppCompleted = (state) => {
    let appState = state.app.data;
    return appState.initApp__filtersCompleted;
};

export const selectWhoamiResponse = (state) => state.app.data.whoamiResponse;

export const selectPermissions = (state) =>
    state.app.data.whoamiResponse?.organizationPermissions ?? [];

export const selectOrganizations = (state) =>
    state.app.data.organizations || [];

export const selectOrganizationForReservation = (state) => {
    if (!state.reservationsReducer.data.reservation) return;
    const organizations = selectOrganizations(state);
    for (const organization of organizations) {
        if (
            organization.id ===
            state.reservationsReducer.data.reservation.organizationId
        )
            return organization;
    }
};

export const selectSelectedOrganization = (state) => {
    if (state.app.data.selectedOrganization) {
        return state.app.data.selectedOrganization;
    }
    const organizations = selectOrganizations(state);
    if (organizations.length === 1) {
        return organizations[0];
    }
};

export const selectMemberships = (state) =>
    selectWhoamiResponse(state) ? selectWhoamiResponse(state).memberships : [];

export const selectFetchingOrganizations = (state) =>
    state.app.data.fetchingOrganizations;

export const selectFetchOrganizationsResult = (state) =>
    state.app.data.fetchOrganizationsResult;

export const selectSelectedMembership = (state) =>
    state.app.data.selectedMembership;

export const selectSelectedMembershipId = (state) => {
    return selectSelectedMembership(state).id;
};

export const selectMembershipOrPersonName = (state) => {
    return (
        selectSelectedMembership(state)?.name ??
        selectWhoamiResponse(state)?.person?.name ??
        ' '
    );
};

export const selectSortedMemberships = (state) => {
    const m = selectMemberships(state);
    if (!m) return [];
    return [...m].sort((m1, m2) =>
        m1.isOwner && !m2.isOwner ? -1 : m2.isOwner && !m1.isOwner ? 1 : 0
    );
};

export const selectSwapCarReservation = (state) =>
    state.app.swapCar.swapCarReservation;
export const selectSwappingCar = (state) => state.app.swapCar.swappingCar;
export const selectSwapCarResult = (state) => state.app.swapCar.swapCarResult;
export const selectSwapCarErrorMessage = (state) =>
    state.app.swapCar.swapCarErrorMessage;

export const selectSwapCarPosition = (state) =>
    state.app.swapCar.swapCarPosition;
export const selectSwapCarTimes = (state) => state.app.swapCar.swapCarTimes;

export const selectSwapCarFilters = (state) => state.app.swapCar.swapCarFilters;
export const selectSwapCarFiltersAreActive = (state) =>
    state.app.swapCar.swapCarFiltersAreActive;
