import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Theme } from '../../../utilities/types';
import Button from '../../common/Buttons/Button';
import { H2 } from '../../common/Typography';
import './CoronaPopup.css';
import { ReactComponent as CoronaSvg } from './virus_filled.svg';

type CoronaPopupProps = {
    theme: Partial<Theme>;
};

export const CoronaPopup = (props: CoronaPopupProps) => {
    const [show, setShow] = useState(true);
    const [english, setEnglish] = useState(false);

    const ok = () => {
        localStorage.setItem('corona_ts', moment().format());
        setShow(false);
    };

    useEffect(() => {
        const corona_ts = localStorage.getItem('corona_ts');
        if (corona_ts) {
            const nextWarnDay = moment(corona_ts)
                .hours(0)
                .minutes(0)
                .seconds(0)
                .milliseconds(0)
                .add(1, 'week');
            const today = moment().startOf('day');
            if (nextWarnDay > today) {
                setShow(false);
                return;
            }
        }

        const corona_english = JSON.parse(
            localStorage.getItem('corona_english') as string
        );
        if (corona_english !== english) {
            setEnglish(corona_english);
        }
    }, [english]);

    const toggleEnglish = () => {
        localStorage.setItem('corona_english', String(!english));
        setEnglish(!english);
    };

    const heading = english ? 'Corona virus' : 'Koronavirus';
    const warning = english ? (
        <>
            <p>
                If you are quarantined or feel unwell you must not use our cars.
            </p>
            <p>Doing so puts others at risk.</p>
        </>
    ) : (
        <>
            <p>
                Hvis du er i karantene eller ikke føler deg vel skal du ikke
                bruke våre biler.
            </p>
            <p>Det setter andres liv og helse i fare.</p>
        </>
    );
    const buttonLabel = english ? 'I understand' : 'Jeg forstår';

    return show ? (
        <div className="coronaPopup" data-testid="coronaPopup">
            <div className="coronaPopupContent" style={props.theme.text?.body1}>
                <Button
                    buttonType={'basicHoverButton'}
                    className={
                        'coronaLanguageButton smallRoundedButton greyButton'
                    }
                    onClick={toggleEnglish}
                    styleOverride={{
                        margin: '8px auto 4px auto',
                    }}
                    label={english ? 'Norsk' : 'English'}
                />

                <H2 className="coronaHeader">{heading}</H2>
                <CoronaSvg />
                <div className="coronaWarning" style={props.theme.text?.body1}>
                    {warning}
                </div>
                <div className="coronaButtonWrapper">
                    <Button
                        buttonType={'basicHoverButton'}
                        className={'coronaButton smallRoundedButton redButton'}
                        onClick={ok}
                        styleOverride={{
                            margin: '8px auto 4px auto',
                        }}
                        label={buttonLabel}
                    />
                </div>
            </div>
        </div>
    ) : null;
};
