import { createAction } from 'redux-actions';

const updatingData = createAction('UPDATING_DATA');

const fetchCarsPending = createAction('FETCH_CARS_PENDING');
const fetchCarsCompleted = createAction('FETCH_CARS_COMPLETED');
const fetchCarsError = createAction('FETCH_CARS_ERROR');

const fetchSwappableCarsPending = createAction('FETCH_SWAPPABLE_CARS_PENDING');
const fetchSwappableCarsCompleted = createAction(
    'FETCH_SWAPPABLE_CARS_COMPLETED'
);
const fetchSwappableCarsError = createAction('FETCH_SWAPPABLE_CARS_ERROR');

const checkConcurrentReservationLimitPending = createAction(
    'CHECK_CONCURRENT_RESERVATION_LIMIT_PENDING'
);
const checkConcurrentReservationLimitCompleted = createAction(
    'CHECK_CONCURRENT_RESERVATION_LIMIT_COMPLETED'
);
const checkConcurrentReservationLimitError = createAction(
    'CHECK_CONCURRENT_RESERVATION_LIMIT_ERROR'
);

const setShowingSwapCarOptions = createAction('SET_SHOWING_SWAP_CAR_OPTIONS');

const setMapPosition = createAction('SET_MAP_POSITION');

const setPreviouslySelectedLocations = createAction(
    'SET_PREVIOUSLY_SELECTED_LOCATIONS'
);

const setLocations = createAction('SET_LOCATION');

const fetchExternalLocationsPending = createAction(
    'FETCH_EXTERNAL_LOCATIONS_PENDING'
);
const fetchExternalLocationsCompleted = createAction(
    'FETCH_EXTERNAL_LOCATIONS_COMLETED'
);
const fetchExternalLocationsError = createAction(
    'FETCH_EXTERNAL_LOCATIONS_ERROR'
);
const setLocationSearchString = createAction('SET_LOCATION_SEARCH_STRING');

const toggleCarFilterView = createAction('TOGGLE_SHOW_CAR_FILTER_VIEW');

const initializeSearch = createAction('INIT_SEARCH');

const updateTimes = createAction('UPDATE_TIMES');

const selectCar = createAction('SELECT_CAR');
const hideCarModal = createAction('HIDE_CAR_MODAL');

const setSelectedPosition = createAction('SET_SELECTED_POSITION');

// booking actions
const bookingReservationPending = createAction('BOOKING_RESERVATION_PENDING');
const bookingReservationCompleted = createAction(
    'BOOKING_RESERVATION_COMPLETED'
);
const bookingReservationError = createAction('BOOKING_RESERVATION_ERROR');

const bookingConfirmationPending = createAction('BOOKING_CONFIRMATION_PENDING');
const bookingConfirmationCompleted = createAction(
    'BOOKING_CONFIRMATION_COMPLETED'
);
const bookingConfirmationError = createAction('BOOKING_CONFIRMATION_ERROR');
const resetBookingConfirmationError = createAction(
    'RESET_BOOKING_CONFIRMATION_ERROR'
);

const fetchLocationDistancesPending = createAction(
    'FETCH_LOCATION_DISTANCES_PENDING'
);
const fetchLocationDistancesCompleted = createAction(
    'FETCH_LOCATION_DISTANCES_COMPLETED'
);
const fetchLocationDistancesError = createAction(
    'FETCH_LOCATION_DISTANCES_ERROR'
);

const setGpsPositioningDisabled = createAction('SET_GPS_LOCATION_DISABLED');

const gettingGpsPositionPending = createAction(
    'ACCESSING_GPS_POSITION_PENDING'
);
const gettingGpsPositionCompleted = createAction(
    'ACCESSING_GPS_POSITION_SUCCEEDED'
);
const gettingGpsPositionError = createAction('ACCESSING_GPS_POSITION_ERROR');

const fetchInternalLocationDistancesPending = createAction(
    'FETCH_INTERNAL_LOCATION_DISTANCE_PENDING'
);
const fetchInternalLocationDistancesCompleted = createAction(
    'FETCH_INTERNAL_LOCATION_DISTANCE_COMPLETED'
);
const fetchInternalLocationDistancesError = createAction(
    'FETCH_INTERNAL_LOCATION_DISTANCE_ERROR'
);

const fetchFiltersPending = createAction('FETCH_FILTERS_PENDING');
const fetchFiltersCompleted = createAction('FETCH_FILTERS_COMPLETED');
const fetchFiltersError = createAction('FETCH_FILTERS_ERROR');

const setFilters = createAction('SET_FILTERS');

const setFiltersAreActive = createAction('SET_FILTERS_ARE_ACTIVE');

const setHideUnavailableCars = createAction('SET_HIDE_UNAVAILABLE_CARS');

const fetchPriceOfCurrentCarCompleted = createAction(
    'FETCH_PRICE_OF_CURRENT_CAR_COMPLETED'
);
const fetchPriceOfCurrentCarError = createAction(
    'FETCH_PRICE_OF_CURRENT_CAR_ERROR'
);
const fetchPriceOfCurrentCarPending = createAction(
    'FETCH_PRICE_OF_CURRENT_CAR_PENDING'
);

// AvailabilityPage actions

const initAvailabilityPending = createAction('INIT_AVAILABILITY_PENDING');
const initAvailabilityCompleted = createAction('INIT_AVAILABILITY_COMPLETED');

const setAvailabilityStartTime = createAction('SET_AVAILABILITY_START_TIME');
const setAvailabilityEndTime = createAction('SET_AVAILABILITY_END_TIME');

const fetchAvailabilityInfoPending = createAction(
    'FETCH_AVAILABILITY_INFO_PENDING'
);
const fetchAvailabilityInfoError = createAction(
    'FETCH_AVAILABILITY_INFO_ERROR'
);
const fetchAvailabilityInfoCompleted = createAction(
    'FETCH_AVAILABILITY_INFO_COMPLETED'
);

const fetchAvailabilityReservationsPending = createAction(
    'FETCH_AVAILABILITY_RESERVATIONS_PENDING'
);
const fetchAvailabilityReservationsCompleted = createAction(
    'FETCH_AVAILABILITY_RESERVATIONS_COMPLETED'
);
const fetchAvailabilityReservationsError = createAction(
    'FETCH_AVAILABILITY_RESERVATIONS_ERROR'
);

const setSuperDamageWaiver = createAction('SET_SUPER_DAMAGE_WAIVER');

const setKilometerEstimate = createAction('SET_KILOMETER_ESTIMATE');

const setShowCityBikes = createAction('SET_SHOW_CITY_BIKES');
const setCityBikes = createAction('SET_CITY_BIKES');

const actions = {
    updatingData,
    fetchCarsPending,
    fetchCarsCompleted,
    fetchCarsError,
    fetchSwappableCarsPending,
    fetchSwappableCarsCompleted,
    fetchSwappableCarsError,
    checkConcurrentReservationLimitPending,
    checkConcurrentReservationLimitCompleted,
    checkConcurrentReservationLimitError,
    setShowingSwapCarOptions,
    setMapPosition,
    fetchLocationDistancesPending,
    fetchLocationDistancesCompleted,
    fetchLocationDistancesError,
    setPreviouslySelectedLocations,
    setLocationSearchString,
    setLocations,
    setGpsPositioningDisabled,
    gettingGpsPositionPending,
    gettingGpsPositionCompleted,
    gettingGpsPositionError,
    fetchExternalLocationsPending,
    fetchExternalLocationsCompleted,
    fetchExternalLocationsError,
    fetchInternalLocationDistancesPending,
    fetchInternalLocationDistancesCompleted,
    fetchInternalLocationDistancesError,
    initializeSearch,
    updateTimes,
    toggleCarFilterView,
    setSelectedPosition,
    selectCar,
    hideCarModal,
    bookingReservationPending,
    bookingReservationCompleted,
    bookingReservationError,
    bookingConfirmationPending,
    bookingConfirmationCompleted,
    bookingConfirmationError,
    resetBookingConfirmationError,
    fetchFiltersPending,
    fetchFiltersCompleted,
    fetchFiltersError,
    setFilters,
    setFiltersAreActive,
    setHideUnavailableCars,
    fetchPriceOfCurrentCarCompleted,
    fetchPriceOfCurrentCarPending,
    fetchPriceOfCurrentCarError,
    setAvailabilityStartTime,
    setAvailabilityEndTime,
    fetchAvailabilityInfoPending,
    fetchAvailabilityInfoError,
    fetchAvailabilityInfoCompleted,
    fetchReservedDaysPending: fetchAvailabilityReservationsPending,
    fetchReservedDaysError: fetchAvailabilityReservationsError,
    fetchReservedDaysCompleted: fetchAvailabilityReservationsCompleted,
    initAvailabilityPending,
    initAvailabilityCompleted,
    setSuperDamageWaiver,
    setKilometerEstimate,
    setShowCityBikes,
    setCityBikes,
};

export default actions;
