import 'react-app-polyfill/ie9';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';
import reducers from './reducers';
import PageLoader from './PageLoader';
import { composeWithDevTools } from 'redux-devtools-extension';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { wrapperBridgeExists } from './utilities/wrapperUtils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

if (wrapperBridgeExists()) {
    LogRocket.init('rsve9o/nodeleapp');
    setupLogRocketReact(LogRocket);
}

// const reactAppEnvironment = process.env.REACT_APP_ENVIRONMENT;
// console.log('process.env :' + JSON.stringify(process.env));
// console.log('reactAppEnvironment: ' + reactAppEnvironment);

const App = lazy(() => import('./app'));

const logConfig = {
    collapsed: true,
};

const createStoreWithMiddleware = composeWithDevTools(
    applyMiddleware(thunk, createLogger(logConfig), LogRocket.reduxMiddleware())
)(createStore);

export const store = createStoreWithMiddleware(reducers);

const queryClient = new QueryClient();

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <PersistGate loading={<div />} persistor={persistStore(store)}>
                <Suspense fallback={<PageLoader />}>
                    <App />
                </Suspense>
            </PersistGate>
        </QueryClientProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();
