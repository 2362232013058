import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { selectTheme } from '../../../duck/selectors';
import { State } from '../../../../state';

interface Props {
    children: ReactNode;
    label?: string;
}

const ProfileMenuSection = ({ children, label }: Props) => {
    return (
        <div className="profileMenuSection">
            {!!label && (
                <div className="profileMenuSection__header">
                    <span className="profileMenuSection__label">{label}</span>
                </div>
            )}

            <div className="profileMenuSection__content">{children}</div>
        </div>
    );
};

const mapStateToProps = (state: State) => ({
    theme: selectTheme(state),
});

export default connect(mapStateToProps)(ProfileMenuSection);
