import { handleActions } from 'redux-actions';
import actions from './actions';
import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../../utilities/constants';

const defaultDamageState = {
    fetchingDamage: false,
    fetchDamageResult: undefined,
    damage: undefined,

    fetchingMembershipDamages: false,
    fetchMembershipDamagesResult: undefined,
    membershipDamages: undefined,

    fetchingDamages: false,
    fetchDamagesResult: undefined,
    damages: undefined,

    fetchingDamagesMetadata: false,
    fetchDamagesMetadataResult: undefined,
    damagesMetadata: undefined,

    addingDamagePicture: false,
    addDamagePictureResult: undefined,
    newDamagePictures: [],
};

const damageReducer = handleActions(
    {
        [actions.updateDamage]: (state, action) => ({
            ...state,
            damage: action.payload,
        }),

        [actions.createAdminDamagePending]: (state) => ({
            ...state,
            creatingDamage: true,
            createDamageResult: undefined,
        }),
        [actions.createAdminDamageCompleted]: (state, action) => ({
            ...state,
            creatingDamage: false,
            createDamageResult: apiCallSuccess,
            damage: action.payload,
        }),
        [actions.createAdminDamageError]: (state) => ({
            ...state,
            creatingDamage: false,
            createDamageResult: apiCallError,
        }),

        [actions.resetCreateAdminDamageResult]: (state) => ({
            ...state,
            createDamageResult: undefined,
        }),

        [actions.deleteAdminDamagePending]: (state) => ({
            ...state,
            deleteDamageResult: undefined,
        }),
        [actions.deleteAdminDamageCompleted]: (state) => ({
            ...state,
            deleteDamageResult: apiCallSuccess,
            damage: undefined,
        }),
        [actions.deleteAdminDamageError]: (state) => ({
            ...state,
            deleteDamageResult: apiCallError,
        }),

        [actions.fetchingDamagesPending]: (state) => ({
            ...state,
            fetchingDamages: true,
            fetchDamagesResult: undefined,
        }),
        [actions.fetchingDamagesCompleted]: (state, action) => ({
            ...state,
            fetchingDamages: false,
            fetchDamagesResult: apiCallSuccess,
            damages: action.payload,
        }),
        [actions.fetchingDamagesError]: (state) => ({
            ...state,
            fetchingDamages: false,
            fetchDamagesResult: apiCallError,
        }),

        [actions.fetchingDamagesMetadataPending]: (state) => ({
            ...state,
            fetchingDamagesMetadata: true,
            fetchDamagesMetadataResult: undefined,
        }),
        [actions.fetchingDamagesMetadataCompleted]: (state, action) => ({
            ...state,
            fetchingDamagesMetadata: false,
            fetchDamagesMetadataResult: apiCallSuccess,
            damagesMetadata: action.payload,
        }),
        [actions.fetchingDamagesMetadataError]: (state) => ({
            ...state,
            fetchingDamagesMetadata: false,
            fetchDamagesMetadataResult: apiCallError,
        }),

        [actions.fetchingMembershipDamagesPending]: (state) => ({
            ...state,
            fetchingMembershipDamages: true,
            fetchMembershipDamagesResult: undefined,
        }),
        [actions.fetchingMembershipDamagesCompleted]: (state, action) => ({
            ...state,
            fetchingMembershipDamages: false,
            fetchMembershipDamagesResult: apiCallSuccess,
            membershipDamages: action.payload,
        }),
        [actions.fetchingMembershipDamagesError]: (state) => ({
            ...state,
            fetchingMembershipDamages: false,
            fetchMembershipDamagesResult: apiCallError,
        }),

        [actions.fetchingDamagePending]: (state) => ({
            ...state,
            fetchingDamage: true,
            fetchDamageResult: undefined,
        }),
        [actions.fetchingDamageCompleted]: (state, action) => ({
            ...state,
            fetchingDamage: false,
            fetchDamageResult: apiCallSuccess,
            damage: action.payload,
        }),
        [actions.fetchingDamageError]: (state) => ({
            ...state,
            fetchingDamage: false,
            fetchDamageResult: apiCallError,
        }),

        [actions.updateDamagePending]: (state) => ({
            ...state,
            updatingDamage: true,
            updateDamageResult: undefined,
        }),
        [actions.updateDamageCompleted]: (state, action) => ({
            ...state,
            updatingDamage: false,
            updateDamageResult: apiCallSuccess,
            damage: action.payload,
        }),
        [actions.updateDamageError]: (state) => ({
            ...state,
            updatingDamage: false,
            updateDamageResult: apiCallError,
        }),

        [actions.postDamagePicturePending]: (state, action) => ({
            ...state,
            addingDamagePicture: true,
            addDamagePictureResult: undefined,
            damage: {
                ...state.damage,
                pictures: action.payload,
            },
        }),
        [actions.postDamagePictureCompleted]: (state, action) => ({
            ...state,
            addingDamagePicture: false,
            addDamagePictureResult: apiCallSuccess,
            damage: {
                ...state.damage,
                pictures: action.payload,
            },
        }),
        [actions.postDamagePictureError]: (state) => ({
            ...state,
            addingDamagePicture: false,
            addDamagePictureResult: apiCallError,
        }),

        CLEAR_STATE: () => defaultDamageState,
    },
    defaultDamageState
);

export default damageReducer;
