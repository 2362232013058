import { createAction } from 'redux-actions';

const fetchAdminPersonsPending = createAction('FETCH_ADMIN_PERSONS_PENDING');
const fetchAdminPersonsCompleted = createAction(
    'FETCH_ADMIN_PERSONS_COMPLETED'
);
const fetchAdminPersonsError = createAction('FETCH_ADMIN_PERSONS_ERROR');

const fetchAdminPersonsMetadataPending = createAction(
    'FETCH_ADMIN_PERSONS_METADATA_PENDING'
);
const fetchAdminPersonsMetadataCompleted = createAction(
    'FETCH_ADMIN_PERSONS_METADATA_COMPLETED'
);
const fetchAdminPersonsMetadataError = createAction(
    'FETCH_ADMIN_PERSONS_METADATA_ERROR'
);

const deleteAdminUnusedPersonPending = createAction(
    'DELETE_ADMIN_UNUSED_PERSON_PENDING'
);
const deleteAdminUnusedPersonCompleted = createAction(
    'DELETE_ADMIN_UNUSED_PERSON_COMPLETED'
);
const deleteAdminUnusedPersonError = createAction(
    'DELETE_ADMIN_UNUSED_PERSON_ERROR'
);

const fetchAdminPersonPending = createAction('FETCH_ADMIN_PERSON_PENDING');
const fetchAdminPersonCompleted = createAction('FETCH_ADMIN_PERSON_COMPLETED');
const fetchAdminPersonError = createAction('FETCH_ADMIN_PERSON_ERROR');

const fetchAdminPersonMembershipsPending = createAction(
    'FETCH_ADMIN_PERSON_MEMBERSHIPS_PENDING'
);
const fetchAdminPersonMembershipsCompleted = createAction(
    'FETCH_ADMIN_PERSON_MEMBERSHIPS_COMPLETED'
);
const fetchAdminPersonMembershipsError = createAction(
    'FETCH_ADMIN_PERSON_MEMBERSHIPS_ERROR'
);

const fetchAdminPersonCardsPending = createAction(
    'FETCH_ADMIN_PERSON_CARDS_PENDING'
);
const fetchAdminPersonCardsCompleted = createAction(
    'FETCH_ADMIN_PERSON_CARDS_COMPLETED'
);
const fetchAdminPersonCardsError = createAction(
    'FETCH_ADMIN_PERSON_CARDS_ERROR'
);

const changePersonPasswordPending = createAction(
    'CHANGE_PERSON_PASSWORD_PENDING'
);
const changePersonPasswordCompleted = createAction(
    'CHANGE_PERSON_PASSWORD_COMPLETED'
);
const changePersonPasswordError = createAction('CHANGE_PERSON_PASSWORD_ERROR');
const resetChangePersonPasswordResponse = createAction(
    'RESET_CHANGE_PERSON_PASSWORD_RESPONSE'
);

const disconnectPersonVippsUserPending = createAction(
    'DISCONNECT_PERSON_VIPPS_USER_PENDING'
);
const disconnectPersonVippsUserCompleted = createAction(
    'DISCONNECT_PERSON_VIPPS_USER_COMPLETED'
);
const disconnectPersonVippsUserError = createAction(
    'DISCONNECT_PERSON_VIPPS_USER_ERROR'
);
const disconnectPersonVippsUserResetError = createAction(
    'DISCONNECT_PERSON_VIPPS_USER_RESET_ERROR'
);

const removePersonCardPending = createAction('REMOVE_PERSON_CARD_PENDING');
const removePersonCardCompleted = createAction('REMOVE_PERSON_CARD_COMPLETED');
const removePersonCardError = createAction('REMOVE_PERSON_CARD_ERROR');

const addPersonCardPending = createAction('ADD_PERSON_CARD_PENDING');
const addPersonCardCompleted = createAction('ADD_PERSON_CARD_COMPLETED');
const addPersonCardError = createAction('ADD_PERSON_CARD_ERROR');

const putAdminPersonPending = createAction('PUT_ADMIN_PERSON_PENDING');
const putAdminPersonCompleted = createAction('PUT_ADMIN_PERSON_COMPLETED');
const putAdminPersonError = createAction('PUT_ADMIN_PERSON_ERROR');

const putAdminPersonReset = createAction('PUT_ADMIN_PERSON_RESET');

const createAdminPersonPending = createAction('CREATE_ADMIN_PERSON_PENDING');
const createAdminPersonCompleted = createAction(
    'CREATE_ADMIN_PERSON_COMPLETED'
);
const createAdminPersonError = createAction('CREATE_ADMIN_PERSON_ERROR');

const showAddPersonCardConfirmationModal = createAction(
    'SHOW_ADD_PERSON_CARD_CONFIRMATION_MODAL'
);
const closeAddPersonCardConfirmationModal = createAction(
    'CLOSE_ADD_PERSON_CARD_CONFIRMATION_MODAL'
);

const fetchAdminPersonCardPending = createAction(
    'FETCH_ADMIN_MEMBER_CARD_PENDING'
);
const fetchAdminPersonCardCompleted = createAction(
    'FETCH_ADMIN_MEMBER_CARD_COMPLETED'
);
const fetchAdminPersonCardError = createAction('FETCH_ADMIN_MEMBER_CARD_ERROR');


const putAdminPersonDriversLicenceValidPending = createAction('PUT_ADMIN_PERSON_DRIVERS_LICENCE_VALID_PENDING');
const putAdminPersonDriversLicenceValidCompleted = createAction('PUT_ADMIN_PERSON_DRIVERS_LICENCE_VALID_COMPLETED');
const putAdminPersonDriversLicenceValidError = createAction('PUT_ADMIN_PERSON_DRIVERS_LICENCE_VALID_ERROR');

const actions = {
    fetchAdminPersonsPending,
    fetchAdminPersonsCompleted,
    fetchAdminPersonsError,
    fetchAdminPersonsMetadataPending,
    fetchAdminPersonsMetadataCompleted,
    fetchAdminPersonsMetadataError,
    deleteAdminUnusedPersonPending,
    deleteAdminUnusedPersonCompleted,
    deleteAdminUnusedPersonError,
    fetchAdminPersonPending,
    fetchAdminPersonCompleted,
    fetchAdminPersonError,
    fetchAdminPersonMembershipsPending,
    fetchAdminPersonMembershipsCompleted,
    fetchAdminPersonMembershipsError,
    fetchAdminPersonCardsPending,
    fetchAdminPersonCardsCompleted,
    fetchAdminPersonCardsError,
    changePersonPasswordPending,
    changePersonPasswordCompleted,
    changePersonPasswordError,
    resetChangePersonPasswordResponse,
    disconnectPersonVippsUserPending,
    disconnectPersonVippsUserCompleted,
    disconnectPersonVippsUserError,
    disconnectPersonVippsUserResetError,
    removePersonCardPending,
    removePersonCardCompleted,
    removePersonCardError,
    addPersonCardPending,
    addPersonCardCompleted,
    addPersonCardError,
    putAdminPersonPending,
    putAdminPersonCompleted,
    putAdminPersonError,
    putAdminPersonReset,
    createAdminPersonPending,
    createAdminPersonCompleted,
    createAdminPersonError,
    fetchAdminPersonCardPending,
    fetchAdminPersonCardCompleted,
    fetchAdminPersonCardError,
    showAddPersonCardConfirmationModal,
    closeAddPersonCardConfirmationModal,
    putAdminPersonDriversLicenceValidPending,
    putAdminPersonDriversLicenceValidCompleted,
    putAdminPersonDriversLicenceValidError
};

export default actions
