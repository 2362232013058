import { handleApiCallAndParseData } from '../../../utilities/utils';
import { doGetInvoices } from '../../../utilities/api';
import {
    selectAuthentication,
    selectSelectedMembership,
} from '../../duck/selectors';
import { createAction, handleActions } from 'redux-actions';
import { apiCallError, apiCallSuccess } from '../../../utilities/constants';
import { createApiCallObject } from '../../../utilities/apiUtils';

export const fetchUserInvoices = () => (dispatch, getState) => {
    dispatch(fetchInvoicesPending());
    const membership = selectSelectedMembership(getState());

    if (!membership) {
        dispatch(fetchInvoicesError());
        return;
    }

    return handleApiCallAndParseData(
        doGetInvoices(
            selectAuthentication(getState()),
            membership.id,
            createApiCallObject(dispatch, 'fetchUserInvoices')
        ),
        dispatch,
        (parsedData) => dispatch(fetchInvoicesCompleted(parsedData)),
        (error) => {
            dispatch(fetchInvoicesError());
            console.log(error);
        }
    );
};

export const resetUserInvoicesData = () => {
    return (dispatch) => {
        dispatch(resetInvoicesData());
    };
};

export const selectInvoices = (state) => state.invoicesReducer.invoices;
export const selectFetchingInvoices = (state) =>
    state.invoicesReducer.fetchingInvoices;
export const selectFetchInvoicesResult = (state) =>
    state.invoicesReducer.fetchInvoicesResult;

const fetchInvoicesPending = createAction('FETCH_INVOICES_PENDING');
const fetchInvoicesCompleted = createAction('FETCH_INVOICES_COMPLETED');
const fetchInvoicesError = createAction('FETCH_INVOICES_ERROR');
const resetInvoicesData = createAction('RESET_INVOICES_DATA');

const invoicesReducer = handleActions(
    {
        [fetchInvoicesPending]: (state) => ({
            ...state,
            fetchingInvoices: true,
            fetchInvoicesResult: undefined,
        }),
        [fetchInvoicesCompleted]: (state, action) => ({
            ...state,
            fetchingInvoices: false,
            fetchInvoicesResult: apiCallSuccess,
            invoices: action.payload,
        }),
        [fetchInvoicesError]: (state) => ({
            ...state,
            fetchingInvoices: false,
            fetchInvoicesResult: apiCallError,
        }),
        [resetInvoicesData]: (state) => ({
            ...state,
            fetchingInvoices: false,
            fetchInvoicesResult: undefined,
            invoices: undefined,
        }),
    },
    {
        invoices: false,
    }
);

export default invoicesReducer;
