import classnames from 'classnames';
import React, { Dispatch, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as DeleLogo } from '../../../resources/dele-logo.svg';
import DropdownMenu from '../DropdownMenu';
import { ReactComponent as AddIcon } from '../icon/addIcon.svg';
import { ReactComponent as CheckInCircleIcon } from '../icon/checkInCircle.svg';
import { ReactComponent as FilterIcon } from '../icon/filter.svg';
import { ReactComponent as MagnifyingGlassIcon } from '../icon/magnifyingGlass.svg';
import { ReactComponent as MenuIcon } from '../icon/menu.svg';
import { ProfileMenu } from '../profileMenu/ProfileMenu';
import { ProfileMenuButton } from '../profileMenu/ProfileMenuButton';
import AdminMenuOrgChooser from '../../components/AdminMenuOrgChooser/AdminMenuOrgChooser';
import {
    PAGE_ADMIN,
    PAGE_CHOOSE_CAR,
    PAGE_SHARED_RESERVATION,
} from '../../duck/constants';
import AdminNewNavItems from './AdminNewNavItems';
import NavItems from './NavItems';
import { Theme } from '../../../utilities/types';
import { Membership, Organization } from '../../types/common';
import { connect } from 'react-redux';
import { State } from '../../../state';
import {
    selectAdmin,
    selectAuthentication,
    selectHeading,
    selectMembershipOrPersonName,
    selectOrganizations,
    selectPage,
    selectSelectedMembership,
    selectSelectedOrganization,
    selectShowHamburgerMenu,
    selectSortedMemberships,
    selectTheme,
} from '../../duck/selectors';
import {
    logout,
    setPage,
    setSelectedMembership,
    setShouldAuthenticate,
    showHamburgerMenu,
    toggleShowHamburgerMenu,
    updateWhoAmIAndLogoutIfAuthExpired,
} from '../../duck/operations';
import { selectFiltersAreActive } from '../../pages/SearchPage/duck/selectors';

const HamburgerMenu = lazy(() => import('./HamburgerMenu/HamburgerMenu'));

type Props = {
    theme: Theme;
    auth: string;
    admin?: boolean;
    organizations: Organization[];
    selectedOrganization?: Organization;
    selectedMembership?: Membership;
    membershipOrPersonName?: string;
    page?: string;
    showHamburgerMenu: boolean;
    showHamburgerMenuFunction: (show: boolean) => void;
    toggleShowHamburgerMenu: () => void;
    heading: string;
    filtersAreActive?: boolean;
    logout: () => void;
    logoutIfAuthExpired: (fetchUserInfoIfLoggedIn?: boolean) => void;
    setShouldAuthenticate: (should: boolean) => void;
    setSelectedMembership: (membership: Membership) => void;
    memberships: Membership[];
    setPage: (pageName: string) => void;
};

const AppHeaderComponent = (props: Props) => {
    const showingSharedReservation = props.page === PAGE_SHARED_RESERVATION;
    const showingSharedReservationClass = showingSharedReservation
        ? ' header--showingSharedReservation'
        : '';
    let org: Organization;
    if (props.selectedMembership)
        org = props.organizations.filter(
            (o) => o.id === props.selectedMembership?.organizationId
        )[0];
    else org = props.organizations.filter((o) => o.key === 'bildeleringen')[0];

    const getHamburgerMenuItem = () => (
        <div className="headerMenuWrapper u-displayMobileOnly">
            <button
                className="mobileMenuButton"
                onClick={() => props.showHamburgerMenuFunction(true)}
                data-test="mobile-menu-button"
            >
                <MenuIcon />
                <div className="menuText" style={props.theme.text?.icon_menu}>
                    MENY
                </div>
            </button>
        </div>
    );

    const getHamburgerMenu = () => (
        <Suspense fallback={<div />}>
            <div className="u-displayMobileOnly u-menuShadow">
                <HamburgerMenu
                    theme={props.theme}
                    toggleShow={props.toggleShowHamburgerMenu}
                    showMenu={props.showHamburgerMenu}
                    side={'left'}
                >
                    <NavItems theme={props.theme} />
                </HamburgerMenu>
            </div>
        </Suspense>
    );

    const replace = (string: string, pattern: RegExp, newValue: string) =>
        string
            .split(pattern)
            .filter((w) => !w.match(pattern))
            .join(newValue);

    const getWhiteOrgLogo = () => {
        let logoSvg = replace(org.logoSvg, /(fill=".{2,7}")/, 'fill="white"');
        logoSvg = replace(logoSvg, /(fill:.{2,7};)/, 'fill:white;');
        return `data:image/svg+xml;utf8,${encodeURIComponent(logoSvg)}`;
    };

    const getDesktopHeading = () => (
        <Link
            className="headerDesktopHeading u-displayDesktopOnly"
            style={{
                backgroundColor: props.theme.colors?.BDR_PROFILE_1,
            }}
            to={'/'}
        >
            {org && org.logoSvg ? (
                <img
                    src={getWhiteOrgLogo()}
                    className="login__headerLogo"
                    alt={`${org.name}-logo`}
                />
            ) : (
                <DeleLogo className="login__headerLogo" />
            )}
        </Link>
    );

    const getMobileHeading = () => {
        let subHeading;
        if (
            props.page === 'ADMIN' &&
            props.organizations.length > 1 &&
            props.selectedOrganization
        ) {
            subHeading = props.selectedOrganization.name;
        }
        return (
            <div className="u-flexCenter u-displayMobileOnly">
                <div
                    className="header__headingWrapper"
                    style={{
                        color: props.theme.textColor__quinary,
                        // @ts-ignore
                        fontSize: props.theme.text?.h5,
                    }}
                >
                    {subHeading ? (
                        <div
                            className={'header__headingWrapperContent--double'}
                        >
                            <span>{props.heading}</span>
                            <span>{subHeading}</span>
                        </div>
                    ) : (
                        props.heading
                    )}
                </div>
            </div>
        );
    };

    const getFilterButton = () => (
        <Link
            className="headerOptionButton u-displayMobileOnly"
            to={{
                state: { showFilters: true },
            }}
        >
            <FilterIcon />
            <div className="menuText" style={props.theme.text?.icon_menu}>
                VALG
            </div>
            {props.filtersAreActive && (
                <div className="headerOptionButton__activeFiltersIndicator">
                    <CheckInCircleIcon />
                </div>
            )}
        </Link>
    );

    const getAdminButtons = () => (
        <Suspense fallback={<div />}>
            <DropdownMenu
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                }}
                menuButton={
                    <div className="headerOptionButton u-displayMobileOnly">
                        <AddIcon />
                        <div
                            className="menuText"
                            style={props.theme.text?.icon_menu}
                        >
                            NY
                        </div>
                    </div>
                }
            >
                <AdminNewNavItems
                    theme={props.theme}
                    setPage={props.setPage}
                    showHamburgerMenuFunction={props.showHamburgerMenuFunction}
                />
            </DropdownMenu>
            <Link
                className="headerOptionButton u-displayMobileOnly"
                to={'/admin/search'}
            >
                <MagnifyingGlassIcon />
                <div className="menuText" style={props.theme.text?.icon_menu}>
                    SØK
                </div>
            </Link>
        </Suspense>
    );

    const getProfileMenu = () => (
        <Suspense fallback={<div />}>
            <DropdownMenu
                disabled={!props.auth || !props.membershipOrPersonName}
                onClick={() => {
                    props.logoutIfAuthExpired();
                    props.showHamburgerMenuFunction(false);
                }}
                menuButton={
                    <ProfileMenuButton
                        theme={props.theme}
                        membershipOrPersonName={props.membershipOrPersonName}
                        selectedMembership={props.selectedMembership}
                    />
                }
            >
                <ProfileMenu
                    theme={props.theme}
                    auth={props.auth}
                    memberships={props.memberships}
                    selectedMembership={props.selectedMembership}
                    setSelectedMembership={props.setSelectedMembership}
                    logout={props.logout}
                    setPage={props.setPage}
                    setShouldAuthenticate={props.setShouldAuthenticate}
                />
            </DropdownMenu>
        </Suspense>
    );
    return (
        <header
            className={classnames('header', showingSharedReservationClass)}
            style={{
                backgroundColor: props.theme.colors?.BDR_PROFILE_1,
            }}
        >
            <div
                className={classnames('header__contents', {
                    'u-menuShadowOnlyDesktop': props.page === PAGE_CHOOSE_CAR,
                    'u-menuShadow': props.page !== PAGE_CHOOSE_CAR,
                })}
            >
                {getDesktopHeading()}
                {getHamburgerMenuItem()}
                {props.showHamburgerMenu && getHamburgerMenu()}
                {getMobileHeading()}
                <div className="desktopNav">
                    <NavItems
                        theme={props.theme}
                        showingSharedReservation={showingSharedReservation}
                    />
                </div>
                <div className="header__buttonsWrapper">
                    {props.page === PAGE_CHOOSE_CAR && getFilterButton()}
                    {props.page === PAGE_ADMIN && getAdminButtons()}
                    {props.auth && props.admin && <AdminMenuOrgChooser />}
                    {props.auth &&
                        !showingSharedReservation &&
                        getProfileMenu()}
                </div>
            </div>
        </header>
    );
};

const AppHeader = connect(
    (state: State) => ({
        theme: selectTheme(state),
        auth: selectAuthentication(state),
        admin: selectAdmin(state),
        organizations: selectOrganizations(state),
        selectedOrganization: selectSelectedOrganization(state),
        selectedMembership: selectSelectedMembership(state),
        membershipOrPersonName: selectMembershipOrPersonName(state),
        page: selectPage(state),
        showHamburgerMenu: selectShowHamburgerMenu(state),
        heading: selectHeading(state),
        filtersAreActive: selectFiltersAreActive(state),
        memberships: selectSortedMemberships(state),
    }),
    (dispatch: Dispatch<any>) => ({
        showHamburgerMenuFunction: (show: boolean) =>
            dispatch(showHamburgerMenu(show)),
        toggleShowHamburgerMenu: () => dispatch(toggleShowHamburgerMenu()),
        setPage: (page: string) => dispatch(setPage(page)),
        logout: () => dispatch(logout()),
        logoutIfAuthExpired: (fetchUserInfoIfLoggedIn?: boolean) =>
            dispatch(
                updateWhoAmIAndLogoutIfAuthExpired(fetchUserInfoIfLoggedIn)
            ),
        setShouldAuthenticate: (should: boolean) =>
            dispatch(setShouldAuthenticate(should)),
        setSelectedMembership: (membership: Membership) =>
            dispatch(setSelectedMembership(membership)),
    })
)(AppHeaderComponent);

export default AppHeader;
