export const THEME_STD = 'THEME_STD';
export const THEME_BDR = 'THEME_BDR';
export const THEME_OSLO = 'THEME_OSLO';

export const PAGE_CHOOSE_CAR = 'CHOOSE_CAR';
export const PAGE_CHOOSE_CAR__BOOKING_PAGE = 'CHOOSE_CAR__BOOKING_PAGE';
export const PAGE_RESERVATIONS = 'RESERVATIONS';
export const PAGE_SHARED_RESERVATION = 'SHARED_RESERVATION';
export const PAGE_INVOICES = 'INVOICES';
export const PAGE_MESSAGES = 'MESSAGES';
export const PAGE_PROFILE = 'PROFILE';
export const PAGE_FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const PAGE_MEMBERSHIP = 'PAGE_MEMBERSHIP';
export const PAGE_JOIN = 'PAGE_CREATE_MEMBERSHIP';
export const PAGE_ADMIN = 'ADMIN';
export const PAGE_ADMIN_RESERVATIONS = 'ADMIN_RESERVATIONS';
export const PAGE_ADMIN_DAMAGE = 'ADMIN_DAMAGE';
export const PAGE_ADMIN_DAMAGES = 'ADMIN_DAMAGES';
export const PAGE_ADMIN_RESERVATION = 'ADMIN_RESERVATION';
export const PAGE_ADMIN_MEMBERSHIPS = 'ADMIN_MEMBERSHIPS';
export const PAGE_ADMIN_MEMBERSHIP = 'ADMIN_MEMBERSHIP';
export const PAGE_ADMIN_HELP_OVERVIEW = 'ADMIN_HELP_OVERVIEW';
export const PAGE_ADMIN_HELP_SPECIFIC_OVERVIEW = 'ADMIN_HELP_SPECIFIC_OVERVIEW';
export const PAGE_ADMIN_HELP_EDIT = 'ADMIN_HELP_EDIT';
export const PAGE_ADMIN_HELP_EDIT_SPECIFIC = 'ADMIN_HELP_EDIT_SPECIFIC';
export const PAGE_ADMIN_PERSONS = 'PAGE_ADMIN_PERSONS';
export const PAGE_ADMIN_PERSON = 'PAGE_ADMIN_PERSON';
export const PAGE_ADMIN_BILLING = 'PAGE_ADMIN_BILLING';
export const PAGE_ADMIN_BILLING_SUMMARY = 'PAGE_ADMIN_BILLING_SUMMARY';
export const PAGE_ADMIN_CARS = 'PAGE_ADMIN_CARS';
export const PAGE_ADMIN_ASSIGNMENTS = 'PAGE_ADMIN_ASSIGNMENTS';
export const PAGE_ADMIN_CAR = 'PAGE_ADMIN_CAR';
export const PAGE_ADMIN_MODELS = 'PAGE_ADMIN_MODELS';
export const PAGE_ADMIN_MODEL = 'PAGE_ADMIN_MODEL';
export const PAGE_ADMIN_FILE_UPLOAD = 'PAGE_ADMIN_FILE_UPLOAD';
export const PAGE_ADMIN_TRIPLETEX_ERRORS = 'PAGE_ADMIN_TRIPLETEX_ERRORS';

export const PAGE_ADMIN_LOCATION = 'PAGE_ADMIN_LOCATION';
export const PAGE_ADMIN_LOCATIONS = 'PAGE_ADMIN_LOCATIONS';

export const PAGE_ADMIN_MEMBER_CARDS = 'PAGE_ADMIN_MEMBER_CARDS';
export const PAGE_ADMIN_MEMBER_CARD = 'PAGE_ADMIN_MEMBER_CARD';

export const HEADING_CHOOSE_CAR = 'Velg bil';
export const HEADING_RESERVATIONS = 'Reservasjoner';
export const HEADING_SHARED_RESERVATION = 'Delt reservasjon';
export const HEADING_INVOICES = 'Mine fakturaer';
export const HEADING_MESSAGES = 'Meldinger';
export const HEADING_PROFILE = 'Profil';
export const HEADING_FORGOT_PASSWORD = 'Glemt passord';
export const HEADING_MEMBERSHIP = 'Medlemskap';
export const HEADING_JOIN = 'Opprett medlemskap';

export const HEADING_ADMIN = 'Admin';
export const HEADING_ADMIN_RESERVATIONS = 'Reservasjoner';
export const HEADING_ADMIN_RESERVATION = 'Reservasjon';
export const HEADING_ADMIN_DAMAGE = 'Skade';
export const HEADING_ADMIN_DAMAGES = 'Skader';
export const HEADING_ADMIN_MEMBERSHIPS = 'Medlemskap';
export const HEADING_ADMIN_MEMBERSHIP = 'Medlemskap';
export const HEADING_ADMIN_HELP_OVERVIEW = 'Hjelpesider';
export const HEADING_ADMIN_HELP_EDIT = 'Rediger';
export const HEADING_ADMIN_HELP_SPECIFIC_OVERVIEW = 'Spesifikke løsninger';
export const HEADING_ADMIN_HELP_EDIT_SPECIFIC = 'Rediger';
export const HEADING_ADMIN_PERSONS = 'Personer';
export const HEADING_ADMIN_PERSON = 'Person';
export const HEADING_ADMIN_BILLING = 'Fakturagrunnlag';
export const HEADING_ADMIN_BILLING_SUMMARY = 'Fakturering';
export const HEADING_ADMIN_CARS = 'Biler';
export const HEADING_ADMIN_CAR = 'Bil';
export const HEADING_ADMIN_ASSIGNMENTS = 'Plasseringer';
export const HEADING_ADMIN_MODELS = 'Bilmodeller';
export const HEADING_ADMIN_MODEL = 'Bilmodell';
export const HEADING_ADMIN_FILE_UPLOAD = 'Last opp filer';
export const HEADING_ADMIN_TRIPLETEX_ERRORS = 'Tripletex-feil';

export const HEADING_ADMIN_LOCATION = 'Lokasjon';
export const HEADING_ADMIN_LOCATIONS = 'Lokasjoner';

export const HEADING_ADMIN_MEMBER_CARDS = 'Nøkkelkort';
export const HEADING_ADMIN_MEMBER_CARD = 'Nøkkelkort';
