import { createAction } from 'redux-actions';

const fetchAdminCarsPending = createAction('FETCH_ADMIN_CARS_PENDING');
const fetchAdminCarsCompleted = createAction('FETCH_ADMIN_CARS_COMPLETED');
const fetchAdminCarsError = createAction('FETCH_ADMIN_CARS_ERROR');

const fetchAdminCarsMetadataPending = createAction(
    'FETCH_ADMIN_CARS_METADATA_PENDING'
);
const fetchAdminCarsMetadataCompleted = createAction(
    'FETCH_ADMIN_CARS_METADATA_COMPLETED'
);
const fetchAdminCarsMetadataError = createAction(
    'FETCH_ADMIN_CARS_METADATA_ERROR'
);

const fetchAdminCarPending = createAction('FETCH_ADMIN_CAR_PENDING');
const fetchAdminCarCompleted = createAction('FETCH_ADMIN_CAR_COMPLETED');
const fetchAdminCarError = createAction('FETCH_ADMIN_CAR_ERROR');

const putAdminCarPending = createAction('PUT_ADMIN_CAR_PENDING');
const putAdminCarCompleted = createAction('PUT_ADMIN_CAR_COMPLETED');
const putAdminCarError = createAction('PUT_ADMIN_CAR_ERROR');
const resetPutAdminCarResult = createAction('RESET_PUT_ADMIN_CAR_RESULT');

const createAdminCarPending = createAction('CREATE_ADMIN_CAR_PENDING');
const createAdminCarCompleted = createAction('CREATE_ADMIN_CAR_COMPLETED');
const createAdminCarError = createAction('CREATE_ADMIN_CAR_ERROR');
const resetCreateAdminCarState = createAction('RESET_CREATE_ADMIN_CAR_STATE');

const fetchAdminCarModelsPending = createAction(
    'FETCH_ADMIN_CAR_MODELS_PENDING'
);
const fetchAdminCarModelsCompleted = createAction(
    'FETCH_ADMIN_CAR_MODELS_COMPLETED'
);
const fetchAdminCarModelsError = createAction('FETCH_ADMIN_CAR_MODELS_ERROR');

const fetchAdminCarTemplatePending = createAction(
    'FETCH_ADMIN_CAR_TEMPLATE_PENDING'
);
const fetchAdminCarTemplateCompleted = createAction(
    'FETCH_ADMIN_CAR_TEMPLATE_COMPLETED'
);
const fetchAdminCarTemplateError = createAction(
    'FETCH_ADMIN_CAR_TEMPLATE_ERROR'
);

const fetchAdminCarIconsPending = createAction('FETCH_ADMIN_CAR_ICONS_PENDING');
const fetchAdminCarIconsCompleted = createAction(
    'FETCH_ADMIN_CAR_ICONS_COMPLETED'
);
const fetchAdminCarIconsError = createAction('FETCH_ADMIN_CAR_ICONS_ERROR');

const fetchAdminCarFuelCardsPending = createAction(
    'FETCH_ADMIN_CAR_FUEL_CARDS_PENDING'
);
const fetchAdminCarFuelCardsCompleted = createAction(
    'FETCH_ADMIN_CAR_FUEL_CARDS_COMPLETED'
);
const fetchAdminCarFuelCardsError = createAction(
    'FETCH_ADMIN_CAR_FUEL_CARDS_ERROR'
);
const addFuelCardPending = createAction('ADD_FUEL_CARD_PENDING');
const addFuelCardCompleted = createAction('ADD_FUEL_CARD_COMPLETED');
const addFuelCardError = createAction('ADD_FUEL_CARD_ERROR');

const removeFuelCardPending = createAction('REMOVE_FUEL_CARD_PENDING');
const removeFuelCardCompleted = createAction('REMOVE_FUEL_CARD_COMPLETED');
const removeFuelCardError = createAction('REMOVE_FUEL_CARD_ERROR');

const fetchAssignmentsPending = createAction('FETCH_ASSIGNMENTS_PENDING');
const fetchAssignmentsCompleted = createAction('FETCH_ASSIGNMENTS_COMPLETED');
const fetchAssignmentsError = createAction('FETCH_ASSIGNMENTS_ERROR');

const fetchAssignmentPending = createAction('FETCH_ASSIGNMENT_PENDING');
const fetchAssignmentCompleted = createAction('FETCH_ASSIGNMENT_COMPLETED');
const fetchAssignmentError = createAction('FETCH_ASSIGNMENT_ERROR');
const resetFetchAssignmentResult = createAction(
    'RESET_FETCH_ASSIGNMENT_RESULT'
);

const fetchCarAssignmentsPending = createAction(
    'FETCH_CAR_ASSIGNMENTS_PENDING'
);
const fetchCarAssignmentsCompleted = createAction(
    'FETCH_CAR_ASSIGNMENTS_COMPLETED'
);
const fetchCarAssignmentsError = createAction('FETCH_CAR_ASSIGNMENTS_ERROR');

const postOrPutAssignmentPending = createAction(
    'POST_OR_PUT_ASSIGNMENT_PENDING'
);
const postOrPutAssignmentCompleted = createAction(
    'POST_OR_PUT_ASSIGNMENT_COMPLETED'
);
const postOrPutAssignmentError = createAction('POST_OR_PUT_ASSIGNMENT_ERROR');
const resetPostOrPutAssignmentsResult = createAction(
    'RESET_POST_OR_PUT_ASSIGNMENT_RESULT'
);

const deleteAssignmentPending = createAction('DELETE_ASSIGNMENT_PENDING');
const deleteAssignmentCompleted = createAction('DELETE_ASSIGNMENT_COMPLETED');
const deleteAssignmentError = createAction('DELETE_ASSIGNMENT_ERROR');
const resetAssignmentAffectedReservations = createAction(
    'RESET_ASSIGNMENT_AFFECTED_RESERVATIONS'
);

const actions = {
    fetchAdminCarsPending,
    fetchAdminCarsCompleted,
    fetchAdminCarsError,
    fetchAdminCarsMetadataPending,
    fetchAdminCarsMetadataCompleted,
    fetchAdminCarsMetadataError,
    fetchAdminCarPending,
    fetchAdminCarCompleted,
    fetchAdminCarError,
    putAdminCarPending,
    putAdminCarCompleted,
    putAdminCarError,
    resetPutAdminCarResult,
    createAdminCarPending,
    createAdminCarCompleted,
    createAdminCarError,
    resetCreateAdminCarState,
    fetchAdminCarModelsPending,
    fetchAdminCarModelsCompleted,
    fetchAdminCarModelsError,
    fetchAdminCarTemplatePending,
    fetchAdminCarTemplateCompleted,
    fetchAdminCarTemplateError,
    fetchAdminCarIconsPending,
    fetchAdminCarIconsCompleted,
    fetchAdminCarIconsError,
    fetchAdminCarFuelCardsPending,
    fetchAdminCarFuelCardsCompleted,
    fetchAdminCarFuelCardsError,
    addFuelCardPending,
    addFuelCardCompleted,
    addFuelCardError,
    removeFuelCardPending,
    removeFuelCardCompleted,
    removeFuelCardError,
    fetchAssignmentsPending,
    fetchAssignmentsCompleted,
    fetchAssignmentsError,
    fetchAssignmentPending,
    fetchAssignmentCompleted,
    fetchAssignmentError,
    resetFetchAssignmentResult,
    fetchCarAssignmentsPending,
    fetchCarAssignmentsCompleted,
    fetchCarAssignmentsError,
    postOrPutAssignmentPending,
    postOrPutAssignmentCompleted,
    postOrPutAssignmentError,
    resetPostOrPutAssignmentsResult,
    deleteAssignmentPending,
    deleteAssignmentCompleted,
    deleteAssignmentError,
    resetAssignmentAffectedReservations,
};

export default actions;
