import React, { Component, Fragment } from 'react';
import deleLogo from '../../../resources/dele-logo-with-text.svg';
import { CloseModalButton } from '../Buttons/CloseModalButton';
import Button from '../Buttons/Button';
import InputField from '../InputField/InputField';
import { apiCallError, apiCallSuccess } from '../../../utilities/constants';
import { Loader } from '../Loader/Loader';
import { deviceIsMobile, isNotBlank } from '../../../utilities/utils';
import { ReactComponent as VippsLogo } from '../icon/vippsLogo.svg';
import { SetWaypointLink } from '../WaypointLink/SetWaypointLink';
import { Link } from 'react-router-dom';

/*
TODO FLOW:
type State = {
    rememberChecked: boolean,
    userName: string,
    password: string,
    forgotPassword: boolean,
    passwordRetrievalMail: string,
    validEmail: boolean
};

type Props = {
    login: Function,
    theme: Object,
    setShowIncorrectLoginFeedback: boolean => void,
    sendPasswordRetrievalEmail: string => void,
    showIncorrectLoginFeedback: boolean,
    closeModal?: () => void,
    sendPasswordResetEmailPending: boolean,
    sendPasswordResetEmailResult: string,
    resetSendPasswordResetEmailResult: () => void,
    joining?: boolean,
    noVippsButton?: boolean,
    presetUserName?: string
};
*/

class Login extends Component /*TODO FLOW:<Props, State>*/ {
    constructor(props /*TODO FLOW:: Props*/) {
        super(props);
        this.state = {
            rememberChecked: false,
            userName: props.presetUserName ? props.presetUserName : '',
            password: '',
            forgotPassword: false,
            passwordRetrievalMail: '',
            validEmail: false,
        };
    }

    render() {
        return (
            <div
                className={'login'}
                style={{ borderColor: this.props.theme.borderColor }}
                data-test="login-box"
            >
                {this.props.joining ? (
                    ''
                ) : (
                    <Fragment>
                        {this.props.closeModal && (
                            <CloseModalButton
                                className="login__closeModalButton"
                                onClick={this.props.closeModal}
                            />
                        )}
                        <div className="login__header">
                            <img
                                src={deleLogo}
                                className="login__headerLogo"
                                alt="BDR logo"
                            />
                        </div>
                    </Fragment>
                )}
                {this.state.forgotPassword
                    ? this.renderForgotPasswordForm()
                    : this.renderLoginForm()}
                <div className="login__bottomButtons bottomButtons">
                    {this.props.joining || this.state.forgotPassword ? (
                        <div className="bottomButtons__becomeMemberButton" />
                    ) : (
                        <Link
                            className="bottomButtons__becomeMemberButton"
                            style={{
                                color: this.props.theme.colors
                                    .links_and_clickables,
                                textDecoration: 'none',
                            }}
                            to="/join"
                            onClick={this.props.closeModal}
                        >
                            Bli medlem
                        </Link>
                    )}
                    <button
                        style={{
                            color: this.props.theme.colors.links_and_clickables,
                        }}
                        className="bottomButtons__forgotPasswordButton"
                        onClick={this.toggleForgotPassword}
                    >
                        {this.state.forgotPassword
                            ? 'Tilbake'
                            : 'Glemt passordet?'}
                    </button>
                </div>
            </div>
        );
    }

    renderForgotPasswordForm = () => {
        return (
            <div className="login__forgotPassword">
                {this.props.sendPasswordResetEmailPending
                    ? this.renderLoader()
                    : this.props.sendPasswordResetEmailResult === apiCallSuccess
                    ? this.renderSuccessMessage()
                    : this.props.sendPasswordResetEmailResult === apiCallError
                    ? this.renderErrorMessage()
                    : this.renderPasswordRetrievalEmailInfoAndInput()}
                <Button
                    onClick={
                        this.props.sendPasswordResetEmailResult
                            ? this.forgotPasswordProcessDone
                            : this.sendPasswordRetrievalEmail
                    }
                    label={
                        this.props.sendPasswordResetEmailResult ? 'Ok' : 'Send'
                    }
                    buttonType={
                        this.state.validEmail ? 'primary' : 'primary__disabled'
                    }
                    theme={this.props.theme}
                />
            </div>
        );
    };

    forgotPasswordProcessDone = () => {
        this.props.resetSendPasswordResetEmailResult();
        this.setState({ forgotPassword: false });
    };

    renderLoader = () => (
        <div className="login__loaderWrapper">
            <div className="login__loader">
                <Loader />
            </div>
        </div>
    );

    renderSuccessMessage = () => (
        <div className="login__resetPasswordResultMessageWrapper">
            <p
                className="login__resetPasswordResultMessage"
                style={this.props.theme.text.body2}
            >
                Hvis vi finner en konto som samsvarer med informasjonen du oppga
                vil en <span className="u-noWrap">e-post</span> være rett rundt
                hjørnet!
            </p>
        </div>
    );

    renderErrorMessage = () => (
        <div className="login__resetPasswordResultMessageWrapper">
            <p
                className="login__resetPasswordResultMessage"
                style={this.props.theme.text.body2}
            >
                Noe gikk galt, venligst prøv igjen senere
            </p>
        </div>
    );

    renderPasswordRetrievalEmailInfoAndInput = () => (
        <Fragment>
            <p
                style={this.props.theme.text.body2}
                className="login__forgotPasswordInfo"
            >
                Hvis du har glemt passordet ditt kan du få tilsendt en e-post
                som lar deg sette et nytt passord.
            </p>
            <InputField
                label={'E-post'}
                key={'emailInput'}
                defaultValue={this.state.passwordRetrievalMail}
                onChange={(newValue) => {
                    this.setState({ passwordRetrievalMail: newValue });
                }}
                className={`login__forgotPasswordEmailInput`}
                type={'EMAIL'}
                optionalValidationCallBack={(validEmail) =>
                    this.setState({ validEmail })
                }
                customValidation={isNotBlank}
                mandatory={true}
                onEnterPress={this.sendPasswordRetrievalEmail}
                autoFocus={!deviceIsMobile()}
            />
        </Fragment>
    );

    sendPasswordRetrievalEmail = () => {
        if (this.state.validEmail)
            this.props.sendPasswordRetrievalEmail(
                this.state.passwordRetrievalMail.trim()
            );
    };

    renderLoginForm = () => {
        let wrongInput = this.props.showIncorrectLoginFeedback;
        return (
            <div className={'login__form'}>
                {this.props.noVippsButton || (
                    <>
                        <SetWaypointLink
                            className={'vippsLogoLink'}
                            waypointUrl={this.props.waypointUrl || '/'}
                            unauthorizedWaypointUrl={'/linkOrCreateUser'}
                            url={`/oauth2/authorization/vipps-login${
                                this.props.vippsLoginQuery || ''
                            }`}
                        >
                            <span>Logg inn med</span>
                            <VippsLogo />
                        </SetWaypointLink>
                        <span
                            className="vippsLogoLink__or"
                            style={this.props.theme.text.associatedText}
                        >
                            eller
                        </span>
                    </>
                )}
                <InputField
                    label={'E-post'}
                    key={'unameInput'}
                    defaultValue={this.state.userName}
                    onChange={(newValue) => {
                        this.onLoginInputChange();
                        this.setState({ userName: newValue });
                    }}
                    className={`login__usernameInput`}
                    type={'EMAIL'}
                    customValidation={isNotBlank}
                    mandatory={true}
                    onEnterPress={this.onSubmit}
                    autoFocus={!deviceIsMobile() && !this.props.presetUserName}
                    name={'uname'}
                    testId={'login-box.input-username'}
                />
                <InputField
                    label={'Passord'}
                    key={'pswInput'}
                    defaultValue={this.state.password}
                    onChange={(newValue) => {
                        this.onLoginInputChange();
                        this.setState({ password: newValue });
                    }}
                    className={`login__passwordInput`}
                    type={'PASSWORD'}
                    mandatory={true}
                    onEnterPress={this.onSubmit}
                    name={this.props.presetUserName ? 'noAutoFillPsw' : 'psw'}
                    autoFocus={!!this.props.presetUserName}
                    testId={'login-box.input-password'}
                />
                {wrongInput ? (
                    <div
                        className="login__feedbackBox"
                        style={{
                            color: this.props.theme.textColor__darkRed,
                        }}
                    >
                        Feil brukernavn eller passord
                    </div>
                ) : (
                    ''
                )}
                <div className="login__buttonsDiv">
                    <Button
                        testId={'login-box.login-button'}
                        onClick={this.onSubmit}
                        label={
                            this.props.joining
                                ? 'Logg inn og bekreft'
                                : 'Logg inn'
                        }
                        buttonType={
                            this.state.userName.length > 0 &&
                            this.state.password.length > 0
                                ? 'primary'
                                : 'primary__disabled'
                        }
                        theme={this.props.theme}
                    />
                </div>
            </div>
        );
    };

    onSubmit = () => {
        if (this.state.userName.length > 0 && this.state.password.length) {
            this.props.login(this.state.userName, this.state.password);
        }
    };

    onLoginInputChange = () => {
        this.props.setShowIncorrectLoginFeedback(false);
    };

    toggleForgotPassword = () => {
        this.setState({ forgotPassword: !this.state.forgotPassword });
    };
}

export default Login;
