import React, { useEffect, useState } from 'react';
import AnimatedChevron from '../AnimatedChevron/AnimatedChevron';
import { Link } from 'react-router-dom';
import { P } from '../../common/Typography';
import { Organization } from '../../types/admin';
import { connect } from 'react-redux';
import {
    selectOrganizations,
    selectPage,
    selectPermissions,
    selectSelectedOrganization,
} from '../../duck/selectors';
import { GrantedPermissions } from '../../common/Security/permissions';
import { setSelectedOrganization } from '../../duck/operations';
import { State } from '../../../state';
import { PublicId } from '../../types/common';
import { PAGE_ADMIN } from '../../duck/constants';

type Props = {
    onAdminPage: boolean;
    organizations: Organization[];
    selectedOrganization?: Organization;
    setSelectedOrganization: (org: Organization) => void;
};

const AdminMenuOrgChooserComponent = ({
    onAdminPage,
    organizations,
    selectedOrganization,
    setSelectedOrganization,
}: Props) => {
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        if (organizations.length === 1 && !selectedOrganization) {
            setSelectedOrganization(organizations[0]);
        }
    }, [organizations, selectedOrganization, setSelectedOrganization]);

    const renderSelectOrganizationOption = (org: Organization) => (
        <div key={org.name} className="adminMenuOrgChooser__option">
            <button
                onClick={() => {
                    setShowOptions(false);
                    setSelectedOrganization(org);
                }}
            >
                {org.name}
            </button>
        </div>
    );

    const renderSelectOrganizationLink = (org: Organization) => (
        <div key={org.name} className="adminMenuOrgChooser__option">
            <Link
                key={org.name}
                to={'/admin'}
                onClick={() => {
                    setShowOptions(false);
                    setSelectedOrganization(org);
                }}
            >
                {org.name}
            </Link>
        </div>
    );

    const height = 21 + organizations.length * 40;

    const renderOrgDropdownButton = () => (
        <button
            className="adminMenuOrgChooser__button"
            onClick={() => setShowOptions(!showOptions)}
        >
            {selectedOrganization ? (
                renderOrgName(selectedOrganization)
            ) : (
                <p>Admin</p>
            )}
            <AnimatedChevron
                open={showOptions || needToChoose}
                color={'white'}
            />
        </button>
    );

    const renderOrgName = (org: Organization) => (
        <div className={'adminMenuOrgChooser__orgName'}>
            <p className="adminMenuOrgChooser__buttonPreLabel">Organisasjon</p>
            <p>{org.name}</p>
        </div>
    );

    const renderHeaderContent = () => {
        const showAdminLink = selectedOrganization && !onAdminPage;
        return (
            <div
                className={`adminMenuOrgChooser__header ${
                    showAdminLink
                        ? 'adminMenuOrgChooser__header--showingAdminLink'
                        : ''
                }`}
            >
                {showAdminLink && (
                    <Link className="navButton" to={'/admin'}>
                        <div className="adminMenuOrgChooser__linkBorderWrapper">
                            <div className="navButton__navLabel">Admin</div>
                        </div>
                    </Link>
                )}
                {organizations.length === 1 &&
                organizations[0].id === selectedOrganization?.id ? (
                    <div className={'adminMenuOrgChooser__label'}>
                        {renderOrgName(selectedOrganization)}
                    </div>
                ) : (
                    renderOrgDropdownButton()
                )}
            </div>
        );
    };

    const needToChoose = onAdminPage && !selectedOrganization;

    const renderNoneChosenWarning = () => {
        if (!needToChoose) return <></>;
        return (
            <div className="adminMenuOrgChooser__needToChooseWarning">
                <div className="adminMenuOrgChooser__needToChooseWarningMessage">
                    <P>Du må velge en organisasjon å administrere.</P>
                </div>
            </div>
        );
    };

    if (!organizations?.length) return <></>;

    return (
        <>
            {renderNoneChosenWarning()}
            <div
                className={`adminMenuOrgChooser ${
                    showOptions || needToChoose
                        ? 'adminMenuOrgChooser--active'
                        : ''
                } u-displayDesktopOnly`}
            >
                {renderHeaderContent()}
                {showOptions && (
                    <div
                        className="adminMenuOrgChooser__closer"
                        onClick={() => setShowOptions(false)}
                    />
                )}
                <div
                    className={`adminMenuOrgChooser__options ${
                        showOptions || needToChoose
                            ? 'adminMenuOrgChooser__options--showing'
                            : ''
                    }`}
                    style={{ height }}
                >
                    <div
                        className="adminMenuOrgChooser__optionsWrapper"
                        style={{
                            top: showOptions || needToChoose ? 0 : -height,
                        }}
                    >
                        <p>Velg organisasjon</p>
                        {organizations.map(
                            onAdminPage
                                ? renderSelectOrganizationOption
                                : renderSelectOrganizationLink
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export const AdminMenuOrgChooser = connect(
    (state: State) => {
        const administratedOrganizationIds: PublicId[] = selectPermissions(
            state
        )
            .filter((p: GrantedPermissions) => p.permissions?.length)
            .map((p: GrantedPermissions) => p.organizationId);
        return {
            selectedOrganization: selectSelectedOrganization(state),
            onAdminPage: selectPage(state) === PAGE_ADMIN,
            organizations: selectOrganizations(
                state
            ).filter((o: Organization) =>
                administratedOrganizationIds.includes(o.id)
            ),
        };
    },
    (dispatch: any) => {
        return {
            setSelectedOrganization: (org: Organization) =>
                dispatch(setSelectedOrganization(org)),
        };
    }
)(AdminMenuOrgChooserComponent);

export default AdminMenuOrgChooser;
