// @ts-ignore
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../../../utilities/constants';

export interface ExpenseState {
    fetchReservationExpensesDataResult?: string;
    fetchingReservationExpensesData?: boolean;

    deleteExpenseResult?: string;
    deletingExpense?: boolean;

    reservationExpensesData?: object;

    selectExpenseReservationResult?: string;
    selectingExpenseReservation?: boolean;

    fetchReservationExpensesMetadataResult?: string;
    fetchingReservationExpensesMetadata?: boolean;
    reservationExpensesMetadata?: object;

    countExpensesResult?: string;
    countingExpenses?: boolean;
    expenseCount?: number;
}

const initialState: ExpenseState = {};

const adminReservationExpensesSlice = createSlice({
    name: 'adminReservationExpenses',
    initialState,
    reducers: {
        fetchingReservationExpensesDataPending(state: ExpenseState) {
            state.fetchReservationExpensesDataResult = undefined;
            state.fetchingReservationExpensesData = true;
        },
        fetchingReservationExpensesDataCompleted(
            state: ExpenseState,
            action: PayloadAction<object>
        ) {
            state.fetchReservationExpensesDataResult = apiCallSuccess;
            state.fetchingReservationExpensesData = false;
            state.reservationExpensesData = action.payload;
        },
        fetchingReservationExpensesDataError(state: ExpenseState) {
            state.fetchReservationExpensesDataResult = apiCallError;
            state.fetchingReservationExpensesData = false;
        },

        deletingExpensePending(state: ExpenseState) {
            state.deleteExpenseResult = undefined;
            state.deletingExpense = true;
        },
        deletingExpenseCompleted(
            state: ExpenseState,
            action: PayloadAction<object>
        ) {
            state.deleteExpenseResult = apiCallSuccess;
            state.deletingExpense = false;
            state.reservationExpensesData = action.payload;
        },
        deletingExpenseError(state: ExpenseState) {
            state.deleteExpenseResult = apiCallError;
            state.deletingExpense = false;
        },

        fetchingReservationExpensesMetadataPending(state: ExpenseState) {
            state.fetchReservationExpensesMetadataResult = undefined;
            state.fetchingReservationExpensesMetadata = true;
        },
        fetchingReservationExpensesMetadataCompleted(
            state: ExpenseState,
            action: PayloadAction<object>
        ) {
            state.fetchReservationExpensesMetadataResult = apiCallSuccess;
            state.fetchingReservationExpensesMetadata = false;
            state.reservationExpensesMetadata = action.payload;
        },
        fetchingReservationExpensesMetadataError(state: ExpenseState) {
            state.fetchReservationExpensesMetadataResult = apiCallError;
            state.fetchingReservationExpensesMetadata = false;
        },

        countExpensesPending(state: ExpenseState) {
            state.countExpensesResult = undefined;
            state.countingExpenses = true;
        },
        countExpensesCompleted(
            state: ExpenseState,
            action: PayloadAction<number>
        ) {
            state.countExpensesResult = apiCallSuccess;
            state.countingExpenses = false;
            state.expenseCount = action.payload;
        },
        countExpensesError(state: ExpenseState) {
            state.countExpensesResult = apiCallError;
            state.countingExpenses = false;
        },

        selectExpenseReservationPending(state: ExpenseState) {
            state.selectExpenseReservationResult = undefined;
            state.selectingExpenseReservation = true;
        },
        selectExpenseReservationCompleted(
            state: ExpenseState,
            action: PayloadAction<object>
        ) {
            state.selectExpenseReservationResult = apiCallSuccess;
            state.selectingExpenseReservation = false;
            state.reservationExpensesData = action.payload;
        },
        selectExpenseReservationError(state: ExpenseState) {
            state.selectExpenseReservationResult = apiCallError;
            state.selectingExpenseReservation = false;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase('CLEAR_STATE', () => initialState);
    },
});

export const {
    fetchingReservationExpensesDataPending,
    fetchingReservationExpensesDataCompleted,
    fetchingReservationExpensesDataError,
    deletingExpensePending,
    deletingExpenseCompleted,
    deletingExpenseError,
    fetchingReservationExpensesMetadataPending,
    fetchingReservationExpensesMetadataCompleted,
    fetchingReservationExpensesMetadataError,
    countExpensesPending,
    countExpensesCompleted,
    countExpensesError,
    selectExpenseReservationPending,
    selectExpenseReservationCompleted,
    selectExpenseReservationError,
} = adminReservationExpensesSlice.actions;

export default adminReservationExpensesSlice.reducer;
