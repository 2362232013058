import React, { Component, ErrorInfo } from 'react';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import { Theme } from '../../../utilities/types';
import LogRocket from 'logrocket';

type ErrorBoundaryProps = {
    theme: Theme;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

export default class ErrorBoundary extends Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: Readonly<ErrorBoundaryProps>) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, info);
        console.error(error, info);
        LogRocket.error(error, info);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage type={'ERROR_BOUNDARY'} />;
        }

        return this.props.children;
    }
}
