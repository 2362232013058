import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../../utilities/constants';
import { handleActions } from 'redux-actions';
import actions from './actions';

const defaultCarState = {
    fetchingAdminCars: false,
    fetchAdminCarsResult: undefined,
    adminCars: undefined,

    fetchingAdminCarsMetadata: false,
    fetchAdminCarsMetadataResult: undefined,
    adminCarsMetadata: undefined,

    fetchingAdminCar: false,
    fetchAdminCarResult: undefined,
    adminCar: undefined,

    puttingAdminCar: false,
    putAdminCarResult: undefined,

    fetchingAdminCarLastReservations: false,
    fetchAdminCarLastReservationsResult: undefined,
    adminCarLastReservations: undefined,

    fetchingAdminCarFuelCards: false,
    fetchAdminCarFuelCardsResult: undefined,
    adminCarFuelCards: undefined,

    fetchingAdminCarModels: false,
    fetchAdminCarModelsResult: undefined,
    adminCarModels: undefined,

    fetchingAdminCarTemplate: false,
    fetchAdminCarTemplateResult: undefined,
    adminCarTemplate: undefined,

    fetchingAdminCarIcons: false,
    fetchAdminCarIconsResult: undefined,
    adminCarIcons: undefined,

    creatingCar: false,
    createCarResult: undefined,
    newCarId: undefined,
};

const carReducer = handleActions(
    {
        [actions.fetchAdminCarsPending]: (state) => ({
            ...state,
            fetchingAdminCars: true,
            fetchAdminCarsResult: undefined,
        }),
        [actions.fetchAdminCarsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminCars: false,
            fetchAdminCarsResult: apiCallSuccess,
            adminCars: action.payload,
        }),
        [actions.fetchAdminCarsError]: (state) => ({
            ...state,
            fetchingAdminCars: false,
            fetchAdminCarsResult: apiCallError,
        }),

        [actions.fetchAdminCarsMetadataPending]: (state) => ({
            ...state,
            fetchingAdminCarsMetadata: true,
            fetchAdminCarsMetadataResult: undefined,
        }),
        [actions.fetchAdminCarsMetadataCompleted]: (state, action) => ({
            ...state,
            fetchingAdminCarsMetadata: false,
            fetchAdminCarsMetadataResult: apiCallSuccess,
            adminCarsMetadata: action.payload,
        }),
        [actions.fetchAdminCarsMetadataError]: (state) => ({
            ...state,
            fetchingAdminCarsMetadata: false,
            fetchAdminCarsMetadataResult: apiCallError,
        }),

        [actions.fetchAdminCarPending]: (state) => ({
            ...state,
            fetchingAdminCar: true,
            fetchAdminCarResult: undefined,
        }),
        [actions.fetchAdminCarCompleted]: (state, action) => ({
            ...state,
            fetchingAdminCar: false,
            fetchAdminCarResult: apiCallSuccess,
            adminCar: action.payload,
        }),
        [actions.fetchAdminCarError]: (state) => ({
            ...state,
            fetchingAdminCar: false,
            fetchAdminCarResult: apiCallError,
        }),

        [actions.putAdminCarPending]: (state) => ({
            ...state,
            puttingAdminCar: true,
            putAdminCarResult: undefined,
        }),
        [actions.putAdminCarCompleted]: (state) => ({
            ...state,
            puttingAdminCar: false,
            putAdminCarResult: apiCallSuccess,
        }),
        [actions.putAdminCarError]: (state) => ({
            ...state,
            puttingAdminCar: false,
            putAdminCarResult: apiCallError,
        }),
        [actions.resetPutAdminCarResult]: (state) => ({
            ...state,
            puttingAdminCar: false,
            putAdminCarResult: undefined,
        }),
        [actions.createAdminCarPending]: (state) => ({
            ...state,
            creatingCar: true,
            createCarResult: undefined,
        }),
        [actions.createAdminCarCompleted]: (state, action) => ({
            ...state,
            creatingCar: false,
            createCarResult: apiCallSuccess,
            newCarId: action.payload.id,
        }),
        [actions.createAdminCarError]: (state) => ({
            ...state,
            creatingCar: false,
            createCarResult: apiCallError,
        }),
        [actions.createAdminCarError]: (state) => ({
            ...state,
            creatingCar: false,
            createCarResult: apiCallError,
        }),
        [actions.resetCreateAdminCarState]: (state) => ({
            ...state,
            creatingCar: false,
            createCarResult: undefined,
            newCarId: undefined,
        }),

        [actions.fetchAdminCarModelsPending]: (state) => ({
            ...state,
            fetchingAdminCarModels: true,
            fetchAdminCarModelsResult: undefined,
        }),
        [actions.fetchAdminCarModelsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminCarModels: false,
            fetchAdminCarModelsResult: apiCallSuccess,
            adminCarModels: action.payload,
        }),
        [actions.fetchAdminCarModelsError]: (state) => ({
            ...state,
            fetchingAdminCarModels: false,
            fetchAdminCarModelsResult: apiCallError,
        }),

        [actions.fetchAdminCarTemplatePending]: (state) => ({
            ...state,
            fetchingAdminCarTemplate: true,
            fetchAdminCarTemplateResult: undefined,
        }),
        [actions.fetchAdminCarTemplateCompleted]: (state, action) => ({
            ...state,
            fetchingAdminCarTemplate: false,
            fetchAdminCarTemplateResult: apiCallSuccess,
            adminCarTemplate: action.payload,
        }),
        [actions.fetchAdminCarTemplateError]: (state) => ({
            ...state,
            fetchingAdminCarTemplate: false,
            fetchAdminCarTemplateResult: apiCallError,
        }),

        [actions.fetchAdminCarIconsPending]: (state) => ({
            ...state,
            fetchingAdminCarIcons: true,
            fetchAdminCarIconsResult: undefined,
        }),
        [actions.fetchAdminCarIconsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminCarIcons: false,
            fetchAdminCarIconsResult: apiCallSuccess,
            adminCarIcons: action.payload,
        }),
        [actions.fetchAdminCarIconsError]: (state) => ({
            ...state,
            fetchingAdminCarIcons: false,
            fetchAdminCarIconsResult: apiCallError,
        }),

        [actions.fetchAdminCarFuelCardsPending]: (state) => ({
            ...state,
            fetchingAdminCarFuelCards: true,
            fetchAdminCarFuelCardsResult: undefined,
        }),
        [actions.fetchAdminCarFuelCardsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminCarFuelCards: false,
            fetchAdminCarFuelCardsResult: apiCallSuccess,
            adminCarFuelCards: action.payload,
        }),
        [actions.fetchAdminCarFuelCardsError]: (state) => ({
            ...state,
            fetchingAdminCarFuelCards: false,
            fetchAdminCarFuelCardsResult: apiCallError,
        }),
        [actions.addFuelCardPending]: (state) => ({
            ...state,
            addingFuelCard: true,
            addFuelCardResult: undefined,
        }),
        [actions.addFuelCardCompleted]: (state) => ({
            ...state,
            addingFuelCard: false,
            addFuelCardResult: apiCallSuccess,
        }),
        [actions.addFuelCardError]: (state) => ({
            ...state,
            addingFuelCard: false,
            addFuelCardResult: apiCallError,
        }),
        [actions.removeFuelCardPending]: (state) => ({
            ...state,
            removingFuelCard: true,
            removeFuelCardResult: undefined,
        }),
        [actions.removeFuelCardCompleted]: (state) => ({
            ...state,
            removingFuelCard: false,
            removeFuelCardResult: apiCallSuccess,
        }),
        [actions.removeFuelCardError]: (state) => ({
            ...state,
            removingFuelCard: false,
            removeFuelCardResult: apiCallError,
        }),

        [actions.fetchAssignmentPending]: (state) => ({
            ...state,
            fetchingAssignment: true,
            fetchAssignmentResult: undefined,
        }),
        [actions.fetchAssignmentCompleted]: (state, action) => ({
            ...state,
            fetchingAssignment: false,
            fetchAssignmentResult: apiCallSuccess,
            assignment: action.payload,
        }),
        [actions.fetchAssignmentError]: (state) => ({
            ...state,
            fetchingAssignment: false,
            fetchAssignmentResult: apiCallError,
        }),
        [actions.resetFetchAssignmentResult]: (state) => ({
            ...state,
            fetchAssignmentResult: undefined,
            assignment: undefined,
        }),

        [actions.fetchCarAssignmentsPending]: (state) => ({
            ...state,
            fetchingCarAssignments: true,
            fetchCarAssignmentsResult: undefined,
        }),
        [actions.fetchCarAssignmentsCompleted]: (state, action) => ({
            ...state,
            fetchingCarAssignments: false,
            fetchCarAssignmentsResult: apiCallSuccess,
            carAssignments: action.payload,
        }),
        [actions.fetchCarAssignmentsError]: (state) => ({
            ...state,
            fetchingCarAssignments: false,
            fetchCarAssignmentsResult: apiCallError,
        }),

        [actions.postOrPutAssignmentPending]: (state) => ({
            ...state,
            postingOrPuttingAssignments: true,
            postOrPutAssignmentsResult: undefined,
        }),
        [actions.postOrPutAssignmentCompleted]: (state, action) => ({
            ...state,
            postingOrPuttingAssignments: false,
            postOrPutAssignmentsResult: apiCallSuccess,
            carAssignments: action.payload.assignments,
            assignmentAffectedReservations: action.payload.affectedReservations,
        }),
        [actions.postOrPutAssignmentError]: (state) => ({
            ...state,
            postingOrPuttingAssignments: false,
            postOrPutAssignmentsResult: apiCallError,
        }),
        [actions.resetPostOrPutAssignmentsResult]: (state) => ({
            ...state,
            postingOrPuttingAssignments: false,
            postOrPutAssignmentsResult: undefined,
        }),

        [actions.deleteAssignmentPending]: (state) => ({
            ...state,
            deletingAssignments: true,
            deleteAssignmentsResult: undefined,
        }),
        [actions.deleteAssignmentCompleted]: (state, action) => ({
            ...state,
            deletingAssignments: false,
            deleteAssignmentsResult: apiCallSuccess,
            carAssignments: action.payload.assignments,
            assignmentAffectedReservations: action.payload.affectedReservations,
        }),
        [actions.deleteAssignmentError]: (state) => ({
            ...state,
            deletingAssignments: false,
            deleteAssignmentsResult: apiCallError,
        }),
        [actions.resetAssignmentAffectedReservations]: (state) => ({
            ...state,
            assignmentAffectedReservations: undefined,
        }),

        CLEAR_STATE: () => defaultCarState,
    },
    defaultCarState
);

export default carReducer;
