import { createAction } from 'redux-actions';

const fetchAdminEntityMessagesPending = createAction(
    'FETCH_ADMIN_ENTITY_MESSAGES_PENDING'
);
const fetchAdminEntityMessagesCompleted = createAction(
    'FETCH_ADMIN_ENTITY_MESSAGES_COMPLETED'
);
const fetchAdminEntityMessagesError = createAction(
    'FETCH_ADMIN_ENTITY_MESSAGES_ERROR'
);

const fetchAdminEntityMessagesMetadataPending = createAction(
    'FETCH_ADMIN_ENTITY_MESSAGES_METADATA_PENDING'
);
const fetchAdminEntityMessagesMetadataCompleted = createAction(
    'FETCH_ADMIN_ENTITY_MESSAGES_METADATA_COMPLETED'
);
const fetchAdminEntityMessagesMetadataError = createAction(
    'FETCH_ADMIN_ENTITY_MESSAGES_METADATA_ERROR'
);

const fetchAdminEntityMessagePending = createAction(
    'FETCH_ADMIN_ENTITY_MESSAGE_PENDING'
);
const fetchAdminEntityMessageCompleted = createAction(
    'FETCH_ADMIN_ENTITY_MESSAGE_COMPLETED'
);
const fetchAdminEntityMessageError = createAction(
    'FETCH_ADMIN_ENTITY_MESSAGE_ERROR'
);

const deleteAdminEntityMessageCompleted = createAction(
    'DELETE_ADMIN_ENTITY_MESSAGE_COMPLETED'
);

const saveAdminEntityMessagePending = createAction(
    'SAVE_ADMIN_ENTITY_MESSAGE_PENDING'
);
const saveAdminEntityMessageCompleted = createAction(
    'SAVE_ADMIN_ENTITY_MESSAGE_COMPLETED'
);
const saveAdminEntityMessageError = createAction(
    'SAVE_ADMIN_ENTITY_MESSAGE_ERROR'
);

const actions = {
    fetchAdminEntityMessagesPending,
    fetchAdminEntityMessagesCompleted,
    fetchAdminEntityMessagesError,
    fetchAdminEntityMessagesMetadataPending,
    fetchAdminEntityMessagesMetadataCompleted,
    fetchAdminEntityMessagesMetadataError,
    fetchAdminEntityMessagePending,
    fetchAdminEntityMessageCompleted,
    fetchAdminEntityMessageError,
    deleteAdminEntityMessageCompleted,
    saveAdminEntityMessagePending,
    saveAdminEntityMessageCompleted,
    saveAdminEntityMessageError,
};

export default actions;
