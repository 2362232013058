import * as L from 'leaflet';
import React from 'react';
import { CityBikeSpot, Theme } from '../../../../../utilities/types';
import Map from '../../../../common/map/Map';
import { MapPosition, RouterLocation } from '../../../../types/application';
import { Car } from '../../../../types/search';
import { LatLng } from 'leaflet';

export const SYSTEM_DEFAULT_MAP_POSITION: LatLng = new LatLng(
    60.391864334,
    5.32294035
);

type MapChooserProps = {
    showCityBikes: boolean;
    toggleShowCityBikes: (event: React.MouseEvent<HTMLButtonElement>) => void;
    mapPosition: MapPosition;
    theme: Theme;
    setMapPosition: (position: MapPosition) => void;
    selectedPosition: MapPosition;
    selectedCarId: string;
    cityBikes: CityBikeSpot[];
    cars: Car[];
    selectCar: (car: Car) => void;
    location: RouterLocation;
};

const MapChooser = (props: MapChooserProps) => {
    const mapPosition: MapPosition = props.mapPosition
        ? props.mapPosition
        : { latlng: SYSTEM_DEFAULT_MAP_POSITION, zoom: 15 };
    const getCarMarker = (car: Car) => {
        const latLng = L.geoJSON(car.location.geojson).getBounds().getCenter();
        let isChosen = car.id === props.selectedCarId;
        const marker = L.marker(latLng, {
            icon: getCarIcon(car.iconUrl, car.maxAvailability, isChosen),
            title: car.licensePlate,
            alt: car.iconUrl,
        });
        marker.on('click', () => {
            props.selectCar(car);
            props.location.history.push('/findcar', {
                showBookingPage: true,
                showMap: true,
            });
        });
        return marker;
    };

    const getCarIcon = (
        url: string | undefined,
        maxAvailability: number,
        isChosen: boolean
    ) => {
        const availabilityClass =
            maxAvailability === 0
                ? 'unavailable'
                : maxAvailability < 100
                ? 'partiallyAvailable'
                : 'available';

        return L.divIcon({
            html: `
            <div class="carMarker">
                <svg class="${availabilityClass}${
                isChosen ? ' selected' : ''
            }" width="29.42" height="33.43" viewBox="0 0 29.42 33.43" xmlns="http://www.w3.org/2000/svg">
                    <path class="first" d="m14.65 8.8844h-12.112c-0.61146 0.016555-0.71041 0.53404-0.52728 1.0495l12.639 21.334z" fill="#8bbe00"/>
                    <path class="second" d="m14.65 8.8844h12.112c0.61146 0.01656 0.7104 0.53404 0.52728 1.0495l-12.639 21.334z" fill="#8bbe00"/>
                    <path class="strokePath" d="m2.0104 9.934c-0.18312-0.51548-0.08418-1.033 0.52728-1.0495h24.224c0.61146 0.01656 0.7104 0.53404 0.52728 1.0495l-12.639 21.334z" fill="none" stroke="#000"/>
                </svg>
                <img
                    src=${url} 
                    alt="car"
                    class="carMarker__img"
                >
            </div>`,
            iconSize: new L.Point(50, 50),
            iconAnchor: [25, 45],
        });
    };

    const getCarAvailabilityById = (licensePlate: string) => {
        const car = props.cars.find((car) => car.licensePlate === licensePlate);
        return car ? car.maxAvailability : 0;
    };

    const isCarSelected = (licensePlate: string) => {
        const car = props.cars.find((car) => car.licensePlate === licensePlate);
        return !!car && car.id === props.selectedCarId;
    };

    const getCarMarkers = (cars: Car[]) => {
        if (cars.length > 0) {
            return cars.map((car) => getCarMarker(car));
        } else {
            return [];
        }
    };
    return (
        <div className="mapChooserContent">
            <Map
                theme={props.theme}
                mapPosition={mapPosition}
                setMapPosition={props.setMapPosition}
                carMarkers={getCarMarkers(props.cars)}
                selectedPosition={props.selectedPosition}
                getCarIcon={getCarIcon}
                selectedCarId={props.selectedCarId}
                getCarAvailabilityById={getCarAvailabilityById}
                isCarSelected={isCarSelected}
                cityBikes={props.showCityBikes ? props.cityBikes : []}
            />
        </div>
    );
};
export default MapChooser;
