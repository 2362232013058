import classnames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Close } from '../icon/close.svg';

type Props = {
    fallBack?: string;
    className?: string;
    children?: React.ReactNode;
    redirect?: boolean;
};

const key = 'linkWaypoint';
const unauthorizedKey = 'unauthorizedWaypoint';

export const setWaypoint = (waypointUrl: string) => {
    localStorage.setItem(key, waypointUrl);
};

export const setUnauthorizedWaypoint = (waypointUrl?: string) => {
    waypointUrl
        ? localStorage.setItem(unauthorizedKey, waypointUrl)
        : localStorage.removeItem(unauthorizedKey);
};

export const getWaypoint = (auth?: string) => {
    let waypoint = localStorage.getItem(key);

    const unauthorizedWaypoint = localStorage.getItem(unauthorizedKey);
    if (!auth && unauthorizedWaypoint) waypoint = unauthorizedWaypoint;
    if (waypoint === undefined || waypoint === null || waypoint[0] !== '/') {
        return '/';
    } else {
        localStorage.removeItem(key);
        localStorage.removeItem(unauthorizedKey);
    }
    return waypoint;
};

/**
 * general component for navigating to a saved waypoint if one exists, else to root
 */

const WaypointLink = ({ className, children }: Props) => {
    const [waypoint] = useState(getWaypoint());
    return (
        <Link
            className={classnames('waypointLink', className, {
                waypointLink__close: !children,
            })}
            to={waypoint}
        >
            {children || <Close />}
        </Link>
    );
};

export default WaypointLink;
