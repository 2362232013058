import { createAction } from 'redux-actions';

const fetchAdminLocationsPending = createAction(
    'FETCH_ADMIN_LOCATIONS_PENDING'
);
const fetchAdminLocationsCompleted = createAction(
    'FETCH_ADMIN_LOCATIONS_COMPLETED'
);
const fetchAdminLocationsError = createAction('FETCH_ADMIN_LOCATIONS_ERROR');

const fetchAdminLocationsMetadataPending = createAction(
    'FETCH_ADMIN_LOCATIONS_METADATA_PENDING'
);
const fetchAdminLocationsMetadataCompleted = createAction(
    'FETCH_ADMIN_LOCATIONS_METADATA_COMPLETED'
);
const fetchAdminLocationsMetadataError = createAction(
    'FETCH_ADMIN_LOCATIONS_METADATA_ERROR'
);

const fetchAdminLocationPending = createAction('FETCH_ADMIN_LOCATION_PENDING');
const fetchAdminLocationCompleted = createAction(
    'FETCH_ADMIN_LOCATION_COMPLETED'
);
const fetchAdminLocationError = createAction('FETCH_ADMIN_LOCATION_ERROR');

const putAdminLocationPending = createAction('PUT_ADMIN_LOCATION_PENDING');
const putAdminLocationCompleted = createAction('PUT_ADMIN_LOCATION_COMPLETED');
const putAdminLocationError = createAction('PUT_ADMIN_LOCATION_ERROR');
const resetPutAdminLocationResults = createAction(
    'CLEAR_PUT_ADMIN_LOCATION_RESULTS'
);

const createAdminLocationPending = createAction('POST_ADMIN_LOCATION_PENDING');
const createAdminLocationCompleted = createAction(
    'POST_ADMIN_LOCATION_COMPLETED'
);
const createAdminLocationError = createAction('POST_ADMIN_LOCATION_ERROR');
const resetCreateAdminLocationResults = createAction(
    'CLEAR_POST_ADMIN_LOCATION_RESULTS'
);

const actions = {
    fetchAdminLocationsPending,
    fetchAdminLocationsCompleted,
    fetchAdminLocationsError,
    fetchAdminLocationsMetadataPending,
    fetchAdminLocationsMetadataCompleted,
    fetchAdminLocationsMetadataError,
    fetchAdminLocationPending,
    fetchAdminLocationCompleted,
    fetchAdminLocationError,
    putAdminLocationPending,
    putAdminLocationCompleted,
    putAdminLocationError,
    resetPutAdminLocationResults,
    createAdminLocationPending,
    createAdminLocationCompleted,
    createAdminLocationError,
    resetCreateAdminLocationResults
};

export default actions
