import React from 'react';
import { Loader } from '../Loader/Loader';

const LoadingPage = () => (
    <div key={'loadingSpinner'} className={'loadingPage'}>
        <div
            style={{
                width: '100px',
                height: '100px',
            }}
        >
            <Loader type={'loaderSpinning'} />
        </div>
    </div>
);
export default LoadingPage;
