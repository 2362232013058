export const selectCreatingUser = (state) =>
    state.profileReducer.data.creatingUser;
export const selectCreateUserResult = (state) =>
    state.profileReducer.data.createUserResult;
export const selectCreateUserErrors = (state) =>
    state.profileReducer.data.createUserErrors;

export const selectUserInfo = (state) => state.profileReducer.data.userInfo;
export const selectMemberCards = (state) =>
    state.profileReducer.data.userInfo?.cards || [];

export const selectFetchingUserInfo = (state) =>
    state.profileReducer.data.fetchingUserInfo;
export const selectFetchUserInfoResult = (state) =>
    state.profileReducer.data.fetchUserInfoResult;

export const selectUpdatingUserInfo = (state) =>
    state.profileReducer.data.updatingUserInfo;

export const selectUnlinkingVippsUser = (state) =>
    state.profileReducer.data.unlinkingVippsUser;
export const selectUnlinkVippsUserResult = (state) =>
    state.profileReducer.data.unlinkVippsUserResult;
export const selectUnlinkVippsUserErrors = (state) =>
    state.profileReducer.data.unlinkVippsUserErrors;

export const selectUpdateUserInfoResult = (state) =>
    state.profileReducer.data.updateUserInfoResult;

export const selectUpdateUserInfoErrors = (state) =>
    state.profileReducer.data.updateUserInfoErrors;

export const selectChangePasswordResult = (state) =>
    state.profileReducer.data.changePasswordResult;
export const selectChagingPassword = (state) =>
    state.profileReducer.data.chagingPassword;

export const selectMembershipTypes = (state) =>
    state.profileReducer.data.membershipTypes;
export const selectFetchingMembershipTypes = (state) =>
    state.profileReducer.data.fetchingMembershipTypes;
export const selectFetchMembershipTypesResult = (state) =>
    state.profileReducer.data.fetchMembershipTypesResult;

export const selectFetchingMemberships = (state) =>
    state.profileReducer.data.fetchingMemberships;
export const selectFetchMembershipsResult = (state) =>
    state.profileReducer.data.fetchMembershipsResult;

export const selectInvitingMember = (state) =>
    state.profileReducer.data.invitingMember;
export const selectInviteMemberResult = (state) =>
    state.profileReducer.data.inviteMemberResult;

export const selectMembership = (state) => state.profileReducer.data.membership;
export const selectFetchingMembership = (state) =>
    state.profileReducer.data.fetchingMembership;
export const selectFetchMembershipResult = (state) =>
    state.profileReducer.data.fetchMembershipResult;

export const selectUpdatingMembership = (state) =>
    state.profileReducer.data.updatingMembership;
export const selectUpdateMembershipResult = (state) =>
    state.profileReducer.data.updateMembershipResult;
export const selectUpdateMembershipErrors = (state) =>
    state.profileReducer.data.updateMembershipErrors;

export const selectFetchingMembershipMembers = (state) =>
    state.profileReducer.data.fetchingMembershipMembers;
export const selectFetchMembershipResultMembers = (state) =>
    state.profileReducer.data.fetchMembershipResult;
