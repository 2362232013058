import appActions from '../../../../duck/actions';
import { selectAuthentication } from '../../../../duck/selectors';
import { handleApiCallAndParseData } from '../../../../../utilities/utils';
import { createAction, handleActions } from 'redux-actions';
import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../utilities/constants';
import {
    deleteAdminMemberCardPersonApiCall,
    getAdminMemberCardApiCall,
    getAdminMemberCardsApiCall,
    getAdminMemberCardsMetadataApiCall,
    putAdminMemberCardPersonApiCall,
} from '../../../../../utilities/api';
import { handlePagination } from '../../../../duck/operations';
import { createApiCallObject } from '../../../../../utilities/apiUtils';

// OPERATIONS

export const fetchMemberCardsMetadata = () => (dispatch, getState) => {
    dispatch(fetchMemberCardsMetadataPending());
    handleApiCallAndParseData(
        getAdminMemberCardsMetadataApiCall(
            selectAuthentication(getState()),
            createApiCallObject(dispatch, 'fetchMemberCardsMetadata')
        ),
        dispatch,
        (parsedData) => {
            dispatch(fetchMemberCardsMetadataCompleted(parsedData));
        },
        (error) => {
            dispatch(fetchMemberCardsMetadataError());
            console.log(error);
        }
    );
};

export const fetchMemberCards = (filters, page) => (dispatch, getState) => {
    if (page) {
        dispatch(appActions.fetchingNewPagePending());
    } else {
        dispatch(appActions.resetPagination());
    }
    dispatch(fetchMemberCardsPending());
    handleApiCallAndParseData(
        getAdminMemberCardsApiCall(
            selectAuthentication(getState()),
            filters ? filters : '',
            page,
            createApiCallObject(dispatch, 'fetchMemberCards')
        ),
        dispatch,
        (parsedData) => {
            dispatch(handlePagination(page, parsedData));
            let previousData = page ? selectMemberCards(getState()) : [];
            dispatch(
                fetchMemberCardsCompleted([
                    ...previousData,
                    ...(parsedData.data || parsedData),
                ])
            );
        },
        (error) => {
            dispatch(fetchMemberCardsError());
            console.log(error);
        },
        undefined,
        true
    );
};

export const fetchMemberCard = (cardId) => (dispatch, getState) => {
    dispatch(fetchMemberCardPending());
    handleApiCallAndParseData(
        getAdminMemberCardApiCall(
            selectAuthentication(getState()),
            cardId,
            createApiCallObject(dispatch, 'fetchMemberCards')
        ),
        dispatch,
        (parsedData) => {
            dispatch(fetchMemberCardCompleted(parsedData));
        },
        (error) => {
            dispatch(fetchMemberCardError());
            console.log(error);
        }
    );
};

export const putMemberCardPerson = (cardId, person) => (dispatch, getState) => {
    dispatch(putMemberCardPersonPending());
    handleApiCallAndParseData(
        putAdminMemberCardPersonApiCall(
            selectAuthentication(getState()),
            cardId,
            person,
            createApiCallObject(dispatch, 'putMemberCardPerson')
        ),
        dispatch,
        (parsedData) => {
            dispatch(putMemberCardPersonCompleted(parsedData));
        },
        (error) => {
            dispatch(putMemberCardPersonError());
            console.log(error);
        }
    );
};

export const deleteMemberCardPerson = (cardId) => (dispatch, getState) => {
    dispatch(putMemberCardPersonPending());
    handleApiCallAndParseData(
        deleteAdminMemberCardPersonApiCall(
            selectAuthentication(getState()),
            cardId,
            createApiCallObject(dispatch, 'deleteAdminMemberCardPersonApiCall')
        ),
        dispatch,
        (parsedData) => {
            dispatch(putMemberCardPersonCompleted(parsedData));
        },
        (error) => {
            dispatch(putMemberCardPersonError());
            console.log(error);
        }
    );
};

export const resetPutAndDeleteMemberCardPersonResults = () => (dispatch) =>
    dispatch(resetPutAndDeleteResults());

// SELECTORS

export const selectMemberCardsMetadata = (state) =>
    state.admin.memberCards.memberCardsMetadata;
export const selectMemberCard = (state) => state.admin.memberCards.memberCard;
export const selectFetchingMemberCard = (state) =>
    state.admin.memberCards.fetchingMemberCard;
export const selectFetchMemberCardResult = (state) =>
    state.admin.memberCards.fetchMemberCardResult;

export const selectMemberCards = (state) => state.admin.memberCards.memberCards;
export const selectFetchingMemberCards = (state) =>
    state.admin.memberCards.fetchingMemberCards;
export const selectFetchMemberCardsMetadataResult = (state) =>
    state.admin.memberCards.fetchMemberCardsMetadataResult;
export const selectFetchingMemberCardsMetadata = (state) =>
    state.admin.memberCards.fetchingMemberCardsMetadata;
export const selectFetchMemberCardsResult = (state) =>
    state.admin.memberCards.fetchMemberCardsResult;

export const selectPuttingMemberCardPerson = (state) =>
    state.admin.memberCards.puttingMemberCardPerson;
export const selectPutMemberCardPersonResult = (state) =>
    state.admin.memberCards.putMemberCardPersonResult;
export const selectDeletingMemberCardPerson = (state) =>
    state.admin.memberCards.deletingMemberCardPerson;
export const selectDeleteMemberCardPersonResult = (state) =>
    state.admin.memberCards.deleteMemberCardPersonResult;

// ACTIONS
const fetchMemberCardsMetadataPending = createAction(
    'FETCH_MEMBER_CARDS_METADATA_PENDING'
);
const fetchMemberCardsMetadataCompleted = createAction(
    'FETCH_MEMBER_CARDS_METADATA_COMPLETED'
);
const fetchMemberCardsMetadataError = createAction(
    'FETCH_MEMBER_CARDS_METADATA_ERROR'
);

const putMemberCardPersonPending = createAction(
    'PUT_MEMBER_CARD_PERSON_PENDING'
);
const putMemberCardPersonCompleted = createAction(
    'PUT_MEMBER_CARD_PERSON_COMPLETED'
);
const putMemberCardPersonError = createAction('PUT_MEMBER_CARD_PERSON_ERROR');

const deleteMemberCardPersonPending = createAction(
    'PUT_MEMBER_CARD_PERSON_PENDING'
);
const deleteMemberCardPersonCompleted = createAction(
    'PUT_MEMBER_CARD_PERSON_COMPLETED'
);
const deleteMemberCardPersonError = createAction(
    'PUT_MEMBER_CARD_PERSON_ERROR'
);

const fetchMemberCardPending = createAction('FETCH_MEMBER_CARD_PENDING');
const fetchMemberCardCompleted = createAction('FETCH_MEMBER_CARD_COMPLETED');
const fetchMemberCardError = createAction('FETCH_MEMBER_CARD_ERROR');

const fetchMemberCardsPending = createAction('FETCH_MEMBER_CARDS_PENDING');
const fetchMemberCardsCompleted = createAction('FETCH_MEMBER_CARDS_COMPLETED');
const fetchMemberCardsError = createAction('FETCH_MEMBER_CARDS_ERROR');
const resetMemberCardsData = createAction('RESET_MEMBER_CARDS_DATA');

const resetPutAndDeleteResults = createAction(
    'RESET_PUT_AND_DELETE_MEMBER_CARD_PERSON_RESULTS'
);

// REDUCER
const memberCardsReducer = handleActions(
    {
        [fetchMemberCardsMetadataPending]: (state) => ({
            ...state,
            fetchingMemberCardsMetadata: true,
            fetchMemberCardsMetadataResult: undefined,
        }),
        [fetchMemberCardsMetadataCompleted]: (state, action) => ({
            ...state,
            fetchingMemberCardsMetadata: false,
            fetchMemberCardsMetadataResult: apiCallSuccess,
            memberCardsMetadata: action.payload,
        }),
        [fetchMemberCardsMetadataError]: (state) => ({
            ...state,
            fetchingMemberCardsMetadata: false,
            fetchMemberCardsMetadataResult: apiCallError,
        }),

        [fetchMemberCardPending]: (state) => ({
            ...state,
            fetchingMemberCards: true,
            fetchMemberCardsResult: undefined,
        }),
        [fetchMemberCardCompleted]: (state, action) => ({
            ...state,
            fetchingMemberCard: false,
            fetchMemberCardResult: apiCallSuccess,
            memberCard: action.payload,
        }),
        [fetchMemberCardError]: (state) => ({
            ...state,
            fetchingMemberCard: false,
            fetchMemberCardResult: apiCallError,
        }),

        [deleteMemberCardPersonPending]: (state) => ({
            ...state,
            deletingMemberCardPerson: true,
            deleteMemberCardPersonResult: undefined,
        }),
        [deleteMemberCardPersonCompleted]: (state, action) => ({
            ...state,
            deletingMemberCardPerson: false,
            deleteMemberCardPersonResult: apiCallSuccess,
            memberCard: action.payload,
        }),
        [deleteMemberCardPersonError]: (state) => ({
            ...state,
            deletingMemberCardPerson: false,
            deleteMemberCardPersonResult: apiCallError,
        }),

        [putMemberCardPersonPending]: (state) => ({
            ...state,
            puttingMemberCardPerson: true,
            putMemberCardPersonResult: undefined,
        }),
        [putMemberCardPersonCompleted]: (state, action) => ({
            ...state,
            puttingMemberCardPerson: false,
            putMemberCardPersonResult: apiCallSuccess,
            memberCard: action.payload,
        }),
        [putMemberCardPersonError]: (state) => ({
            ...state,
            puttingMemberCardPerson: false,
            putMemberCardPersonResult: apiCallError,
        }),

        [resetPutAndDeleteResults]: (state) => ({
            ...state,
            deleteMemberCardPersonResult: undefined,
            putMemberCardPersonResult: undefined,
        }),

        [fetchMemberCardPending]: (state) => ({
            ...state,
            fetchingMemberCard: true,
            fetchMemberCardResult: undefined,
        }),
        [fetchMemberCardsCompleted]: (state, action) => ({
            ...state,
            fetchingMemberCards: false,
            fetchMemberCardsResult: apiCallSuccess,
            memberCards: action.payload,
        }),
        [fetchMemberCardsError]: (state) => ({
            ...state,
            fetchingMemberCards: false,
            fetchMemberCardsResult: apiCallError,
        }),
        [resetMemberCardsData]: (state) => ({
            ...state,
            fetchingMemberCards: false,
            fetchMemberCardsResult: undefined,
            memberCards: undefined,
        }),
    },
    {
        MemberCards: false,
    }
);

export default memberCardsReducer;
