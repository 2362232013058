import classnames from 'classnames';
import React from 'react';
import { RouterLocation } from '../../types/application';
import { setUnauthorizedWaypoint, setWaypoint } from './WaypointLink';

type Props = {
    className?: string;
    waypointUrl?: string;
    unauthorizedWaypointUrl?: string;
    returnToCurrentLocation?: RouterLocation;
    url: string;
    children: React.ReactNode | string;
};

/**
 * general component for saving a waypoint which can later be used by WaypointLink
 */

export const SetWaypointLink = ({
    className,
    waypointUrl,
    unauthorizedWaypointUrl,
    returnToCurrentLocation,
    url,
    children,
}: Props) => {
    if (returnToCurrentLocation) {
        const { pathname, search } = returnToCurrentLocation.location;
        waypointUrl = pathname;
        if (search) waypointUrl = waypointUrl + search;
    }
    return (
        <div
            className={classnames('waypointLink', className)}
            onClick={() => {
                setWaypoint(waypointUrl || '/');
                setUnauthorizedWaypoint(unauthorizedWaypointUrl);
                window.location = (url as unknown) as Location;
            }}
        >
            {children}
        </div>
    );
};
