import { createAction } from 'redux-actions';

const createUserPending = createAction('CREATE_USER_PENDING');
const createUserCompleted = createAction('CREATE_USER_COMPLETED');
const createUserError = createAction('CREATE_USER_ERROR');
const updateCreateUserErrors = createAction('UPDATE_CREATE_USER_ERRORS');

const fetchUserInfoPending = createAction('FETCH_USER_INFO_PENDING');
const fetchUserInfoCompleted = createAction('FETCH_USER_INFO_COMPLETED');
const fetchUserInfoError = createAction('FETCH_USER_INFO_ERROR');

const unlinkVippsUserPending = createAction('UNLINK_VIPPS_USER_PENDING');
const unlinkVippsUserCompleted = createAction('UNLINK_VIPPS_USER_COMPLETED');
const unlinkVippsUserError = createAction('UNLINK_VIPPS_USER_ERROR');
const unlinkVippsUserResetError = createAction('UNLINK_VIPPS_USER_RESET_ERROR');

const fetchMembershipsPending = createAction('FETCH_MEMBERSHIPS_PENDING');
const fetchMembershipsCompleted = createAction('FETCH_MEMBERSHIPS_COMPLETED');
const fetchMembershipsError = createAction('FETCH_MEMBERSHIPS_ERROR');

const updateUserInfoPending = createAction('UPDATE_USER_INFO_PENDING');
const updateUserInfoCompleted = createAction('UPDATE_USER_INFO_COMPLETED');
const updateUserInfoError = createAction('UPDATE_USER_INFO_ERROR');

const changePasswordPending = createAction('CHANGE_PASSWORD_PENDING');
const changePasswordCompleted = createAction('CHANGE_PASSWORD_COMPLETED');
const changePasswordError = createAction('CHANGE_PASSWORD_ERROR');

const resetUpdateUserInfoAndPasswordApiResults = createAction(
    'RESET_UPDATE_USER_INFO_AND_PASSWORD_API_RESULTS'
);
const resetChangePasswordResult = createAction('RESET_CHANGE_PASSWORD_RESULT');
const updateUpdateUserErrors = createAction('RESET_UPDATE_USER_ERRORS');

const fetchMembershipPending = createAction('FETCH_MEMBERSHIP_PENDING');
const fetchMembershipCompleted = createAction('FETCH_MEMBERSHIP_COMPLETED');
const fetchMembershipError = createAction('FETCH_MEMBERSHIP_ERROR');

const fetchMembershipTypesPending = createAction(
    'FETCH_MEMBERSHIP_TYPES_PENDING'
);
const fetchMembershipTypesCompleted = createAction(
    'FETCH_MEMBERSHIP_TYPES_COMPLETED'
);
const fetchMembershipTypesError = createAction('FETCH_MEMBERSHIP_TYPES_ERROR');

const fetchMembershipMembersPending = createAction(
    'FETCH_MEMBERSHIP_MEMBERS_PENDING'
);
const fetchMembershipMembersCompleted = createAction(
    'FETCH_MEMBERSHIP_MEMBERS_COMPLETED'
);
const fetchMembershipMembersError = createAction(
    'FETCH_MEMBERSHIP_MEMBERS_ERROR'
);

const updateMembershipMembers = createAction('UPDATE_MEMBERSHIP_MEMBERS');

const updateMembershipPending = createAction('UPDATE_MEMBERSHIP_PENDING');
const updateMembershipCompleted = createAction('UPDATE_MEMBERSHIP_COMPLETED');
const updateMembershipError = createAction('UPDATE_MEMBERSHIP_ERROR');
const resetUpdateMembershipApiResult = createAction(
    'RESET_UPDATE_MEMBERSHIP_API_RESULT'
);
const resetUpdateMembershipErrors = createAction(
    'RESET_UPDATE_MEMBERSHIP_ERRORS'
);

const inviteMemberPending = createAction('INVITE_MEMBER_PENDING');
const inviteMemberCompleted = createAction('INVITE_MEMBER_COMPLETED');
const inviteMemberError = createAction('INVITE_MEMBER_ERROR');
const resetInviteMemberResult = createAction('RESET_INVITE_MEMBER_RESULT');

const removeMembershipMemberPending = createAction(
    'REMOVE_MEMBERSHIP_MEMBER_PENDING'
);
const removeMembershipMemberCompleted = createAction(
    'REMOVE_MEMBERSHIP_MEMBER_COMPLETED'
);
const removeMembershipMemberError = createAction(
    'REMOVE_MEMBERSHIP_MEMBER_ERROR'
);

const actions = {
    createUserPending,
    createUserCompleted,
    createUserError,
    updateCreateUserErrors,
    fetchUserInfoPending,
    fetchUserInfoCompleted,
    fetchUserInfoError,
    unlinkVippsUserPending,
    unlinkVippsUserCompleted,
    unlinkVippsUserError,
    unlinkVippsUserResetError,
    fetchMembershipsPending,
    fetchMembershipsCompleted,
    fetchMembershipsError,
    updateUserInfoPending,
    updateUserInfoCompleted,
    updateUserInfoError,
    resetUpdateUserInfoAndPasswordApiResults,
    resetChangePasswordResult,
    updateUpdateUserErrors,
    changePasswordPending,
    changePasswordCompleted,
    changePasswordError,
    fetchMembershipPending,
    fetchMembershipCompleted,
    fetchMembershipError,
    fetchMembershipTypesPending,
    fetchMembershipTypesCompleted,
    fetchMembershipTypesError,
    fetchMembershipMembersPending,
    fetchMembershipMembersCompleted,
    fetchMembershipMembersError,
    updateMembershipMembers,
    updateMembershipPending,
    updateMembershipCompleted,
    updateMembershipError,
    resetUpdateMembershipApiResult,
    resetUpdateMembershipErrors,
    inviteMemberPending,
    inviteMemberCompleted,
    inviteMemberError,
    resetInviteMemberResult,
    removeMembershipMemberPending,
    removeMembershipMemberCompleted,
    removeMembershipMemberError,
};

export default actions;
