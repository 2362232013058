import React from 'react';
import './PageLoader.css';
const PageLoader = () => (
    <div className="pageLoader">
        <div className="pageLoader__content">
            <div style={{ height: '128px', width: '128px' }}>
                <div className={'pageLoaderLoader'} />
            </div>
        </div>
    </div>
);

// const PageLoaderWrapper = () => setTimeout(() => PageLoader, 1000);

export default PageLoader;
