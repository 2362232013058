import actions from './actions';
import appActions from '../../../duck/actions';
import {
    emptyStringsToNull,
    handleApiCallAndParseData,
} from '../../../../utilities/utils';
import {
    doChangePassword,
    doCreateUser,
    doFetchMembership,
    doFetchMembershipMembers,
    doFetchMemberships,
    doFetchMembershipTypes,
    doFetchUserInfo,
    doRemoveMembershipMember,
    doSendMembershipInvitationEmail,
    doUpdatePersonMembership,
    doUnlinkVippsUser,
    doUpdateMembership,
    doUpdateUserInfo,
} from '../../../../utilities/api';
import {
    selectAuthentication,
    selectMemberships,
    selectWhoamiResponse,
} from '../../../duck/selectors';
import {
    selectCreateUserErrors,
    selectUpdateUserInfoErrors,
    selectUpdateUserInfoResult,
} from './selectors';
import { login, updateShellAppLogin } from '../../../duck/operations';
import LogRocket from 'logrocket';
import { createApiCallObject } from '../../../../utilities/apiUtils';

export function createUser(userInfo) {
    return (dispatch) => {
        dispatch(actions.createUserPending());
        doCreateUser(
            createApiCallObject(dispatch, 'createUser'),
            userInfo
        ).then(
            (response) => {
                response.json().then((json) => {
                    if (json.errors?.length > 0) {
                        console.log('createUser json errors: ', json.errors);
                        dispatch(actions.createUserError(json.errors));
                    } else {
                        dispatch(actions.createUserCompleted());
                        if (userInfo.password) {
                            dispatch(login(userInfo.email, userInfo.password));
                        }
                    }
                });
            },
            (error) => {
                dispatch(actions.createUserError([]));
                console.log(error);
            }
        );
    };
}

export function resetCreateUserError(fieldName) {
    return (dispatch, getState) => {
        let errors = selectCreateUserErrors(getState());
        if (!errors || errors.length === 0) return;
        let index = errors.findIndex((e) => e.fieldName === fieldName);
        if (index === -1) return;
        errors = [...errors];
        errors.splice(index, 1);
        dispatch(actions.updateCreateUserErrors(errors));
    };
}

export function fetchUserInfo() {
    return (dispatch, getState) => {
        if (!navigator.onLine) return;
        dispatch(actions.fetchUserInfoPending());
        return handleApiCallAndParseData(
            doFetchUserInfo(
                selectAuthentication(getState()),
                createApiCallObject(dispatch, 'fetchUserInfo')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.fetchUserInfoCompleted(parsedData));
                updateShellAppLogin(parsedData);
                LogRocket.identify(parsedData.id, {
                    name: parsedData.name,
                    email: parsedData.email,
                });
            },
            (error) => {
                dispatch(actions.fetchUserInfoError());
                console.log(error);
            }
        );
    };
}

export function fetchMemberships() {
    return (dispatch, getState) => {
        dispatch(actions.fetchMembershipsPending());
        return handleApiCallAndParseData(
            doFetchMemberships(
                selectAuthentication(getState()),
                createApiCallObject(dispatch, 'fetchMemberships')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.fetchMembershipsCompleted(parsedData));
                dispatch(
                    appActions.whoamiResponse({
                        ...selectWhoamiResponse(getState()),
                        memberships: parsedData,
                    })
                );
            },
            (error) => {
                dispatch(actions.fetchMembershipsError());
                console.log(error);
            }
        );
    };
}

export function updateUserInfo(userInfo) {
    return (dispatch, getState) => {
        dispatch(actions.updateUserInfoPending());
        return handleApiCallAndParseData(
            doUpdateUserInfo(
                selectAuthentication(getState()),
                emptyStringsToNull(userInfo),
                createApiCallObject(dispatch, 'updateUserInfo')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.updateUserInfoCompleted(parsedData.entity));
            },
            (error) => {
                dispatch(actions.updateUserInfoError(error.errors));
                console.log(error);
            }
        );
    };
}

export function resetUpdateUserError(fieldName) {
    return (dispatch, getState) => {
        let errors = selectUpdateUserInfoErrors(getState());
        if (!errors || errors.length === 0) return;
        let updateUserInfoErrors = [...errors];
        if (errors.length === 1) {
            if (errors[0].fieldName !== fieldName) return;
            updateUserInfoErrors = [];
        } else {
            let index = errors.findIndex((e) => e.fieldName === fieldName);
            if (index === -1) return;
            updateUserInfoErrors.splice(index, 1);
        }
        const updateUserInfoResult =
            updateUserInfoErrors.length === 0
                ? undefined
                : selectUpdateUserInfoResult(getState());

        dispatch(
            actions.updateUpdateUserErrors({
                updateUserInfoResult,
                updateUserInfoErrors,
            })
        );
    };
}

export function resetUpdatePasswordError() {
    return (dispatch) => {
        dispatch(actions.resetChangePasswordResult());
    };
}

export function resetUpdateUserInfoAndPasswordApiResults() {
    return (dispatch) => {
        dispatch(actions.resetUpdateUserInfoAndPasswordApiResults());
    };
}

export function changePassword(currentPassword, newPassword) {
    return (dispatch, getState) => {
        dispatch(actions.changePasswordPending());
        return handleApiCallAndParseData(
            doChangePassword(
                selectAuthentication(getState()),
                currentPassword,
                newPassword,
                createApiCallObject(dispatch, 'changePassword')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.changePasswordCompleted(parsedData));
            },
            (error) => {
                dispatch(actions.changePasswordError());
                console.log(error);
            },
            true
        );
    };
}

export const unlinkVippsUserResetError = () => (dispatch) =>
    dispatch(actions.unlinkVippsUserResetError());

export function unlinkVippsUser() {
    return (dispatch, getState) => {
        dispatch(actions.unlinkVippsUserPending());
        doUnlinkVippsUser(
            selectAuthentication(getState()),
            createApiCallObject(dispatch, 'unlinkVippsUser')
        ).then(
            () => {
                dispatch(actions.unlinkVippsUserCompleted());
                // TODO: Revoke consent from vipps?
            },
            (error) => {
                dispatch(actions.unlinkVippsUserError(error));
                console.log(error);
            }
        );
    };
}

export function fetchMembershipTypes(partnerKey) {
    return (dispatch, getState) => {
        dispatch(actions.fetchMembershipTypesPending());
        return handleApiCallAndParseData(
            doFetchMembershipTypes(
                selectAuthentication(getState()),
                createApiCallObject(dispatch, 'fetchMembershipTypes'),
                partnerKey
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.fetchMembershipTypesCompleted(parsedData));
            },
            (error) => {
                dispatch(actions.fetchMembershipTypesError());
                console.log(error);
            }
        );
    };
}

export function fetchMembership(membershipId) {
    return (dispatch, getState) => {
        dispatch(actions.fetchMembershipPending());
        dispatch(fetchMembershipMembers(membershipId));
        return handleApiCallAndParseData(
            doFetchMembership(
                selectAuthentication(getState()),
                membershipId,
                createApiCallObject(dispatch, 'fetchMembership')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.fetchMembershipCompleted(parsedData));
            },
            (error) => {
                dispatch(actions.fetchMembershipError());
                console.log(error);
            }
        );
    };
}

export function updateMembership(membershipJson) {
    return (dispatch, getState) => {
        dispatch(actions.updateMembershipPending());
        return handleApiCallAndParseData(
            doUpdateMembership(
                selectAuthentication(getState()),
                emptyStringsToNull(membershipJson),
                createApiCallObject(dispatch, 'updateMembership')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.updateMembershipCompleted(parsedData.entity));
            },
            (error) => {
                dispatch(actions.updateMembershipError(error.errors));
                console.log(error);
            }
        );
    };
}

export function resetUpdateMembershipErrors() {
    return (dispatch) => dispatch(actions.resetUpdateMembershipErrors());
}

export function resetUpdateMembershipApiResult() {
    return (dispatch) => dispatch(actions.resetUpdateMembershipApiResult());
}

export function inviteMembershipMember(
    membershipId,
    inviteeName,
    inviteeEmail,
    canCreateReservation,
    canCreatePrivateMembership
) {
    return (dispatch, getState) => {
        dispatch(actions.inviteMemberPending());
        doSendMembershipInvitationEmail(
            selectAuthentication(getState()),
            membershipId,
            inviteeName,
            inviteeEmail,
            canCreateReservation,
            canCreatePrivateMembership
        ).then(
            (response) => {
                if (response.ok) {
                    dispatch(actions.inviteMemberCompleted());
                } else {
                    console.log(response);
                    dispatch(actions.inviteMemberError());
                }
            },
            (error) => {
                console.log(error);
                dispatch(actions.inviteMemberError());
            }
        );
    };
}

export function resetInviteMemberResult() {
    return (dispatch) => dispatch(actions.resetInviteMemberResult());
}

export function removeMembershipMember(membershipId, memberId) {
    return (dispatch, getState) => {
        dispatch(actions.removeMembershipMemberPending());
        return handleApiCallAndParseData(
            doRemoveMembershipMember(
                selectAuthentication(getState()),
                membershipId,
                memberId,
                createApiCallObject(dispatch, 'removeMembershipMember')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.removeMembershipMemberCompleted());
                dispatch(fetchMembershipMembers(membershipId));
            },
            (error) => {
                dispatch(actions.removeMembershipMemberError());
                console.log(error);
            }
        );
    };
}

export function updatePersonMembership(
    membershipId,
    memberPersonId,
    personMembershipOptions
) {
    return (dispatch, getState) => {
        return handleApiCallAndParseData(
            doUpdatePersonMembership(
                selectAuthentication(getState()),
                membershipId,
                memberPersonId,
                personMembershipOptions,
                createApiCallObject(dispatch, 'updatePersonMembership')
            ),
            dispatch,
            (parsedData) => {
                dispatch(actions.updateMembershipMembers(parsedData));
            },
            (error) => {
                console.log(error);
            }
        );
    };
}

export function fetchMembershipMembers(membershipId) {
    return (dispatch, getState) => {
        dispatch(actions.fetchMembershipMembersPending());
        return handleApiCallAndParseData(
            doFetchMembershipMembers(
                selectAuthentication(getState()),
                membershipId,
                createApiCallObject(dispatch, 'fetchMembershipMembers')
            ),
            dispatch,
            (parsedData) => {
                let memberships = selectMemberships(getState());
                let membershipIndex = memberships.findIndex(
                    (m) => m.id === membershipId
                );

                if (
                    memberships &&
                    memberships.length > 0 &&
                    membershipIndex > -1
                ) {
                    memberships = [...memberships];
                    let membership = (memberships[membershipIndex] = {
                        ...memberships[membershipIndex],
                    });

                    membership.members = parsedData;
                    membership.memberCount = parsedData.length;

                    dispatch(
                        actions.fetchMembershipMembersCompleted({
                            membership,
                            memberships,
                        })
                    );
                }
            },
            (error) => {
                dispatch(actions.fetchMembershipMembersError());
                console.log(error);
            }
        );
    };
}
