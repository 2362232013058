import React from 'react';

export const NewItemNotification = props => {
    let content = '!';
    if (props.number) {
        content = props.number;
    }

    let style = {
        backgroundColor: props.theme.notificationColor,
        color: props.theme.color__secondary
    };

    if (props.styleOverride) {
        style = { ...style, ...props.styleOverride };
    }

    return (
        <div className="newItemNotification" style={style}>
            {content}
        </div>
    );
};
