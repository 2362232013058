import React from 'react';
import ButtonWithIcon from '../../common/Buttons/ButtonWithIcon';
import { getCarIndexLabel } from '../../../utilities/searchUtils';
/*
TODO FLOW:
type Props = {
    theme: Object,
    car: Object,
    damageId?: string,
    label: string,
    ariaType: string,
    urlSegment: string
};
*/

export const AdminNewEntityLink = (props) => {
    const car = props.car;
    let carLabel = getCarIndexLabel(car);
    let url = `/admin/${props.urlSegment}/new?value=${
        car.id
    }&label=${encodeURIComponent(carLabel)}`;
    if (props.damageId) url = [url, `&damageId=${props.damageId}`].join('');

    return (
        <ButtonWithIcon
            buttonType={props.buttonType || 'ADMIN_ADD_LINK'}
            id={props.car.id}
            link={url}
            ariaLabel={`Opprett ${props.ariaType} på denne bilen`}
            label={props.label}
            target={'_blank'}
            pathname={'adminNewEntityLink'}
        />
    );
};
