import React, { Fragment } from 'react';
import LoadingPage from './LoadingPage/LoadingPage';
import Redirect from 'react-router-dom/Redirect';
import { ErrorModal } from './ErrorModal/ErrorModal';
import { apiCallError, apiCallSuccess } from '../../utilities/constants';

export const IssuesApiCall = (ComposedComponent) =>
    class extends React.Component {
        constructor(props) {
            super(props);

            if (props.preFetch) {
                props.apiCall(); //TODO denne bør støtte parametere
            }
            this.state = {
                callIssued: false,
                preRenderFetchIssued: !!props.preFetch,
            };
        }

        render() {
            return this.checkThatPropsContainsNecessaryValues();
        }
        componentDidUpdate() {
            if (
                this.state.preRenderFetchIssued &&
                !this.props.apiCallInProgress
            ) {
                this.setState({ preRenderFetchIssued: false });
            }
            if (
                this.props.apiCallResult === apiCallSuccess &&
                this.props.resetOnSuccess
            ) {
                this.props.location.history.replace(this.props.redirectUrl);
                this.setState({ callIssued: false });
            }
        }

        checkThatPropsContainsNecessaryValues = () => {
            return this.props.hasOwnProperty('apiCall') &&
                this.props.hasOwnProperty('apiCallInProgress') &&
                this.props.hasOwnProperty('apiCallResult') &&
                this.props.hasOwnProperty('redirectUrl')
                ? this.renderWithApiCallChecks()
                : this.renderWithoutApiCallChecks();
        };
        renderWithoutApiCallChecks = () => (
            <ComposedComponent {...this.props} />
        );

        renderWithApiCallChecks = () => {
            return this.props.preFetch
                ? this.issuedPreRenderLogic()
                : this.issuedPostRenderLogic();
        };
        issuedPreRenderLogic = () => {
            return this.state.preRenderFetchIssued
                ? this.onLoading()
                : this.props.apiCallResult === apiCallSuccess ||
                  !this.props.apiCallResult ||
                  (Array.isArray(this.props.apiCallResult) &&
                      !this.props.apiCallResult.includes(`${apiCallError}`))
                ? this.onSuccess()
                : this.onError(); //apiCallResult === apiCallError
        };

        issuedPostRenderLogic = () => {
            let { apiCallResult, apiCallInProgress } = this.props;
            return apiCallInProgress ? (
                this.onLoading()
            ) : !this.state.callIssued ? (
                <ComposedComponent {...this.props} issueCall={this.issueCall} />
            ) : apiCallResult === apiCallSuccess ? (
                this.onSuccess()
            ) : apiCallResult === apiCallError ? (
                this.onError()
            ) : (
                this.onLoading()
            );
        };
        onError = () => {
            /*
                TODO improvement
                ComposedComponentHandlesError, boolean som vi sender til componentet slik at det selv kan håndtere en error
             */
            let {
                theme,
                redirectUrl,
                location,
                componentIsModal,
                showModalOnError,
                customErrorMessage,
                optionalErrorUrl,
                optionalResetError,
            } = this.props;
            return showModalOnError ? (
                <Fragment>
                    <ErrorModal
                        heading={'Endring feilet'}
                        errorMessage={
                            customErrorMessage
                                ? customErrorMessage
                                : 'Kunne dessverre ikke utføre endring, forsøk igjen senere.'
                        }
                        theme={theme}
                        onClose={() => {
                            if (optionalResetError) {
                                optionalResetError();
                                this.setState({ callIssued: false });
                            }
                            location.history.replace(
                                optionalErrorUrl
                                    ? optionalErrorUrl
                                    : redirectUrl
                            );
                        }}
                    />
                    {componentIsModal ? (
                        ''
                    ) : (
                        <ComposedComponent
                            {...this.props}
                            issueCall={this.issueCall}
                        />
                    )}
                </Fragment>
            ) : (
                <Redirect
                    to={optionalErrorUrl ? optionalErrorUrl : redirectUrl}
                />
            );
        };
        onSuccess = () => {
            let { preFetch, redirectUrl, stayOnSuccess } = this.props;
            return preFetch || stayOnSuccess ? (
                <ComposedComponent
                    {...this.props}
                    issueCall={this.issueCall}
                    loading={false}
                />
            ) : (
                <Redirect to={redirectUrl} />
            );
        };
        onLoading = () => {
            return this.props.composedComponentHandlesLoading ? (
                <ComposedComponent
                    {...this.props}
                    issueCall={this.issueCall}
                    loading={true}
                />
            ) : (
                <LoadingPage />
            );
        };
        issueCall = (apiCallParams) => {
            if (apiCallParams) this.props.apiCall(...apiCallParams);
            else this.props.apiCall();
            this.setState({ callIssued: true });
        };
    };
