import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../../utilities/constants';
import { AdminSituationState, SpecificSolutionState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    Situation,
    SpecificSolutionIndex,
} from '../../../../../../utilities/types';
import { Metadata } from '../../../../../types/admin';

const defaultSituationState: AdminSituationState = {
    fetchingSituation: false,
    fetchSituationResult: undefined,
    situation: undefined,
};

const defaultSpecificSolutionsState: SpecificSolutionState = {
    fetchingSpecificSolutions: false,
    fetchSpecificSolutionsResult: undefined,
    specificSolutions: undefined,
    fetchingSpecificSolutionsMetadata: false,
    fetchSpecificSolutionsMetadataResult: undefined,
    specificSolutionsMetadata: undefined,
};

const adminSituationSlice = createSlice({
    name: 'situations',
    initialState: defaultSituationState,
    reducers: {
        fetchingSituationPending(state: AdminSituationState) {
            state.fetchingSituation = true;
            state.fetchSituationResult = undefined;
        },
        fetchingSituationCompleted(
            state: AdminSituationState,
            action: PayloadAction<Situation>
        ) {
            state.fetchingSituation = false;
            state.fetchSituationResult = apiCallSuccess;
            state.situation = action.payload;
        },
        fetchingSituationError(state: AdminSituationState) {
            state.fetchingSituation = false;
            state.fetchSituationResult = apiCallError;
        },
    },
});

const adminSpecificSolutionSlice = createSlice({
    name: 'specificSolution',
    initialState: defaultSpecificSolutionsState,
    reducers: {
        fetchingSpecificSolutionsPending(state: SpecificSolutionState) {
            state.fetchingSpecificSolutions = false;
            state.fetchSpecificSolutionsResult = undefined;
        },
        fetchingSpecificSolutionsCompleted(
            state: SpecificSolutionState,
            action: PayloadAction<SpecificSolutionIndex[]>
        ) {
            state.fetchingSpecificSolutions = false;
            state.fetchSpecificSolutionsResult = apiCallSuccess;
            state.specificSolutions = action.payload;
        },
        fetchingSpecificSolutionsError(state: SpecificSolutionState) {
            state.fetchingSpecificSolutions = false;
            state.fetchSpecificSolutionsResult = apiCallError;
        },
        fetchingSpecificSolutionsMetadataPending(state: SpecificSolutionState) {
            state.fetchingSpecificSolutionsMetadata = true;
            state.fetchSpecificSolutionsMetadataResult = undefined;
        },
        fetchingSpecificSolutionsMetadataCompleted(
            state: SpecificSolutionState,
            action: PayloadAction<Metadata<SpecificSolutionIndex>>
        ) {
            state.fetchingSpecificSolutionsMetadata = false;
            state.fetchSpecificSolutionsMetadataResult = apiCallSuccess;
            state.specificSolutionsMetadata = action.payload;
        },
        fetchingSpecificSolutionsMetadataError(state: SpecificSolutionState) {
            state.fetchingSpecificSolutionsMetadata = false;
            state.fetchSpecificSolutionsMetadataResult = apiCallError;
        },
    },
});

export const {
    fetchingSituationPending,
    fetchingSituationCompleted,
    fetchingSituationError,
} = adminSituationSlice.actions;

export const {
    fetchingSpecificSolutionsPending,
    fetchingSpecificSolutionsCompleted,
    fetchingSpecificSolutionsError,
    fetchingSpecificSolutionsMetadataPending,
    fetchingSpecificSolutionsMetadataCompleted,
    fetchingSpecificSolutionsMetadataError,
} = adminSpecificSolutionSlice.actions;

export const situationReducer = adminSituationSlice.reducer;
export const specificSolutionsReducer = adminSpecificSolutionSlice.reducer;
