import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../../../utilities/constants';

interface State {
    editingReservationMessage?: {
        pictures?: object[];
    };

    reservationMessages?: object;

    fetchingReservationMessages?: boolean;
    fetchReservationMessagesResult?: string;

    updatingReservationMessage?: boolean;
    updateReservationMessageResult?: string;

    deletingReservationMessages?: boolean;
    deleteReservationMessagesResult?: string;
}

const initialState: State = {};

const adminReservationMessageSlice = createSlice({
    name: 'adminReservationMessages',
    initialState,
    reducers: {
        setEditingReservationMessage(
            state: State,
            action: PayloadAction<object>
        ) {
            state.editingReservationMessage = action.payload;
        },

        postReservationMessagePicturePending(
            state: State,
            action: PayloadAction<object[]>
        ) {
            if (state.editingReservationMessage)
                state.editingReservationMessage.pictures = action.payload;
        },
        postReservationMessagePictureCompleted(
            state: State,
            action: PayloadAction<object[]>
        ) {
            if (state.editingReservationMessage)
                state.editingReservationMessage.pictures = action.payload;
        },

        postReservationMessagePictureError(
            state: State,
            action: PayloadAction<object[]>
        ) {
            if (state.editingReservationMessage)
                state.editingReservationMessage.pictures = action.payload;
        },

        fetchReservationMessagePending(state: State) {
            state.fetchingReservationMessages = true;
            state.fetchReservationMessagesResult = undefined;
        },
        fetchReservationMessageCompleted(
            state: State,
            action: PayloadAction<object>
        ) {
            state.fetchingReservationMessages = false;
            state.fetchReservationMessagesResult = apiCallSuccess;
            state.reservationMessages = action.payload;
        },
        fetchReservationMessageError(state: State) {
            state.fetchingReservationMessages = false;
            state.fetchReservationMessagesResult = apiCallError;
        },

        updateReservationMessagePending(state: State) {
            state.updatingReservationMessage = true;
            state.updateReservationMessageResult = undefined;
        },
        updateReservationMessageCompleted(
            state: State,
            action: PayloadAction<object>
        ) {
            state.updatingReservationMessage = false;
            state.updateReservationMessageResult = apiCallSuccess;
            state.reservationMessages = action.payload;
        },
        updateReservationMessageError(state: State) {
            state.updatingReservationMessage = false;
            state.updateReservationMessageResult = apiCallError;
        },
        resetUpdateReservationMessageResult(state: State) {
            state.updateReservationMessageResult = undefined;
        },

        deleteReservationMessagePending(state: State) {
            state.deletingReservationMessages = true;
            state.deleteReservationMessagesResult = undefined;
        },
        deleteReservationMessageCompleted(state: State) {
            state.deletingReservationMessages = false;
            state.deleteReservationMessagesResult = apiCallSuccess;
        },
        deleteReservationMessageError(state: State) {
            state.deletingReservationMessages = false;
            state.deleteReservationMessagesResult = apiCallError;
        },
        resetDeleteReservationMessageResult(state: State) {
            state.deleteReservationMessagesResult = undefined;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase('CLEAR_STATE', () => initialState);
    },
});

export const {
    setEditingReservationMessage,
    postReservationMessagePicturePending,
    postReservationMessagePictureCompleted,
    postReservationMessagePictureError,
    fetchReservationMessagePending,
    fetchReservationMessageCompleted,
    fetchReservationMessageError,
    updateReservationMessagePending,
    updateReservationMessageCompleted,
    updateReservationMessageError,
    resetUpdateReservationMessageResult,
    deleteReservationMessagePending,
    deleteReservationMessageCompleted,
    deleteReservationMessageError,
    resetDeleteReservationMessageResult,
} = adminReservationMessageSlice.actions;

export default adminReservationMessageSlice.reducer;
