import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../utilities/constants';
import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { TripletexError, TripletexState } from './types';
import { Metadata } from '../../../../types/admin';

const initialState: TripletexState = {
    tripletexErrors: [],
    fetchingTripletexErrors: false,
    fetchTripletexErrorsResult: undefined,

    tripletexErrorsMetadata: undefined,
    fetchingTripletexErrorsMetadata: false,
    fetchTripletexErrorsMetadataResult: undefined,

    tripletexErrorCount: 0,
    fetchingTripletexErrorCount: false,
    fetchTripletexErrorCountResult: undefined,
};

const adminTripletexErrorsSlice = createSlice({
    name: 'tripletex',
    initialState,
    reducers: {
        fetchAdminTripletexErrorsPending(state: Draft<TripletexState>) {
            state.fetchingTripletexErrors = true;
            state.fetchTripletexErrorsResult = undefined;
        },
        fetchAdminTripletexErrorsCompleted(
            state: Draft<TripletexState>,
            action: PayloadAction<TripletexError[]>
        ) {
            state.fetchingTripletexErrors = false;
            state.fetchTripletexErrorsResult = apiCallSuccess;
            state.tripletexErrors = action.payload;
        },
        fetchAdminTripletexErrorsError(state: Draft<TripletexState>) {
            state.fetchingTripletexErrors = false;
            state.fetchTripletexErrorsResult = apiCallError;
        },

        fetchAdminTripletexErrorsMetadataPending(state: Draft<TripletexState>) {
            state.fetchingTripletexErrorsMetadata = true;
            state.fetchTripletexErrorsMetadataResult = undefined;
        },
        fetchAdminTripletexErrorsMetadataCompleted(
            state: Draft<TripletexState>,
            action: PayloadAction<Metadata<TripletexError>>
        ) {
            state.fetchingTripletexErrorsMetadata = false;
            state.fetchTripletexErrorsMetadataResult = apiCallSuccess;
            state.tripletexErrorsMetadata = action.payload;
        },
        fetchAdminTripletexErrorsMetadataError(state: Draft<TripletexState>) {
            state.fetchingTripletexErrorsMetadata = false;
            state.fetchTripletexErrorsMetadataResult = apiCallError;
        },

        fetchAdminTripletexErrorCountPending(state: Draft<TripletexState>) {
            state.fetchingTripletexErrorCount = true;
            state.fetchTripletexErrorCountResult = undefined;
        },
        fetchAdminTripletexErrorCountCompleted(
            state: Draft<TripletexState>,
            action: PayloadAction<number>
        ) {
            state.fetchingTripletexErrorCount = false;
            state.fetchTripletexErrorCountResult = apiCallSuccess;
            state.tripletexErrorCount = action.payload;
        },
        fetchAdminTripletexErrorCountError(state: Draft<TripletexState>) {
            state.fetchingTripletexErrorCount = false;
            state.fetchTripletexErrorCountResult = apiCallError;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase('CLEAR_STATE', () => initialState);
    },
});

export const {
    fetchAdminTripletexErrorsPending,
    fetchAdminTripletexErrorsCompleted,
    fetchAdminTripletexErrorsError,

    fetchAdminTripletexErrorsMetadataPending,
    fetchAdminTripletexErrorsMetadataCompleted,
    fetchAdminTripletexErrorsMetadataError,

    fetchAdminTripletexErrorCountPending,
    fetchAdminTripletexErrorCountCompleted,
    fetchAdminTripletexErrorCountError,
} = adminTripletexErrorsSlice.actions;

const tripletexReducer = adminTripletexErrorsSlice.reducer;
export default tripletexReducer;
