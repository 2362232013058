import { handleActions } from 'redux-actions';
import actions from './actions';
import { MAX_AVAILABILITY, PRICE, LOCATION, PROXIMITY } from './constants';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { apiCallError, apiCallSuccess } from '../../../../utilities/constants';

const defaultDataState = {
    fetchingCars: false,
    fetchingReservation: false,
    fetchingLocationDistances: false,
    fetchingExternalLocations: false,
    locationSearchString: '',
    cars: [],
    fetchingSwappableCars: false,
    fetchSwappableCarsResult: undefined,
    swappableCars: [],
    locationDistances: [],
    externalLocations: [],
    previouslySelectedLocations: [],
    reservation: undefined,
    fetchingPriceOfCurrentCar: false,
    priceOfCurrentCar: undefined,
    reservingCar: false,
    reservationResult: undefined,
};

const dataReducer = handleActions(
    {
        [actions.fetchCarsPending]: (state) => ({
            ...state,
            fetchingCars: true,
        }),
        [actions.fetchCarsCompleted]: (state, action) => ({
            ...state,
            fetchingCars: false,
            cars: action.payload.cars,
            messages: action.payload.messages,
        }),
        [actions.fetchCarsError]: (state) => ({
            ...state,
            fetchingCars: false,
        }),

        [actions.fetchSwappableCarsPending]: (state) => ({
            ...state,
            fetchingSwappableCars: true,
            fetchSwappableCarsResult: undefined,
        }),
        [actions.fetchSwappableCarsCompleted]: (state, action) => ({
            ...state,
            fetchingSwappableCars: false,
            fetchSwappableCarsResult: apiCallSuccess,
            swappableCars: action.payload,
        }),
        [actions.fetchSwappableCarsError]: (state) => ({
            ...state,
            fetchSwappableCarsResult: apiCallError,
            fetchingSwappableCars: false,
        }),

        [actions.checkConcurrentReservationLimitPending]: (state) => ({
            ...state,
            checkingConcurrentReservationLimit: true,
            checkConcurrentReservationLimitResult: undefined,
        }),
        [actions.checkConcurrentReservationLimitCompleted]: (
            state,
            action
        ) => ({
            ...state,
            checkingConcurrentReservationLimit: false,
            checkConcurrentReservationLimitResult: apiCallSuccess,
            concurrentReservationLimitResponse: action.payload,
        }),
        [actions.checkConcurrentReservationLimitError]: (state) => ({
            ...state,
            checkingConcurrentReservationLimit: false,
            checkConcurrentReservationLimitResult: apiCallError,
            concurrentReservationLimitResponse: undefined,
        }),

        [actions.setShowingSwapCarOptions]: (state, action) => ({
            ...state,
            showingSwapCarOptions: action.payload,
        }),
        [actions.bookingReservationPending]: (state) => ({
            ...state,
            fetchingReservation: true,
        }),
        [actions.bookingReservationCompleted]: (state, action) => ({
            ...state,
            fetchingReservation: false,
            reservation: action.payload,
        }),
        [actions.updatingData]: (state) => ({
            ...state,
            fetchingCars: true,
        }),
        [actions.setLocationSearchString]: (state, action) => ({
            ...state,
            locationSearchString: action.payload,
        }),
        [actions.setPreviouslySelectedLocations]: (state, action) => ({
            ...state,
            previouslySelectedLocations: action.payload,
        }),
        [actions.fetchLocationDistancesPending]: (state) => ({
            ...state,
            fetchingLocationDistances: true,
        }),
        [actions.fetchLocationDistancesCompleted]: (state, action) => ({
            ...state,
            fetchingLocationDistances: false,
            locationDistances: action.payload,
        }),
        [actions.fetchLocationDistancesError]: (state) => ({
            ...state,
            fetchingLocationDistances: false,
        }),
        [actions.fetchExternalLocationsPending]: (state) => ({
            ...state,
            fetchingExternalLocations: true,
        }),
        [actions.fetchExternalLocationsCompleted]: (state, action) => ({
            ...state,
            fetchingExternalLocations: false,
            externalLocations: action.payload,
        }),
        [actions.bookingConfirmationPending]: (state) => ({
            ...state,
            reservingCar: true,
            reservingCarResult: undefined,
        }),
        [actions.bookingConfirmationError]: (state, action) => ({
            ...state,
            reservingCar: false,
            //TODO gjør om til bool

            reservingCarResult: action.payload ? action.payload : 'ERROR',
        }),
        [actions.resetBookingConfirmationError]: (state) => ({
            ...state,
            reservingCarResult: undefined,
        }),
        [actions.bookingConfirmationCompleted]: (state) => ({
            ...state,
            reservingCar: false,
            reservingCarResult: 'SUCCESS',
        }),
        [actions.fetchPriceOfCurrentCarPending]: (state) => ({
            ...state,
            fetchingPriceOfCurrentCar: true,
        }),
        [actions.fetchPriceOfCurrentCarError]: (state) => ({
            ...state,
            fetchingPriceOfCurrentCar: false,
        }),
        [actions.fetchPriceOfCurrentCarCompleted]: (state, action) => ({
            ...state,
            fetchingPriceOfCurrentCar: false,
            priceOfCurrentCar: action.payload,
        }),
        CLEAR_STATE: (state) => {
            return {
                ...state,
                fetchingCars: false,
                fetchingReservation: false,
                fetchingLocationDistances: false,
                fetchingExternalLocations: false,
                locationSearchString: '',
                previouslySelectedLocations: [],
                reservation: undefined,
                fetchingPriceOfCurrentCar: false,
                priceOfCurrentCar: undefined,
                reservingCar: false,
                reservationResult: undefined,
            };
        },
    },
    defaultDataState
);

const defaultSearchState = {
    startTime: undefined,
    endTime: undefined,
    selectedCarId: undefined,
    gettingGpsPosition: false,
    fetchingFilters: false,
    filters: undefined,
    hideUnavailableCars: false,
    superDamageWaiver: true,
    selectedPosition: undefined,
    gpsPositionDisabled: false,
};

const searchReducer = handleActions(
    {
        [actions.initializeSearch]: (state, action) => ({
            ...state,
            startTime: action.payload.startTime,
            endTime: action.payload.endTime,
        }),
        [actions.updateTimes]: (state, action) => ({
            ...state,
            startTime: action.payload.start,
            endTime: action.payload.end,
        }),
        [actions.setSelectedPosition]: (state, action) => ({
            ...state,
            selectedPosition: action.payload,
        }),
        [actions.setGpsPositioningDisabled]: (state, action) => ({
            ...state,
            gpsPositionDisabled: action.payload,
        }),
        [actions.gettingGpsPositionPending]: (state) => ({
            ...state,
            gettingGpsPosition: true,
        }),
        [actions.gettingGpsPositionCompleted]: (state) => ({
            ...state,
            gettingGpsPosition: false,
        }),
        [actions.gettingGpsPositionError]: (state) => ({
            ...state,
            gettingGpsPosition: false,
        }),
        [actions.fetchFiltersPending]: (state) => ({
            ...state,
            fetchingFilters: true,
        }),
        [actions.fetchFiltersCompleted]: (state, action) => ({
            ...state,
            fetchingFilters: false,
            filters: action.payload,
        }),
        [actions.setFilters]: (state, action) => ({
            ...state,
            filters: action.payload,
        }),
        [actions.setFiltersAreActive]: (state, action) => ({
            ...state,
            filtersAreActive: action.payload,
        }),
        [actions.setHideUnavailableCars]: (state, action) => ({
            ...state,
            hideUnavailableCars: action.payload,
        }),
        [actions.selectCar]: (state, action) => ({
            ...state,
            selectedCarId: action.payload.id,
            selectedCar: action.payload,
        }),
        [actions.setSuperDamageWaiver]: (state, action) => ({
            ...state,
            superDamageWaiver: action.payload,
        }),
        [actions.setKilometerEstimate]: (state, action) => ({
            ...state,
            kilometerEstimate: action.payload,
        }),
        CLEAR_STATE: (state) => {
            return {
                ...state,
                startTime: '',
                endTime: '',
                selectedCarId: undefined,
                gettingGpsPosition: false,
                fetchingFilters: false,
                superDamageWaiver: true,
            };
        },
    },
    defaultSearchState
);

const defaultAvailabilityState = {
    initializingAvailability: false,
    startTime: '',
    endTime: '',
    fetchingAvailabilityInfo: false,
    isAvailable: false,
    availablePeriods: [],
    fetchingReservations: false,
    reservations: [],
};

const availabilityReducer = handleActions(
    {
        [actions.initAvailabilityPending]: (state) => ({
            ...state,
            initializingAvailability: true,
            startTime: '',
            endTime: '',
            isAvailable: false,
            availablePeriods: [],
            fetchingReservations: false,
            reservedDays: [],
        }),
        [actions.initAvailabilityCompleted]: (state, action) => ({
            ...state,
            initializingAvailability: false,
        }),
        [actions.setAvailabilityStartTime]: (state, action) => ({
            ...state,
            startTime: action.payload,
        }),
        [actions.setAvailabilityEndTime]: (state, action) => ({
            ...state,
            endTime: action.payload,
        }),
        [actions.fetchAvailabilityInfoPending]: (state) => ({
            ...state,
            fetchingAvailabilityInfo: true,
        }),
        [actions.fetchAvailabilityInfoError]: (state) => ({
            ...state,
            fetchingAvailabilityInfo: false,
        }),
        [actions.fetchAvailabilityInfoCompleted]: (state, action) => ({
            ...state,
            fetchingAvailabilityInfo: false,
            isAvailable: action.payload.isPeriodAvailable,
            availablePeriods: action.payload.periods,
        }),
        [actions.fetchReservedDaysPending]: (state) => ({
            ...state,
            fetchingReservations: true,
        }),
        [actions.fetchReservedDaysCompleted]: (state, action) => ({
            ...state,
            fetchingReservations: false,
            reservedDays: action.payload,
        }),
        CLEAR_STATE: () => defaultSearchState,
    },
    defaultAvailabilityState
);

const defaultUiState = {
    showCarFilterView: false,
    mapPosition: undefined,
    sortingPriority: [PROXIMITY, LOCATION, MAX_AVAILABILITY, PRICE],
    showCityBikes: false,
};

//TODO remove unused functions
const uiReducer = handleActions(
    {
        [actions.setMapPosition]: (state, action) => ({
            ...state,
            mapPosition: action.payload,
        }),
        [actions.toggleCarFilterView]: (state) => {
            return { ...state, showCarFilterView: !state.showCarFilterView };
        },
        [actions.setShowCityBikes]: (state, action) => ({
            ...state,
            showCityBikes: action.payload,
        }),
        [actions.setCityBikes]: (state, action) => ({
            ...state,
            cityBikes: action.payload,
        }),
        CLEAR_STATE: () => defaultUiState,
    },
    defaultUiState
);

const searchPersistConfig = {
    key: 'search',
    storage: storage,
    whitelist: ['selectedPosition', 'gpsPositionDisabled'],
};

const dataPersistConfig = {
    key: 'searchData',
    storage: storage,
    whitelist: ['previouslySelectedLocations'],
};

const reducer = combineReducers({
    searchData: persistReducer(dataPersistConfig, dataReducer),
    search: persistReducer(searchPersistConfig, searchReducer),
    availability: availabilityReducer,
    ui: uiReducer,
});

export default reducer;
