import { createAction } from 'redux-actions';

const fetchConflictsPending = createAction('FETCH_CONFLICTS_PENDING');
const fetchConflictsCompleted = createAction('FETCH_CONFLICTS_COMPLETED');
const fetchConflictsError = createAction('FETCH_CONFLICTS_ERROR');

const sendSmsPending = createAction('SEND_SMS_PENDING');
const sendSmsCompleted = createAction('SEND_SMS_COMPLETED');
const sendSmsError = createAction('SEND_SMS_ERROR');

const fetchingAdminReservationsPending = createAction(
    'FETCHING_ADMIN_RESERVATIONS_PENDING'
);
const fetchingAdminReservationsCompleted = createAction(
    'FETCHING_ADMIN_RESERVATIONS_COMPLETED'
);
const fetchingAdminReservationsError = createAction(
    'FETCHING_ADMIN_RESERVATIONS_ERROR'
);

const fetchingAdminReservationsMetadataPending = createAction(
    'FETCHING_ADMIN_RESERVATIONS_METADATA_PENDING'
);
const fetchingAdminReservationsMetadataCompleted = createAction(
    'FETCHING_ADMIN_RESERVATIONS_METADATA_COMPLETED'
);
const fetchingAdminReservationsMetadataError = createAction(
    'FETCHING_ADMIN_RESERVATIONS_METADATA_ERROR'
);

const fetchingAdminReservationMessagesPending = createAction(
    'FETCHING_ADMIN_RESERVATION_MESSAGES_PENDING'
);
const fetchingAdminReservationMessagesCompleted = createAction(
    'FETCHING_ADMIN_RESERVATION_MESSAGES_COMPLETED'
);
const fetchingAdminReservationMessagesError = createAction(
    'FETCHING_ADMIN_RESERVATION_MESSAGES_ERROR'
);
const fetchingAdminReservationMessagesMetadataPending = createAction(
    'FETCHING_ADMIN_RESERVATION_MESSAGES_METADATA_PENDING'
);
const fetchingAdminReservationMessagesMetadataCompleted = createAction(
    'FETCHING_ADMIN_RESERVATION_MESSAGES_METADATA_COMPLETED'
);
const fetchingAdminReservationMessagesMetadataError = createAction(
    'FETCHING_ADMIN_RESERVATION_MESSAGES_METADATA_ERROR'
);

const fetchingAdminReservationMessagePending = createAction(
    'FETCHING_ADMIN_RESERVATION_MESSAGE_PENDING'
);
const fetchingAdminReservationMessageCompleted = createAction(
    'FETCHING_ADMIN_RESERVATION_MESSAGE_COMPLETED'
);
const fetchingAdminReservationMessageError = createAction(
    'FETCHING_ADMIN_RESERVATION_MESSAGE_ERROR'
);

const fetchingAdminAdminReservationsPending = createAction(
    'FETCHING_ADMIN_ADMIN_RESERVATIONS_PENDING'
);
const fetchingAdminAdminReservationsCompleted = createAction(
    'FETCHING_ADMIN_ADMIN_RESERVATIONS_COMPLETED'
);
const fetchingAdminAdminReservationsError = createAction(
    'FETCHING_ADMIN_ADMIN_RESERVATIONS_ERROR'
);
const fetchingAdminAdminReservationsMetadataPending = createAction(
    'FETCHING_ADMIN_ADMIN_RESERVATIONS_METADATA_PENDING'
);
const fetchingAdminAdminReservationsMetadataCompleted = createAction(
    'FETCHING_ADMIN_ADMIN_RESERVATIONS_METADATA_COMPLETED'
);
const fetchingAdminAdminReservationsMetadataError = createAction(
    'FETCHING_ADMIN_ADMIN_RESERVATIONS_METADATA_ERROR'
);

const fetchingAdminReservationPending = createAction(
    'FETCHING_ADMIN_RESERVATION_PENDING'
);
const fetchingAdminReservationCompleted = createAction(
    'FETCHING_ADMIN_RESERVATION_COMPLETED'
);
const fetchingAdminReservationError = createAction(
    'FETCHING_ADMIN_RESERVATION_ERROR'
);

const adminReservationResendReservationPending = createAction(
    'ADMIN_RESERVATION_RESEND_PENDING'
);
const adminReservationResendReservationCompleted = createAction(
    'ADMIN_RESERVATION_RESEND_COMPLETED'
);
const adminReservationResendReservationError = createAction(
    'ADMIN_RESERVATION_RESEND_ERROR'
);

const resetAdminReservationResendReservation = createAction(
    'RESET_ADMIN_RESERVATION_RESEND'
);

const invoicingAdminReservationPending = createAction(
    'INVOICING_ADMIN_RESERVATION_PENDING'
);
const invoicingAdminReservationCompleted = createAction(
    'INVOICING_ADMIN_RESERVATION_COMPLETED'
);
const invoicingAdminReservationError = createAction(
    'INVOICING_ADMIN_RESERVATION_ERROR'
);

const fetchReservationChangesPending = createAction(
    'FETCH_RESERVATION_CHANGES_PENDING'
);
const fetchReservationChangesCompleted = createAction(
    'FETCH_RESERVATION_CHANGES_COMPLETED'
);
const fetchReservationChangesError = createAction(
    'FETCH_RESERVATION_CHANGES_ERROR'
);

const fetchReservationChangeLogBodyPending = createAction(
    'FETCH_RESERVATION_CHANGE_LOG_PENDING'
);
const fetchReservationChangeLogBodyCompleted = createAction(
    'FETCH_RESERVATION_CHANGE_LOG_COMPLETED'
);
const fetchReservationChangeLogBodyError = createAction(
    'FETCH_RESERVATION_CHANGE_LOG_ERROR'
);

const fetchingAdminReservationTripsPending = createAction(
    'FETCHING_ADMIN_RESERVATION_TRIPS_PENDING'
);
const fetchingAdminReservationTripsCompleted = createAction(
    'FETCHING_ADMIN_RESERVATION_TRIPS_COMPLETED'
);
const fetchingAdminReservationTripsError = createAction(
    'FETCHING_ADMIN_RESERVATION_TRIPS_ERROR'
);

const fetchingAdminReservationExtensibilityPending = createAction(
    'FETCHING_ADMIN_RESERVATION_EXTENSIBILITY_PENDING'
);
const fetchingAdminReservationExtensibilityCompleted = createAction(
    'FETCHING_ADMIN_RESERVATION_EXTENSIBILITY_COMPLETED'
);
const fetchingAdminReservationExtensibilityError = createAction(
    'FETCHING_ADMIN_RESERVATION_EXTENSIBILITY_ERROR'
);

const fetchingAdminReservationConflictsPending = createAction(
    'FETCHING_ADMIN_RESERVATION_CONFLICTS_PENDING'
);
const fetchingAdminReservationConflictsCompleted = createAction(
    'FETCHING_ADMIN_RESERVATION_CONFLICTS_COMPLETED'
);
const fetchingAdminReservationConflictsError = createAction(
    'FETCHING_ADMIN_RESERVATION_CONFLICTS_ERROR'
);

const fetchingAdminProblemReservationsPending = createAction(
    'FETCHING_ADMIN_PROBLEM_RESERVATIONS_PENDING'
);
const fetchingAdminProblemReservationsCompleted = createAction(
    'FETCHING_ADMIN_PROBLEM_RESERVATIONS_COMPLETED'
);
const fetchingAdminProblemReservationsError = createAction(
    'FETCHING_ADMIN_PROBLEM_RESERVATIONS_ERROR'
);

const fetchingAdminReservationDriversPending = createAction(
    'FETCHING_ADMIN_RESERVATION_DRIVERS_PENDING'
);
const fetchingAdminReservationDriversCompleted = createAction(
    'FETCHING_ADMIN_RESERVATION_DRIVERS_COMPLETED'
);
const fetchingAdminReservationDriversError = createAction(
    'FETCHING_ADMIN_RESERVATION_DRIVERS_ERROR'
);

const addReservationDriverPending = createAction(
    'ADD_RESERVATION_DRIVER_PENDING'
);
const addReservationDriverCompleted = createAction(
    'ADD_RESERVATION_DRIVER_COMPLETED'
);
const addReservationDriverError = createAction('ADD_RESERVATION_DRIVER_ERROR');

const removeReservationDriverPending = createAction(
    'REMOVE_RESERVATION_DRIVER_PENDING'
);
const removeReservationDriverCompleted = createAction(
    'REMOVE_RESERVATION_DRIVER_COMPLETED'
);
const removeReservationDriverError = createAction(
    'REMOVE_RESERVATION_DRIVER_ERROR'
);

const putReservationPending = createAction('PUT_RESERVATION_PENDING');
const putReservationCompleted = createAction('PUT_RESERVATION_COMPLETED');
const putReservationError = createAction('PUT_RESERVATION_ERROR');
const resetPutReservationResult = createAction('RESET_PUT_RESERVATION_RESULT');

const cancelReservationPending = createAction('CANCEL_RESERVATION_PENDING');
const cancelReservationCompleted = createAction('CANCEL_RESERVATION_COMPLETED');
const cancelReservationError = createAction('CANCEL_RESERVATION_ERROR');

const voidReservationPending = createAction('VOID_RESERVATION_PENDING');
const voidReservationCompleted = createAction('VOID_RESERVATION_COMPLETED');
const voidReservationError = createAction('VOID_RESERVATION_ERROR');

const createAdminReservationPending = createAction(
    'CREATE_ADMIN_RESERVATION_PENDING'
);
const createAdminReservationCompleted = createAction(
    'CREATE_ADMIN_RESERVATION_COMPLETED'
);
const createAdminReservationError = createAction(
    'CREATE_ADMIN_RESERVATION_ERROR'
);

const resetReservationReviewFlagPending = createAction(
    'RESET_RESERVATION_REVIEW_FLAG_PENDING'
);
const resetReservationReviewFlagCompleted = createAction(
    'RESET_RESERVATION_REVIEW_FLAG_COMPLETED'
);
const resetReservationReviewFlagError = createAction(
    'RESET_RESERVATION_REVIEW_FLAG_ERROR'
);

const fetchingAdminBillingDataPending = createAction(
    'FETCHING_ADMIN_BILLING_DATA_PENDING'
);
const fetchingAdminBillingDataCompleted = createAction(
    'FETCHING_ADMIN_BILLING_DATA_COMPLETED'
);
const fetchingAdminBillingDataError = createAction(
    'FETCHING_ADMIN_BILLING_DATA_ERROR'
);

const setInvoiceSummaryBeforeDate = createAction(
    'SET_ADMIN_INVOICE_SUMMARY_BEFORE_DATE'
);

const setIncludeNonReservationLines = createAction(
    'SET_INCLUDE_NON_RESERVATION_LINES'
);

const clearAdminInvoiceSummary = createAction('CLEAR_ADMIN_INVOICE_SUMMARY');

const fetchingAdminInvoiceSummaryPending = createAction(
    'FETCHING_ADMIN_INVOICE_SUMMARY_PENDING'
);
const fetchingAdminInvoiceSummaryCompleted = createAction(
    'FETCHING_ADMIN_INVOICE_SUMMARY_COMPLETED'
);
const fetchingAdminInvoiceSummaryError = createAction(
    'FETCHING_ADMIN_INVOICE_SUMMARY_ERROR'
);

const fetchingAdminInvoicesPending = createAction(
    'FETCHING_ADMIN_INVOICE_DATA_PENDING'
);
const fetchingAdminInvoicesCompleted = createAction(
    'FETCHING_ADMIN_INVOICE_DATA_COMPLETED'
);
const fetchingAdminInvoicesError = createAction(
    'FETCHING_ADMIN_INVOICE_DATA_ERROR'
);

const fetchingAdminInvoiceMetadataPending = createAction(
    'FETCHING_ADMIN_INVOICE_METADATA_PENDING'
);
const fetchingAdminInvoiceMetadataCompleted = createAction(
    'FETCHING_ADMIN_INVOICE_METADATA_COMPLETED'
);
const fetchingAdminInvoiceMetadataError = createAction(
    'FETCHING_ADMIN_INVOICE_METADATA_ERROR'
);

const fetchingAdminInvoiceDetailsPending = createAction(
    'FETCHING_ADMIN_INVOICE_DETAILS_PENDING'
);
const fetchingAdminInvoiceDetailsCompleted = createAction(
    'FETCHING_ADMIN_INVOICE_DETAILS_COMPLETED'
);
const fetchingAdminInvoiceDetailsError = createAction(
    'FETCHING_ADMIN_INVOICE_DETAILS_ERROR'
);

const createInvoicePending = createAction('CREATE_INVOICE_PENDING');
const createInvoiceCompleted = createAction('CREATE_INVOICE_COMPLETED');
const createInvoiceError = createAction('CREATE_INVOICE_ERROR');

const resetCreateInvoicesResponse = createAction(
    'RESET_CREATE_INVOICES_RESPONSE'
);

const sendInvoicePending = createAction('SEND_INVOICE_PENDING');
const sendInvoiceCompleted = createAction('SEND_INVOICE_COMPLETED');
const sendInvoiceError = createAction('SEND_INVOICE_ERROR');

const fetchProductsPending = createAction('FETCH_PRODUCTS_PENDING');
const fetchProductsCompleted = createAction('FETCH_PRODUCTS_COMPLETED');
const fetchProductsError = createAction('FETCH_PRODUCTS_ERROR');

const fetchProductsMetadataPending = createAction(
    'FETCH_PRODUCTS_METADATA_PENDING'
);
const fetchProductsMetadataCompleted = createAction(
    'FETCH_PRODUCTS_METADATA_COMPLETED'
);
const fetchProductsMetadataError = createAction(
    'FETCH_PRODUCTS_METADATA_ERROR'
);

const fetchProductVatCodesPending = createAction(
    'FETCH_PRODUCT_VAT_CODES_PENDING'
);
const fetchProductVatCodesCompleted = createAction(
    'FETCH_PRODUCT_VAT_CODES_COMPLETED'
);
const fetchProductVatCodesError = createAction('FETCH_PRODUCT_VAT_CODES_ERROR');

const updateProductPending = createAction('UPDATE_PRODUCT_PENDING');
const updateProductCompleted = createAction('UPDATE_PRODUCT_COMPLETED');
const updateProductError = createAction('UPDATE_PRODUCT_ERROR');

const fetchProductPending = createAction('FETCH_PRODUCT_PENDING');
const fetchProductCompleted = createAction('FETCH_PRODUCT_COMPLETED');
const fetchProductError = createAction('FETCH_PRODUCT_ERROR');

const fetchProductCategoriesPending = createAction(
    'FETCH_PRODUCT_CATEGORIES_PENDING'
);
const fetchProductCategoriesCompleted = createAction(
    'FETCH_PRODUCT_CATEGORIES_COMPLETED'
);
const fetchProductCategoriesError = createAction(
    'FETCH_PRODUCT_CATEGORIES_ERROR'
);

const createProductPending = createAction('CREATE_PRODUCT_PENDING');
const createProductCompleted = createAction('CREATE_PRODUCT_COMPLETED');
const createProductError = createAction('CREATE_PRODUCT_ERROR');

const setBillingData = createAction('SET_BILLING_DATA');

const fetchingAdminBillingMetadataPending = createAction(
    'FETCHING_ADMIN_BILLING_METADATA_PENDING'
);
const fetchingAdminBillingMetadataCompleted = createAction(
    'FETCHING_ADMIN_BILLING_METADATA_COMPLETED'
);
const fetchingAdminBillingMetadataError = createAction(
    'FETCHING_ADMIN_BILLING_METADATA_ERROR'
);
const updateBillingData = createAction('UPDATE_BILLING_DATA');

const setBlockingReservation = createAction('SET_BLOCKING_RESERVATION');

/* Admin membership actions*/

const fetchingAdminMembershipsPending = createAction(
    'FETCHING_ADMIN_MEMBERSHIPS_PENDING'
);
const fetchingAdminMembershipsCompleted = createAction(
    'FETCHING_ADMIN_MEMBERSHIPS_COMPLETED'
);
const fetchingAdminMembershipsError = createAction(
    'FETCHING_ADMIN_MEMBERSHIPS_ERROR'
);

const fetchingAdminMembershipsMetadataPending = createAction(
    'FETCHING_ADMIN_MEMBERSHIPS_METADATA_PENDING'
);
const fetchingAdminMembershipsMetadataCompleted = createAction(
    'FETCHING_ADMIN_MEMBERSHIPS_METADATA_COMPLETED'
);
const fetchingAdminMembershipsMetadataError = createAction(
    'FETCHING_ADMIN_MEMBERSHIPS_METADATA_ERROR'
);

const fetchAdminMembershipPending = createAction(
    'FETCH_ADMIN_MEMBERSHIP_PENDING'
);
const fetchAdminMembershipCompleted = createAction(
    'FETCH_ADMIN_MEMBERSHIP_COMPLETED'
);
const fetchAdminMembershipError = createAction('FETCH_ADMIN_MEMBERSHIP_ERROR');

// TODO rename to admin membership
const fetchMembershipSubMembersPending = createAction(
    'FETCH_MEMBERSHIP_SUB_MEMBERS_PENDING'
);
const fetchMembershipSubMembersCompleted = createAction(
    'FETCH_MEMBERSHIP_SUB_MEMBERS_COMPLETED'
);
const fetchMembershipSubMembersError = createAction(
    'FETCH_MEMBERSHIP_SUB_MEMBERS_ERROR'
);
const setMembershipSubMembers = createAction('SET_MEMBERSHIP_SUB_MEMBERS');

const fetchAdminReservationSummaryPending = createAction(
    'FETCH_ADMIN_RESERVATION_SUMMARY_PENDING'
);
const fetchAdminReservationSummaryCompleted = createAction(
    'FETCH_ADMIN_RESERVATION_SUMMARY_COMPLETED'
);
const fetchAdminReservationSummaryError = createAction(
    'FETCH_ADMIN_RESERVATION_SUMMARY_ERROR'
);

const addMembershipMemberPending = createAction(
    'ADD_MEMBERSHIP_MEMBER_PENDING'
);
const addMembershipMemberCompleted = createAction(
    'ADD_MEMBERSHIP_MEMBER_COMPLETED'
);
const addMembershipMemberError = createAction('ADD_MEMBERSHIP_MEMBER_ERROR');

const removeMembershipMemberPending = createAction(
    'REMOVE_MEMBERSHIP_MEMBER_PENDING'
);
const removeMembershipMemberCompleted = createAction(
    'REMOVE_MEMBERSHIP_MEMBER_COMPLETED'
);
const removeMembershipMemberError = createAction(
    'REMOVE_MEMBERSHIP_MEMBER_ERROR'
);

const putMembershipPending = createAction('PUT_MEMBERSHIP_PENDING');
const putMembershipCompleted = createAction('PUT_MEMBERSHIP_COMPLETED');
const putMembershipError = createAction('PUT_MEMBERSHIP_ERROR');
const resetPutMembershipResult = createAction('RESET_PUT_MEMBERSHIP_RESULT');

const deleteMembershipPending = createAction('DELETE_MEMBERSHIP_PENDING');
const deleteMembershipCompleted = createAction('DELETE_MEMBERSHIP_COMPLETED');
const deleteMembershipError = createAction('DELETE_MEMBERSHIP_ERROR');
const resetDeleteMembershipResult = createAction(
    'RESET_DELETE_MEMBERSHIP_RESULT'
);

const refundMembershipDepositPending = createAction(
    'REFUND_MEMBERSHIP_DEPOSIT_PENDING'
);
const refundMembershipDepositCompleted = createAction(
    'REFUND_MEMBERSHIP_DEPOSIT_COMPLETED'
);
const refundMembershipDepositError = createAction(
    'REFUND_MEMBERSHIP_DEPOSIT_ERROR'
);

const fetchingAdminMembershipStatesPending = createAction(
    'FETCHING_ADMIN_MEMBERSHIP_STATES_PENDING'
);
const fetchingAdminMembershipStatesCompleted = createAction(
    'FETCHING_ADMIN_MEMBERSHIP_STATES_COMPLETED'
);
const fetchingAdminMembershipStatesError = createAction(
    'FETCHING_ADMIN_MEMBERSHIP_STATES_ERROR'
);

const fetchingAdminMembershipTypesPending = createAction(
    'FETCHING_ADMIN_MEMBERSHIP_TYPES_PENDING'
);
const fetchingAdminMembershipTypesCompleted = createAction(
    'FETCHING_ADMIN_MEMBERSHIP_TYPES_COMPLETED'
);
const fetchingAdminMembershipTypesError = createAction(
    'FETCHING_ADMIN_MEMBERSHIP_TYPES_ERROR'
);

const fetchAssignmentsDataPending = createAction(
    'FETCHING_ASSIGNMENTS_DATA_PENDING'
);
const fetchAssignmentsDataCompleted = createAction(
    'FETCHING_ASSIGNMENTS_DATA_COMPLETED'
);
const fetchAssignmentsDataError = createAction(
    'FETCHING_ASSIGNMENTS_DATA_ERROR'
);

const fetchAssignmentsMetadataPending = createAction(
    'FETCHING_ASSIGNMENTS_METADATA_PENDING'
);
const fetchAssignmentsMetadataCompleted = createAction(
    'FETCHING_ASSIGNMENTS_METADATA_COMPLETED'
);
const fetchAssignmentsMetadataError = createAction(
    'FETCHING_ASSIGNMENTS_METADATA_ERROR'
);

const actions = {
    sendSmsPending,
    sendSmsCompleted,
    sendSmsError,
    fetchConflictsPending,
    fetchConflictsCompleted,
    fetchConflictsError,
    fetchingAdminReservationsPending,
    fetchingAdminReservationsCompleted,
    fetchingAdminReservationsError,
    fetchingAdminReservationsMetadataPending,
    fetchingAdminReservationsMetadataCompleted,
    fetchingAdminReservationsMetadataError,
    fetchingAdminReservationMessagesPending,
    fetchingAdminReservationMessagesCompleted,
    fetchingAdminReservationMessagesError,
    fetchingAdminReservationMessagesMetadataPending,
    fetchingAdminReservationMessagesMetadataCompleted,
    fetchingAdminReservationMessagesMetadataError,
    fetchingAdminReservationMessagePending,
    fetchingAdminReservationMessageCompleted,
    fetchingAdminReservationMessageError,
    fetchingAdminAdminReservationsPending,
    fetchingAdminAdminReservationsCompleted,
    fetchingAdminAdminReservationsError,
    fetchingAdminAdminReservationsMetadataPending,
    fetchingAdminAdminReservationsMetadataCompleted,
    fetchingAdminAdminReservationsMetadataError,
    fetchingAdminReservationPending,
    fetchingAdminReservationCompleted,
    fetchingAdminReservationError,
    adminReservationResendReservationPending,
    adminReservationResendReservationCompleted,
    adminReservationResendReservationError,
    resetAdminReservationResendReservation,
    invoicingAdminReservationPending,
    invoicingAdminReservationCompleted,
    invoicingAdminReservationError,
    fetchReservationChangesPending,
    fetchReservationChangesCompleted,
    fetchReservationChangesError,
    fetchReservationChangeLogBodyPending,
    fetchReservationChangeLogBodyCompleted,
    fetchReservationChangeLogBodyError,
    fetchingAdminReservationTripsPending,
    fetchingAdminReservationTripsCompleted,
    fetchingAdminReservationTripsError,
    fetchingAdminReservationExtensibilityPending,
    fetchingAdminReservationExtensibilityCompleted,
    fetchingAdminReservationExtensibilityError,
    fetchingAdminReservationConflictsPending,
    fetchingAdminReservationConflictsCompleted,
    fetchingAdminReservationConflictsError,
    fetchingAdminProblemReservationsPending,
    fetchingAdminProblemReservationsCompleted,
    fetchingAdminProblemReservationsError,
    fetchingAdminReservationDriversPending,
    fetchingAdminReservationDriversCompleted,
    fetchingAdminReservationDriversError,
    putReservationPending,
    putReservationCompleted,
    putReservationError,
    resetPutReservationResult,
    fetchMembershipSubMembersPending,
    fetchMembershipSubMembersCompleted,
    fetchMembershipSubMembersError,
    setMembershipSubMembers,
    voidReservationPending,
    voidReservationCompleted,
    voidReservationError,
    resetReservationReviewFlagPending,
    resetReservationReviewFlagCompleted,
    resetReservationReviewFlagError,
    cancelReservationPending,
    cancelReservationCompleted,
    cancelReservationError,
    addReservationDriverPending,
    addReservationDriverCompleted,
    addReservationDriverError,
    removeReservationDriverPending,
    removeReservationDriverCompleted,
    removeReservationDriverError,
    createAdminReservationPending,
    createAdminReservationCompleted,
    createAdminReservationError,
    fetchingAdminMembershipsPending,
    fetchingAdminMembershipsCompleted,
    fetchingAdminMembershipsError,
    fetchingAdminMembershipsMetadataPending,
    fetchingAdminMembershipsMetadataCompleted,
    fetchingAdminMembershipsMetadataError,
    fetchAdminMembershipPending,
    fetchAdminMembershipCompleted,
    fetchAdminMembershipError,
    fetchAdminReservationSummaryPending,
    fetchAdminReservationSummaryCompleted,
    fetchAdminReservationSummaryError,
    putMembershipPending,
    putMembershipCompleted,
    putMembershipError,
    resetPutMembershipResult,
    deleteMembershipPending,
    deleteMembershipCompleted,
    deleteMembershipError,
    resetDeleteMembershipResult,
    refundMembershipDepositPending,
    refundMembershipDepositCompleted,
    refundMembershipDepositError,
    addMembershipMemberPending,
    addMembershipMemberCompleted,
    addMembershipMemberError,
    removeMembershipMemberPending,
    removeMembershipMemberCompleted,
    removeMembershipMemberError,
    fetchingAdminMembershipStatesPending,
    fetchingAdminMembershipStatesCompleted,
    fetchingAdminMembershipStatesError,
    fetchingAdminMembershipTypesPending,
    fetchingAdminMembershipTypesCompleted,
    fetchingAdminMembershipTypesError,
    fetchingAdminBillingDataPending,
    fetchingAdminBillingDataCompleted,
    fetchingAdminBillingDataError,
    setInvoiceSummaryBeforeDate,
    setIncludeNonReservationLines,
    clearAdminInvoiceSummary,
    fetchingAdminInvoiceSummaryPending,
    fetchingAdminInvoiceSummaryCompleted,
    fetchingAdminInvoiceSummaryError,
    fetchingAdminInvoicesPending,
    fetchingAdminInvoicesCompleted,
    fetchingAdminInvoicesError,
    fetchingAdminInvoiceMetadataPending,
    fetchingAdminInvoiceMetadataCompleted,
    fetchingAdminInvoiceMetadataError,
    fetchingAdminInvoiceDetailsPending,
    fetchingAdminInvoiceDetailsCompleted,
    fetchingAdminInvoiceDetailsError,
    createInvoicePending,
    createInvoiceCompleted,
    createInvoiceError,
    resetCreateInvoicesResponse,
    sendInvoicePending,
    sendInvoiceCompleted,
    sendInvoiceError,
    fetchProductsPending,
    fetchProductsCompleted,
    fetchProductsError,
    fetchProductsMetadataPending,
    fetchProductsMetadataCompleted,
    fetchProductsMetadataError,
    fetchProductVatCodesPending,
    fetchProductVatCodesCompleted,
    fetchProductVatCodesError,

    updateProductPending,
    updateProductCompleted,
    updateProductError,

    fetchProductPending,
    fetchProductCompleted,
    fetchProductError,

    fetchProductCategoriesPending,
    fetchProductCategoriesCompleted,
    fetchProductCategoriesError,

    createProductPending,
    createProductCompleted,
    createProductError,

    setBillingData,
    fetchingAdminBillingMetadataPending,
    fetchingAdminBillingMetadataCompleted,
    fetchingAdminBillingMetadataError,
    updateBillingData,
    setBlockingReservation,
    fetchAssignmentsDataPending,
    fetchAssignmentsDataCompleted,
    fetchAssignmentsDataError,
    fetchAssignmentsMetadataPending,
    fetchAssignmentsMetadataCompleted,
    fetchAssignmentsMetadataError,
};

export default actions;
