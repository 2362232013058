import React from 'react';
import { ReactComponent as Gears } from '../../../resources/gears.svg';
import { useSelector } from 'react-redux';
import { selectTheme } from '../../duck/selectors';

type Props = {
    type?:
        | 'DOTS__HORIZONTAL'
        | 'DOTS__HORIZONTAL--grey'
        | 'DOTS__VERTICAL'
        | 'GEARS';
    size?: string;
};

export const Loader = ({ type, size }: Props) => {
    const theme = useSelector(selectTheme);
    if (type === 'GEARS')
        return (
            <div
                className={'loaderGears'}
                style={{ height: size || '100%', width: size || '100%' }}
            >
                <Gears />
            </div>
        );
    if (type === 'DOTS__HORIZONTAL')
        return (
            <div className={'loaderDots'}>
                <div
                    className={'loaderDot--medium'}
                    style={{ borderColor: theme?.color__primary }}
                />
                <div
                    className={'loaderDot--medium'}
                    style={{ borderColor: theme?.color__primary }}
                />
                <div
                    className={'loaderDot--medium'}
                    style={{ borderColor: theme?.color__primary }}
                />
            </div>
        );
    if (type === 'DOTS__HORIZONTAL--grey')
        return (
            <div className={'loaderDots'}>
                <div
                    className={'loaderDot--medium'}
                    style={{ borderColor: theme?.color__ternary }}
                />
                <div
                    className={'loaderDot--medium'}
                    style={{ borderColor: theme?.color__ternary }}
                />
                <div
                    className={'loaderDot--medium'}
                    style={{ borderColor: theme?.color__ternary }}
                />
            </div>
        );
    if (type === 'DOTS__VERTICAL')
        return (
            <div className={'loaderDots loaderDots--vertical'}>
                <div
                    className={'loaderDot--medium'}
                    style={{ borderColor: theme?.color__primary }}
                />
                <div
                    className={'loaderDot--medium'}
                    style={{ borderColor: theme?.color__primary }}
                />
                <div
                    className={'loaderDot--medium'}
                    style={{ borderColor: theme?.color__primary }}
                />
            </div>
        );
    let style = {
        border: `2px solid EBEBEB`,
        borderTop: `2px solid black`,
    };
    return (
        <div style={{ height: size || '100%', width: size || '100%' }}>
            <div className={'loaderSpinning'} style={style} />
        </div>
    );
};
