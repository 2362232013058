import React, { useState } from 'react';
import { ReactComponent as DeleteIcon } from '../icon/delete.svg';
import { AdminModalConfirm } from '../AdminModals/AdminModalConfirm';
import { Theme } from '../../../utilities/types';

type Props = {
    theme: Theme;
    confirmationText?: string;
    delete: () => void;
};

export const DeleteWithConfirmationButton = (props: Props) => {
    const [showModal, setShowModal] = useState(false);

    const close = () => setShowModal(false);
    return (
        <div className="deleteWithConfirmationButton">
            <button
                className="adminDamage__deleteButton"
                onClick={() => setShowModal(true)}
            >
                <DeleteIcon />
            </button>
            {showModal && (
                <AdminModalConfirm
                    onClose={close}
                    heading={props.confirmationText || 'Bekreft sletting'}
                    theme={props.theme}
                    issueCall={() => {
                        props.delete();
                        close();
                    }}
                    showCancelButton={true}
                    styleOverride={{
                        minHeight: 0,
                    }}
                />
            )}
        </div>
    );
};
