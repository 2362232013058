import React from 'react';
import { PAGE_ADMIN } from '../../duck/constants';
import NavButton from '../Buttons/NavButton';
import { Permission, Write } from '../Security/permissions';
import { AuthorizedNavButton } from '../Security/authorizedComponents';
import { Theme } from '../../../utilities/types';

type Props = {
    showHamburgerMenuFunction: (val: boolean) => void;
    setPage: (name: string) => void;
    theme: Theme;
};

const AdminNewNavItems = (props: Props) => {
    const createNavButton = (
        label: string,
        link: string,
        permission?: Permission
    ) => {
        const Comp = permission ? AuthorizedNavButton : NavButton;
        return (
            <Comp
                type={'whiteItem'}
                theme={props.theme}
                onClick={() => {
                    props.showHamburgerMenuFunction(false);
                    props.setPage(PAGE_ADMIN);
                }}
                styleOverride={{ backgroundColor: '#5E5E6F' }}
                exact={true}
                newTab={true}
                key={label}
                link={link}
                label={label}
                requireAny={permission!}
            />
        );
    };

    return (
        <div className="adminNewSubItemsWrapper">
            {createNavButton(
                'Reservasjon',
                '/admin/reservation-management/reservations/new',
                Write.RESERVATION
            )}
            {createNavButton('Bruker', '/admin/user-management/persons/new')}
            {createNavButton(
                'Bil',
                '/admin/fleet-management/cars/new',
                Write.FLEET_MANAGEMENT
            )}
            {createNavButton(
                'Bilmodell',
                '/admin/fleet-management/models/new',
                Write.FLEET_MANAGEMENT
            )}
            {createNavButton(
                'Lokasjon',
                '/admin/fleet-management/locations/new',
                Write.FLEET_MANAGEMENT
            )}
            {createNavButton(
                'Popupmelding',
                '/admin/fleet-management/entity-messages/new',
                Write.FLEET_MANAGEMENT
            )}
            {createNavButton('Skade', '/admin/damages/new', Write.MESSAGE)}
            {createNavButton(
                'Nøkkelkort',
                '/admin/user-management/member-cards/new'
            )}
            {createNavButton(
                'Produkt',
                '/admin/billing-management/products/new',
                Write.BILLING
            )}
        </div>
    );
};

export default AdminNewNavItems;
