import { handleActions } from 'redux-actions';
import { createAction } from 'redux-actions';
import { getApiCall } from '../../../utilities/api';
import { selectAuthentication } from '../../duck/selectors';
import { apiCallError, apiCallSuccess } from '../../../utilities/constants';
import { handleApiCallAndParseData } from '../../../utilities/utils';
import { createApiCallObject } from '../../../utilities/apiUtils';

export const fetchLocation = (id) => (dispatch, getState) => {
    dispatch(fetchLocationPending());
    return handleApiCallAndParseData(
        getApiCall(
            selectAuthentication(getState()),
            'locations',
            createApiCallObject(dispatch, 'fetchLocation'),
            id
        ),
        dispatch,
        (parsedData) => dispatch(fetchLocationCompleted(parsedData)),
        (error) => {
            dispatch(fetchLocationError());
            console.log(error);
        }
    );
};

export function fetchLocations() {
    return (dispatch, getState) => {
        if (!navigator.onLine) return;
        dispatch(fetchLocationsPending());
        return handleApiCallAndParseData(
            getApiCall(
                selectAuthentication(getState()),
                'locations',
                createApiCallObject(dispatch, 'fetchLocations')
            ),
            dispatch,
            (parsedData) => dispatch(fetchLocationsCompleted(parsedData)),
            (error) => {
                dispatch(fetchLocationsError());
                console.log(error);
            }
        );
    };
}

export const selectFetchingLocation = (state) =>
    state.locationsReducer.fetchingLocation;
export const selectFetchLocationResult = (state) =>
    state.locationsReducer.fetchLocationResult;
export const selectLocation = (state) => state.locationsReducer.location;

export const selectFetchingLocations = (state) =>
    state.locationsReducer.fetchingLocations;
export const selectFetchLocationsResult = (state) =>
    state.locationsReducer.fetchLocationsResult;
export const selectLocations = (state) => state.locationsReducer.locations;

const fetchLocationPending = createAction('FETCH_LOCATION_PENDING');
const fetchLocationError = createAction('FETCH_LOCATION_ERROR');
const fetchLocationCompleted = createAction('FETCH_LOCATION_COMPLETED');

const fetchLocationsPending = createAction('FETCH_LOCATIONS_PENDING');
const fetchLocationsError = createAction('FETCH_LOCATIONS_ERROR');
const fetchLocationsCompleted = createAction('FETCH_LOCATIONS_COMPLETED');

const locationsReducer = handleActions(
    {
        [fetchLocationPending]: (state) => ({
            ...state,
            fetchingLocation: true,
            fetchLocationResult: undefined,
        }),
        [fetchLocationError]: (state) => ({
            ...state,
            fetchingLocation: false,
            fetchLocationResult: apiCallError,
        }),
        [fetchLocationCompleted]: (state, action) => ({
            ...state,
            fetchingLocation: false,
            fetchLocationResult: apiCallSuccess,
            location: action.payload,
        }),
        [fetchLocationsPending]: (state) => ({
            ...state,
            fetchingLocations: true,
            fetchLocationsResult: undefined,
        }),
        [fetchLocationsError]: (state) => ({
            ...state,
            fetchingLocations: false,
            fetchLocationsResult: apiCallError,
        }),
        [fetchLocationsCompleted]: (state, action) => ({
            ...state,
            fetchingLocations: false,
            fetchLocationsResult: apiCallSuccess,
            locations: action.payload,
        }),
    },
    {
        fetchingLocation: false,
        fetchLocationResult: undefined,
        location: undefined,
        fetchingLocations: false,
        fetchLocationsResult: undefined,
        locations: [],
    }
);

export default locationsReducer;
