import { handleActions } from 'redux-actions';
import actions from './actions';
import {
    apiCallError,
    apiCallSuccess
} from '../../../../../../utilities/constants';

const defaultLocationsState = {};

const locationsReducer = handleActions(
    {
        [actions.fetchAdminLocationsPending]: state => ({
            ...state,
            fetchingAdminLocations: true,
            fetchAdminLocationsResult: undefined
        }),
        [actions.fetchAdminLocationsCompleted]: (state, action) => ({
            ...state,
            fetchingAdminLocations: false,
            fetchAdminLocationsResult: apiCallSuccess,
            adminLocations: action.payload
        }),
        [actions.fetchAdminLocationsError]: state => ({
            ...state,
            fetchingAdminLocations: false,
            fetchAdminLocationsResult: apiCallError
        }),
        [actions.fetchAdminLocationsMetadataPending]: state => ({
            ...state,
            fetchingAdminLocationsMetadata: true,
            fetchAdminLocationsMetadataResult: undefined
        }),
        [actions.fetchAdminLocationsMetadataCompleted]: (state, action) => ({
            ...state,
            fetchingAdminLocationsMetadata: false,
            fetchAdminLocationsMetadataResult: apiCallSuccess,
            adminLocationsMetadata: action.payload
        }),
        [actions.fetchAdminLocationsMetadataError]: state => ({
            ...state,
            fetchingAdminLocationsMetadata: false,
            fetchAdminLocationsMetadataResult: apiCallError
        }),

        [actions.fetchAdminLocationPending]: state => ({
            ...state,
            fetchingAdminLocation: true,
            fetchAdminLocationResult: undefined
        }),
        [actions.fetchAdminLocationCompleted]: (state, action) => ({
            ...state,
            fetchingAdminLocation: false,
            fetchAdminLocationResult: apiCallSuccess,
            adminLocation: action.payload
        }),
        [actions.fetchAdminLocationError]: state => ({
            ...state,
            fetchingAdminLocation: false,
            fetchAdminLocationResult: apiCallError
        }),

        [actions.putAdminLocationPending]: state => ({
            ...state,
            puttingAdminLocation: true,
            putAdminLocationResult: undefined
        }),
        [actions.putAdminLocationCompleted]: (state, action) => ({
            ...state,
            puttingAdminLocation: false,
            putAdminLocationResult: apiCallSuccess,
            adminLocation: action.payload
        }),
        [actions.putAdminLocationError]: state => ({
            ...state,
            puttingAdminLocation: false,
            putAdminLocationResult: apiCallError
        }),
        [actions.resetPutAdminLocationResults]: state => ({
            ...state,
            puttingAdminLocation: false,
            putAdminLocationResult: undefined
        }),

        [actions.createAdminLocationPending]: state => ({
            ...state,
            postingAdminLocation: true,
            postAdminLocationResult: undefined
        }),
        [actions.createAdminLocationCompleted]: (state, action) => ({
            ...state,
            postingAdminLocation: false,
            postAdminLocationResult: apiCallSuccess,
            adminLocation: action.payload
        }),
        [actions.createAdminLocationError]: state => ({
            ...state,
            postingAdminLocation: false,
            postAdminLocationResult: apiCallError
        }),
        [actions.resetCreateAdminLocationResults]: state => ({
            ...state,
            postingAdminLocation: false,
            postAdminLocationResult: undefined
        }),
        CLEAR_STATE: () => defaultLocationsState
    },
    defaultLocationsState
);

export default locationsReducer;
