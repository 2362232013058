//TODO FORBEDRET THEMES
// Istedenfor at vi plukker ut variabler fra de ulike themene og pakker dem samme i nye style-objekter over alt bør vi
// heller lage disse styleobjektene her og deretter plukke dem ut i de komponentene vi trenger
// Lager da egne konstanter, lokale til denne filen, som inneholder de ulike fargene, og bruker disse i de ulike style-komponentene

//TODO forebdrgin av fontSize og sizes generelt til å bruke rem, se:
// https://engageinteractive.co.uk/blog/em-vs-rem-vs-px

//TODO legg til lineheight rundt om kring, kanskje lage noen styles i theme som heter text__paragraph som har lineheight font-size etc

//themes

//TODO sjekk om noen av textStyles bruker noen av disse fargene
// colorPalette bør flyttes over textStyles
import { Theme } from './types';

const colorPalette = {
    background: '#FFFFFF',
    background__light: '#FAFAFA',
    background__dark: '#E8E8E8',
    background__button: 'rgba(196, 196, 196, 0.2)',
    typographyBlack__MediumEmphasis: 'rgba(0, 0, 0, 0.6)',
    typographyWhite__HighEmphasis: '#FFFFFF',
    typographyWhite__MediumEmphasis: 'rgba(255, 255, 255, 0.7)',

    /*
    TODO
    change button color to the new green, selv om det ikke er det i skissene, det ser bedre ut
     */
    /*
    Nye oppdaterte stiler
     */
    BDR_PROFILE_1: '#51289D',
    BDR_PURPLE_BLUE_PROFILE_2: '#3A3A4F',
    BDR_PURPLE_BLUE_PROFILE_2__HOVER: '#2f2f43',

    input_field_frame: 'rgba(34, 134, 80, 0.2)',

    input_field_frame__grey: '#9D9CA7',

    associated_info_text: '#696969',

    text__grey: '#3D4D5B',

    button_primary: '#77A202',
    button_primary__dark: '#688E01',
    button_primary_orange: '#B4911A',
    button_primary_orange_dark: '#A18117',

    partially_available: '#E2C500',
    partially_available_text: '#907002',

    lightGreyBackground: 'rgba(178, 178, 178, 0.15)',
    disabled: '#B2B2B2',
    disabled__wcag: '#737272',

    button_2_background__wcag: '#FBFBFB',

    background_button: 'rgba(94, 94, 111, 0.1)',
    links_and_clickables: '#016CC5',
    links_and_clickables__hover: '#01569E',

    background_past_reservation: '#F3F3F3',
    background_past_reservation_selected: '#e9e9e9',
    background_past_reservation_hover: '#e8e6e6',

    background_active_reservation: 'rgba(238, 255, 217, 0.8)',
    background_active_reservation_selected: 'rgba(221,255,197,0.8)',
    background_active_reservation_hover: 'rgba(211,255,184,0.8)',

    background_overdue_reservation: 'rgba(226, 197, 0, .18)',

    text_available_green: '#5D7F01',
    background_available_green: '#8BBE00',

    vipps_orange: '#FF5B24',

    alert_error: '#B41717',
    input_error: '#F65858',
    status: '#DC7B25',

    /*
    gamle farger
     */

    alert: '#F2994A',

    redButton: '#ff7f82',
    redButton__hover: '#c76767',

    lightGreyBorder: '#D8D8D8',
    associatedText_grey: '#656464',
    hover_on_white_background_color: '#f5f5f5',
    hover_on_grey_background_color: '#d0e6ff',

    primary__lighter: '#d5f2ab',
    primary__light: '#bfdf76',
    primary: '#8BBE00',
    primary__dark: '#77A300',
    // secondary: '#567D37',
    //
    // button__grey: '#DADADA',
    // button__grey__dark: '#c1c1c1',

    black: '#333333',
    black__quarterOpacity: 'rgba(0, 0, 0, 0.25)',
    grey: '#B2B2B2',
    grey__dark: '#767676',
    grey__darker: '#3D4D5B',
    grey__mediumLight: '#CCCCCC',
    grey__mediumLight__opaque: 'rgb(204,204,204, 0.50)',
    grey__light: '#EEEEEE',
    grey__lighter: '#F2F2F2',
    grey__opaque: 'rgba(118, 118, 118, 0.4)',
    black__semiTransparent: 'rgba(0, 0, 0, 0.2)',
    grey__semiTransparent: 'rgba(94, 94, 111, 0.2)',
    grey__transparent: 'rgba(94, 94, 111, 0.1)',
    dullWhite: '#FBFBFB',
    halfOpaqueWhite: 'rgba(255, 255, 255, .5)',
    red__darker: '#911616',
    red__dark: '#a71616',
    red: '#B41717',
    orange__darker: '#cb6b00',
    orange__dark: '#db7300',
    orange: '#D2AA1D',
    orange__light: '#E2C500',
    green_yellow: '#CFFE51',
    yellow__light: '#fff199',
    green: '#dcebb3',
    lightGreen: '#F0FFC8',
    blue: '#0375D4',
    blue__light: '#2098FB',
    blue__dark: '#0872CA',
    blue__darker: '#0764b1',
    gray__blue__purple__highOpacity: '#3a3a4fee',
    gray__blue__purple__opaque: '#3a3a4f',
    sand: '#F9F6E2',
    lightPink: '#f9d6da',
    olive: '#AABE9B',
    olive__lighter: 'rgba(139, 190, 0, 0.5)',
    availability__green: '#93c531',
    availability__yellow: '#e8b900',
    availability__grey: '#cccccc',
};

const typography = {
    h1: {
        fontFamily: 'Lato',
        fontWeight: 600,
        fontSize: '2rem',
        lineHeight: '2.3125rem',
        letterSpacing: '-1px',
        color: colorPalette.primary,
    },
    h2: {
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '2rem',
        lineHeight: '2.3125rem',
        letterSpacing: '-0.5px',
        color: colorPalette.primary,
    },
    // h4 in styleguide
    h3: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
        letterSpacing: '0.25px',
        color: colorPalette.primary,
    },
    // h5 in styleguide
    h4: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
        color: colorPalette.black,
    },
    // h6 in styleguide
    h5: {
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.5625rem',
        letterSpacing: '0.15px',
        color: colorPalette.black,
    },
    // h7 in styleguide
    h6: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '0.9375rem',
        lineHeight: '1.3125',
        letterSpacing: '0.15px',
        color: colorPalette.text__grey,
    },

    body1: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: '1.375rem',
        letterSpacing: '0.5px',
        color: colorPalette.grey__darker,
    },
    body2: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.9375rem',
        lineHeight: '1.3125rem',
        letterSpacing: '0.25px',
        color: colorPalette.grey__darker,
    },
    link: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.9375rem',
        lineHeight: '1.3125rem',
        letterSpacing: '0.25px',
        // color: colorPalette.links_and_clickables
    },
    buttonWithIconText: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.8125rem',
        lineHeight: '0.9375rem',
        letterSpacing: '0.25px',
        // color: colorPalette.blue
    },
    roundButtonWithIconText: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.833rem',
        lineHeight: '0.9375rem',
        letterSpacing: '0.25px',
        // color: colorPalette.blue,
        textTransform: 'uppercase',
    },
    editOptionButtonWithIconText: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.667em',
        lineHeight: '0.9375rem',
        letterSpacing: '0.25px',
        color: colorPalette.background,
        textTransform: 'uppercase',
    },
    dateAndTimeText: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '1rem',
        lineHeight: '1.125rem',
        letterSpacing: '0.25px',
        color: colorPalette.black,
    },
    reservationDateText: {
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '0.85rem',
        letterSpacing: '0.5px',
        color: colorPalette.black,
    },

    /*
    Checked new styles
     */

    icon_menu: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '100%',
        letterSpacing: '1px',
        color: colorPalette.typographyWhite__HighEmphasis,
    },

    label: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '100%',
        letterSpacing: '0.4px',
        color: colorPalette.associated_info_text,
    },

    // TODO bruker denne istedenfor label på inputfelter, hør med Kari
    inputField__label: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.875rem',
        lineHeight: '100%',
        letterSpacing: '0.4px',
        color: colorPalette.associated_info_text,
    },

    inputField__input: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '1.125rem',
        lineHeight: '100%',
        letterSpacing: '0.4px',
        color: colorPalette.black,
    },
    inputField__helpText: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.75rem',
        lineHeight: '100%',
        letterSpacing: '0.4px',
        color: colorPalette.associated_info_text,
    },
    dateInInputField: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.9375rem',
        letterSpacing: '0px',
        lineHeight: '100%',
        color: colorPalette.text__grey,
    },
    associatedText: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '0.75rem',
        letterSpacing: '0.4px',
        lineHeight: '16px',
        color: colorPalette.associated_info_text,
    },
    menuText: {
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '1rem',
        letterSpacing: '0.5px',
        color: colorPalette.typographyWhite__HighEmphasis,
    },
    buttonsPri1: {
        fontFamily: 'Lato',
        fontWeight: 500,
        fontSize: '1.2rem',
        lineHeight: '1.3125rem',
        letterSpacing: '0.5px',
        color: colorPalette.typographyWhite__HighEmphasis,
    },
};

/*
font-weights
400 regular
500 medium
600 semi-bold
700 bold
*/

export const theme_std: Partial<Theme> = {
    color__primary: 'black',
    color__secondary: '#fff',
    color__ternary: '#d9d9d9',
    backgroundColor__primary: '#577c37',
    backgroundColor__secondary: '#fff',
    backgroundColor__ternary: '#efefef',
    navItemBackground: 'powderBlue',
    navItemBackground__selected: 'blue',
    borderColor: '#EBEBEB',
    button__hoverColor: '#577c3738',
    textColor__primary: 'black',
    textColor__secondary: '#90A0AE',
    textColor__ternary: '#8ABE01',
    textColor__quinary: 'white',
    fontSize__paragraph: '0.85em',
};

export const theme_bdr: Partial<Theme> = {
    color__primary: '#577c37',
    color__secondary: 'black',
    color__ternary: '#d9d9d9',
    linkColor: colorPalette.blue__light,
    notificationColor: colorPalette.green_yellow,
    modalBackgroundColor: colorPalette.grey__opaque,
    menuColor: colorPalette.gray__blue__purple__opaque,
    menuBorderColor: colorPalette.halfOpaqueWhite,
    backgroundColor__primary: '#577c37',
    backgroundColor__secondary: '#fff',
    backgroundColor__ternary: '#efefef',
    navItemBackground__selected: '#577c376e',
    borderColor: '#EBEBEB',
    border__lightGreen: colorPalette.olive,
    button__hoverColor: '#577c3738',
    textColor__primary: 'black',
    textColor__secondary: '#90A0AE',
    textColor__ternary: '#8ABE01',
    textColor__quinary: 'white',
    textColor__darkGrey: colorPalette.grey__dark,
    textColor__darkRed: colorPalette.red,
    fontSize__h1: '1.25rem',
    fontSize__paragraph: '0.8125rem',
    fontSize__paragraph_small: '0.625rem',

    text: typography,
    colors: colorPalette,

    isMobile: true,
};
