import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

export type ErrorState = {
    unauthorizedUrl?: string;
    unauthorizedActionAttempted: boolean;
};

const initialState: ErrorState = {
    unauthorizedActionAttempted: false,
};

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        reportUnauthorizedAttempt(
            state: Draft<ErrorState>,
            action: PayloadAction<string | undefined>
        ) {
            state.unauthorizedUrl = action.payload;
            state.unauthorizedActionAttempted = true;
        },
        clearErrors(state: Draft<ErrorState>) {
            state = initialState;
        },
    },
});

export const { reportUnauthorizedAttempt, clearErrors } = errorSlice.actions;

const errorReducer = errorSlice.reducer;
export default errorReducer;
