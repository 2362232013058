import { createAction } from 'redux-actions';

const createAdminDamagePending = createAction(
    'CREATE_ADMIN_RESERVATION_MESSAGE_PENDING'
);
const createAdminDamageCompleted = createAction(
    'CREATE_ADMIN_RESERVATION_MESSAGE_COMPLETED'
);
const createAdminDamageError = createAction(
    'CREATE_ADMIN_RESERVATION_MESSAGE_ERROR'
);

const resetCreateAdminDamageResult = createAction(
    'RESET_CREATE_ADMIN_RESERVATION_MESSAGE_RESULT'
);

const deleteAdminDamagePending = createAction(
    'DELETE_ADMIN_RESERVATION_MESSAGE_PENDING'
);
const deleteAdminDamageCompleted = createAction(
    'DELETE_ADMIN_RESERVATION_MESSAGE_COMPLETED'
);
const deleteAdminDamageError = createAction(
    'DELETE_ADMIN_RESERVATION_MESSAGE_ERROR'
);

const fetchingDamagesPending = createAction('FETCHING_DAMAGES_PENDING');
const fetchingDamagesCompleted = createAction('FETCHING_DAMAGES_COMPLETED');
const fetchingDamagesError = createAction('FETCHING_DAMAGES_ERROR');

const fetchingDamagesMetadataPending = createAction(
    'FETCHING_DAMAGES_METADATA_PENDING'
);
const fetchingDamagesMetadataCompleted = createAction(
    'FETCHING_DAMAGES_METADATA_COMPLETED'
);
const fetchingDamagesMetadataError = createAction(
    'FETCHING_DAMAGES_METADATA_ERROR'
);

const fetchingDamagePending = createAction('FETCHING_DAMAGE_PENDING');
const fetchingDamageCompleted = createAction('FETCHING_DAMAGE_COMPLETED');
const fetchingDamageError = createAction('FETCHING_DAMAGE_ERROR');

const updateDamagePending = createAction('UPDATE_DAMAGE_PENDING');
const updateDamageCompleted = createAction('UPDATE_DAMAGE_COMPLETED');
const updateDamageError = createAction('UPDATE_DAMAGE_ERROR');

const postDamagePicturePending = createAction('POST_DAMAGE_PICTURE_PENDING');
const postDamagePictureCompleted = createAction(
    'POST_DAMAGE_PICTURE_COMPLETED'
);
const postDamagePictureError = createAction('POST_DAMAGE_PICTURE_ERROR');

const updateDamage = createAction('UPDATE_DAMAGE');

const fetchingMembershipDamagesPending = createAction(
    'FETCHING_MEMBERSHIP_DAMAGES_PENDING'
);
const fetchingMembershipDamagesCompleted = createAction(
    'FETCHING_MEMBERSHIP_DAMAGES_COMPLETED'
);
const fetchingMembershipDamagesError = createAction(
    'FETCHING_MEMBERSHIP_DAMAGES_ERROR'
);

const actions = {
    createAdminDamagePending,
    createAdminDamageCompleted,
    createAdminDamageError,
    resetCreateAdminDamageResult,
    deleteAdminDamagePending,
    deleteAdminDamageCompleted,
    deleteAdminDamageError,
    updateDamage,
    fetchingDamagesPending,
    fetchingDamagesCompleted,
    fetchingDamagesError,
    fetchingDamagesMetadataPending,
    fetchingDamagesMetadataCompleted,
    fetchingDamagesMetadataError,
    fetchingDamagePending,
    fetchingDamageCompleted,
    fetchingDamageError,
    updateDamagePending,
    updateDamageCompleted,
    updateDamageError,
    postDamagePicturePending,
    postDamagePictureCompleted,
    postDamagePictureError,
    fetchingMembershipDamagesPending,
    fetchingMembershipDamagesCompleted,
    fetchingMembershipDamagesError,
};

export default actions;
