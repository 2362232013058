import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiCallError, apiCallSuccess } from '../../../../utilities/constants';
import { Situation } from '../../../../utilities/types';

export interface SituationState {
    fetchingTags?: boolean;
    fetchTagsResult?: string;
    tagsList: string[];
    fetchingSituations?: boolean;
    fetchSituationsResult?: string;
    situationsList: Situation[];
}

const initialState: SituationState = {
    tagsList: [],
    situationsList: [],
};

const situationsSlice = createSlice({
    name: 'situations',
    initialState,
    reducers: {
        fetchTagsPending(state: SituationState) {
            state.fetchingTags = true;
            state.fetchTagsResult = undefined;
        },
        fetchTagsCompleted(
            state: SituationState,
            action: PayloadAction<string[]>
        ) {
            state.fetchingTags = false;
            state.fetchTagsResult = apiCallSuccess;
            state.tagsList = action.payload;
        },
        fetchTagsError(state: SituationState) {
            state.fetchingTags = false;
            state.fetchTagsResult = apiCallError;
        },
        fetchSituationsPending(state: SituationState) {
            state.fetchingSituations = true;
            state.fetchSituationsResult = undefined;
        },
        fetchSituationsCompleted(
            state: SituationState,
            action: PayloadAction<Situation[]>
        ) {
            state.fetchingSituations = false;
            state.fetchSituationsResult = apiCallSuccess;
            state.situationsList = action.payload;
        },
        fetchSituationsError(state: SituationState) {
            state.fetchingSituations = false;
            state.fetchSituationsResult = apiCallError;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase('CLEAR_STATE', () => initialState);
    },
});

export const {
    fetchTagsPending,
    fetchTagsCompleted,
    fetchTagsError,
    fetchSituationsPending,
    fetchSituationsCompleted,
    fetchSituationsError,
} = situationsSlice.actions;

export default situationsSlice.reducer;
