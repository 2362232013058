import { MembershipPreview } from '../../../../../../../types/admin';
import { createSlice } from '@reduxjs/toolkit';
import { ApiCallResult } from '../../../../../../../types/application';
import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../../../../utilities/constants';

export type MembershipPreviewState = {
    preview?: MembershipPreview;
    fetchingPreview?: boolean;
    fetchPreviewResult?: ApiCallResult;
};

const membershipPreviewStore: MembershipPreviewState = {};

const membershipPreviewSlice = createSlice({
    name: 'membershipPreview',
    initialState: membershipPreviewStore,
    reducers: {
        fetchMembershipPreviewPending(state: MembershipPreviewState) {
            state.fetchingPreview = true;
            state.fetchPreviewResult = undefined;
        },
        fetchMembershipPreviewCompleted(
            state: MembershipPreviewState,
            action: { payload: MembershipPreview }
        ) {
            state.fetchingPreview = false;
            state.preview = action.payload;
            state.fetchPreviewResult = apiCallSuccess;
        },
        fetchMembershipPreviewError(state: MembershipPreviewState) {
            state.fetchingPreview = false;
            state.fetchPreviewResult = apiCallError;
        },
    },
});

export const {
    fetchMembershipPreviewPending,
    fetchMembershipPreviewCompleted,
    fetchMembershipPreviewError,
} = membershipPreviewSlice.actions;

export const membershipPreviewReducer = membershipPreviewSlice.reducer;
export default membershipPreviewReducer;
