import { ReactComponent as Chevron } from '../../common/icon/chevron.svg';

const AnimatedChevron = ({ open, color }) => {
    const colors = ['white', 'blue', 'charcoal'];
    let colorClass;
    if (colors.includes(color)) colorClass = `animatedChevron--${color}`;
    return (
        <div
            className={`animatedChevron ${
                open ? 'animatedChevron--open' : ''
            } ${colorClass}`}
        >
            <Chevron />
        </div>
    );
};

export default AnimatedChevron;
