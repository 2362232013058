import React from 'react';
import { connect } from 'react-redux';
import { TypographyProps } from './typography';
import { selectTheme } from '../../duck/selectors';
import { State } from '../../../state';

type Props = {
    small?: boolean;
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
> &
    TypographyProps;

const P = ({
    children,
    className,
    small,
    style,
    theme,
    ...rest
}: Props): JSX.Element => {
    const textStyles = small ? theme?.text?.body2 : theme?.text?.body1;

    return (
        <p className={className} style={{ ...textStyles, ...style }} {...rest}>
            {children}
        </p>
    );
};

const mapStateToProps = (state: State) => ({
    theme: selectTheme(state),
});

export default connect(mapStateToProps, () => ({}))(P);
