import React, { Fragment, ReactNode } from 'react';
import useEffectOnce from '../../utilities/UseEffectOnce';

type Props = {
    children?: ReactNode;
    onEnterPress?: () => void;
    closeModal: () => void;
    dontFocus?: boolean;
    modalBackground?: string;
    optionalModalClass?: string;
    overflowVisible?: boolean;
    styleOverride?: React.CSSProperties;
};

let modal: HTMLDivElement | null = null;
const Modal = (props: Props) => {
    useEffectOnce(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside, {
            passive: false,
        });
        if (!props.dontFocus && modal) modal.focus();

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    });

    const handleClickOutside = (event: any) => {
        if (modal && !modal.contains(event.target)) {
            event.preventDefault();
            props.closeModal();
        }
    };

    // Kan (må) sette hele bakgrunnsfargen heller enn bare opacity.
    // Skal bakgrunnen standardiseres slik at man bare velger bakgrunn eller ikke bakgrunn?
    const modalBackground = props.modalBackground ?? '#00000000';

    let className = `o-modal ${props.optionalModalClass ?? ''}${
        props.overflowVisible ? '' : ' o-modal--overflowAuto'
    }`;

    return (
        <Fragment>
            <div
                className="o-modalWrapper"
                style={{
                    backgroundColor: modalBackground,
                }}
            >
                <div
                    ref={(node) => (modal = node)}
                    style={props.styleOverride ?? {}}
                    className={className}
                    tabIndex={-1}
                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                        if (event.key === 'Enter') {
                            if (props.onEnterPress) props.onEnterPress();
                        } else if (
                            event.key === 'Esc' ||
                            event.key === 'Escape'
                        ) {
                            props.closeModal();
                        }
                    }}
                >
                    {props.children}
                </div>
            </div>
        </Fragment>
    );
};

export default Modal;
