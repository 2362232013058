import { handleActions } from 'redux-actions';
import actions from './actions';
import { apiCallError, apiCallSuccess } from '../../../../utilities/constants';
import { combineReducers } from 'redux';

const defaultDataState = {
    membership: false,
};

const dataReducer = handleActions(
    {
        [actions.createUserPending]: (state) => ({
            ...state,
            creatingUser: true,
            createUserResult: undefined,
        }),
        [actions.createUserCompleted]: (state) => ({
            ...state,
            creatingUser: false,
            createUserResult: apiCallSuccess,
        }),
        [actions.createUserError]: (state, action) => ({
            ...state,
            creatingUser: false,
            createUserResult: apiCallError,
            createUserErrors: action.payload,
        }),
        [actions.updateCreateUserErrors]: (state, action) => ({
            ...state,
            createUserErrors: action.payload,
        }),

        [actions.fetchUserInfoPending]: (state) => ({
            ...state,
            fetchingUserInfo: true,
            fetchUserInfoResult: undefined,
        }),
        [actions.fetchUserInfoError]: (state) => ({
            ...state,
            fetchingUserInfo: false,
            fetchUserInfoResult: apiCallError,
        }),
        [actions.fetchUserInfoCompleted]: (state, action) => ({
            ...state,
            userInfo: action.payload,
            fetchingUserInfo: false,
            fetchUserInfoResult: apiCallSuccess,
        }),

        [actions.updateUserInfoPending]: (state) => ({
            ...state,
            updatingUserInfo: true,
            updateUserInfoResult: undefined,
            updateUserInfoErrors: undefined,
        }),
        [actions.updateUserInfoCompleted]: (state, action) => ({
            ...state,
            updatingUserInfo: false,
            updateUserInfoResult: apiCallSuccess,
            userInfo: action.payload,
        }),
        [actions.updateUserInfoError]: (state, action) => ({
            ...state,
            updatingUserInfo: false,
            updateUserInfoResult: apiCallError,
            updateUserInfoErrors: action.payload,
        }),
        [actions.resetUpdateUserInfoAndPasswordApiResults]: (state) => ({
            ...state,
            updateUserInfoResult: undefined,
            updateUserInfoErrors: undefined,
            changePasswordResult: undefined,
        }),
        [actions.resetChangePasswordResult]: (state) => ({
            ...state,
            changePasswordResult: undefined,
        }),
        [actions.updateUpdateUserErrors]: (state, action) => ({
            ...state,
            ...action.payload,
        }),

        [actions.unlinkVippsUserPending]: (state) => ({
            ...state,
            unlinkingVippsUser: true,
            unlinkVippsUserResult: undefined,
            unlinkVippsUserErrors: undefined,
        }),
        [actions.unlinkVippsUserCompleted]: (state) => ({
            ...state,
            unlinkingVippsUser: false,
            unlinkVippsUserResult: apiCallSuccess,
            userInfo: {
                ...state.userInfo,
                oidcId: undefined,
            },
        }),
        [actions.unlinkVippsUserError]: (state, action) => ({
            ...state,
            unlinkingVippsUser: false,
            unlinkVippsUserResult: apiCallError,
            unlinkVippsUserErrors: action.payload,
        }),
        [actions.unlinkVippsUserResetError]: (state) => ({
            ...state,
            unlinkingVippsUser: undefined,
            unlinkVippsUserResult: undefined,
            unlinkVippsUserErrors: undefined,
        }),

        [actions.fetchMembershipsPending]: (state) => ({
            ...state,
            fetchingMemberships: true,
            fetchMembershipsResult: undefined,
        }),
        [actions.fetchMembershipsError]: (state) => ({
            ...state,
            fetchingMemberships: false,
            fetchMembershipsResult: apiCallError,
        }),
        [actions.fetchMembershipsCompleted]: (state, action) => ({
            ...state,
            memberships: action.payload,
            fetchingMemberships: false,
            fetchMembershipsResult: apiCallSuccess,
        }),

        [actions.fetchMembershipPending]: (state) => ({
            ...state,
            fetchingMembership: true,
            fetchingMembershipMembers: true,
            fetchMembershipResult: undefined,
            fetchMembershipMembersResult: undefined,
        }),
        [actions.fetchMembershipError]: (state) => ({
            ...state,
            fetchingMembership: false,
            fetchMembershipResult: apiCallError,
        }),
        [actions.fetchMembershipCompleted]: (state, action) => ({
            ...state,
            membership: {
                ...action.payload,
                members: state.membership.members,
            },
            fetchingMembership: false,
            fetchMembershipResult: apiCallSuccess,
        }),

        [actions.fetchMembershipTypesPending]: (state) => ({
            ...state,
            fetchingMembershipTypes: true,
            fetchMembershipTypesResult: undefined,
        }),
        [actions.fetchMembershipTypesError]: (state) => ({
            ...state,
            fetchingMembershipTypes: false,
            fetchMembershipTypesResult: apiCallError,
        }),
        [actions.fetchMembershipTypesCompleted]: (state, action) => ({
            ...state,
            membershipTypes: action.payload,
            fetchingMembershipTypes: false,
            fetchMembershipTypesResult: apiCallSuccess,
        }),

        [actions.updateMembershipPending]: (state) => ({
            ...state,
            updatingMembership: true,
            updateMembershipResult: undefined,
            fetchMembershipMembersResult: undefined,
            updateMembershipErrors: undefined,
        }),
        [actions.updateMembershipError]: (state, action) => ({
            ...state,
            updatingMembership: false,
            updateMembershipResult: apiCallError,
            updateMembershipErrors: action.payload,
        }),
        [actions.updateMembershipCompleted]: (state, action) => ({
            ...state,
            updatingMembership: false,
            updateMembershipResult: apiCallSuccess,
            membership: {
                ...action.payload,
                members: state.membership.members,
            },
        }),
        [actions.resetUpdateMembershipErrors]: (state) => ({
            ...state,
            updateMembershipErrors: undefined,
        }),

        [actions.changePasswordPending]: (state) => ({
            ...state,
            chagingPassword: true,
            changePasswordResult: undefined,
        }),
        [actions.changePasswordError]: (state) => ({
            ...state,
            chagingPassword: false,
            changePasswordResult: apiCallError,
        }),
        [actions.changePasswordCompleted]: (state) => ({
            ...state,
            chagingPassword: false,
            changePasswordResult: apiCallSuccess,
        }),

        [actions.inviteMemberPending]: (state) => ({
            ...state,
            invitingMember: true,
            inviteMemberResult: undefined,
        }),
        [actions.inviteMemberCompleted]: (state) => ({
            ...state,
            invitingMember: false,
            inviteMemberResult: apiCallSuccess,
        }),
        [actions.inviteMemberError]: (state) => ({
            ...state,
            invitingMember: false,
            inviteMemberResult: apiCallError,
        }),
        [actions.resetInviteMemberResult]: (state) => ({
            ...state,
            inviteMemberResult: undefined,
        }),

        [actions.resetUpdateMembershipApiResult]: (state) => ({
            ...state,
            updateMembershipResult: undefined,
        }),
        [actions.removeMembershipMemberPending]: (state) => ({
            ...state,
            removingMembershipMember: true,
            removeMembershipMemberResult: undefined,
        }),
        [actions.removeMembershipMemberError]: (state) => ({
            ...state,
            removingMembershipMember: false,
            removeMembershipMemberResult: apiCallError,
        }),
        [actions.removeMembershipMemberCompleted]: (state, action) => ({
            ...state,
            removingMembershipMember: false,
            removeMembershipMemberResult: apiCallSuccess,
        }),
        [actions.fetchMembershipMembersPending]: (state) => ({
            ...state,
            fetchingMembershipMembers: true,
            fetchMembershipMembersResult: undefined,
        }),
        [actions.fetchMembershipMembersError]: (state) => ({
            ...state,
            fetchingMembershipMembers: false,
            fetchMembershipMembersResult: apiCallError,
        }),
        [actions.fetchMembershipMembersCompleted]: (state, action) => ({
            ...state,
            membership: action.payload.membership,
            fetchingMembershipMembers: false,
            fetchMembershipMembersResult: apiCallSuccess,
        }),
        [actions.updateMembershipMembers]: (state, action) => ({
            ...state,
            membership: {
                ...state.membership,
                members: action.payload,
            },
        }),
        CLEAR_STATE: (state) => ({
            ...defaultDataState,
            membershipTypes: state.membershipTypes,
            // keep membership types in case of logout in /join
            // this is open information
        }),
    },
    defaultDataState
);

const reducer = combineReducers({
    data:dataReducer,
});

export default reducer;
