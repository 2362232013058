import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiCallError, apiCallSuccess } from '../../../../utilities/constants';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import reservationMessagesReducer from '../components/ReservationPage/ReservationMessages/duck/reservationMessageReducers';
import { persistReducer } from 'redux-persist';

export interface ReservationsState {
    fetchingReservations?: boolean;

    fetchingActiveReservations?: boolean;
    activeReservationList?: object[];

    fetchingHistoricReservations?: boolean;
    historicReservationList?: object[];
    historicReservationCount?: number;

    dialToOpenPending: boolean;
    dialToOpenError: boolean;
    dialToOpenCompleted: boolean;

    extensionHighLight?: boolean;

    fetchingReservationDrivers?: boolean;
    fetchReservationDriversResult?: string;
    reservationDrivers?: object[];

    updatingReservationDrivers?: boolean;
    updateReservationDriversResult?: string;

    fetchingReservationMessages?: boolean;
    fetchReservationMessagesResult?: string;
    reservationMessages?: object;

    fetchingReservation?: boolean;
    fetchReservationResult?: string;
    reservation?: object;

    extendReservationPending?: boolean;
    extendReservationError?: boolean;

    fetchReservationExtendabilityPending?: boolean;
    fetchReservationExtendabilityError?: boolean;
    reservationExtendability?: object;

    cancelReservationPending?: boolean;
    cancelReservationResult?: string;
    showCancelReservationSuccessModal?: boolean;
    cancelReservationSuccessModalText?: object;

    putReservationPending?: boolean;
    putReservationResult?: string;
    putReservationErrorType?: object;

    updatingReservationUserNote?: boolean;
    updateReservationUserNoteResult?: string;

    carDistances: object;

    fetchingFindCarInfo?: boolean;
    fetchingFindCarInfoResult?: string;

    fetchingReservationsByCarID?: boolean;
    reservationsByCarId: object[];
    unseenReservations: object[];

    fetchingEntityMessages?: boolean;
    fetchEntityMessagesResult?: string;
    entityMessages?: object;

    preLoadingActiveReservations?: boolean;
    preLoadedReservations?: object;
}

const initialState: ReservationsState = {
    activeReservationList: undefined,
    historicReservationList: undefined,
    dialToOpenPending: false,
    dialToOpenError: false,
    dialToOpenCompleted: false,
    carDistances: {},
    reservationsByCarId: [],
    unseenReservations: [],
};

const reservationsSlice = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        fetchActiveReservationsPending(state: ReservationsState) {
            state.fetchingActiveReservations = true;
        },
        fetchActiveReservationsCompleted(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.fetchingActiveReservations = false;
            state.activeReservationList = action.payload;
        },
        fetchActiveReservationsError(state: ReservationsState) {
            state.fetchingActiveReservations = false;
        },
        clearActiveReservations(state: ReservationsState) {
            state.activeReservationList = [];
        },

        fetchHistoricReservationsPending(state: ReservationsState) {
            state.fetchingHistoricReservations = true;
        },
        fetchHistoricReservationsCompleted(
            state: ReservationsState,
            action: PayloadAction<{
                totalElements: number;
                reservations: object[];
            }>
        ) {
            state.fetchingHistoricReservations = false;
            state.historicReservationList = action.payload.reservations;
            state.historicReservationCount = action.payload.totalElements;
        },
        fetchHistoricReservationsError(state: ReservationsState) {
            state.fetchingHistoricReservations = false;
        },

        dialToOpenPending(state: ReservationsState) {
            state.dialToOpenPending = true;
            state.dialToOpenError = false;
            state.dialToOpenCompleted = false;
        },
        dialToOpenCompleted(state: ReservationsState) {
            state.dialToOpenPending = false;
            state.dialToOpenError = false;
            state.dialToOpenCompleted = true;
        },
        dialToOpenError(state: ReservationsState) {
            state.dialToOpenPending = false;
            state.dialToOpenError = true;
            state.dialToOpenCompleted = false;
        },

        updateReservationsAfterExtension(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.activeReservationList = action.payload;
            state.extensionHighLight = true;
        },

        resetReservationExtensionHighLight(state: ReservationsState) {
            state.extensionHighLight = false;
        },

        fetchReservationDriversPending(state: ReservationsState) {
            state.fetchingReservationDrivers = true;
            state.fetchReservationDriversResult = undefined;
        },
        fetchReservationDriversCompleted(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.fetchingReservationDrivers = false;
            state.fetchReservationDriversResult = apiCallSuccess;
            state.reservationDrivers = action.payload;
        },
        fetchReservationDriversError(state: ReservationsState) {
            state.fetchingReservationDrivers = false;
            state.fetchReservationDriversResult = apiCallError;
        },

        setReservationDriverState(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.updateReservationDriversResult = undefined;
            state.reservationDrivers = action.payload;
        },

        updateReservationDriversPending(state: ReservationsState) {
            state.updatingReservationDrivers = true;
            state.updateReservationDriversResult = undefined;
        },
        updateReservationDriversCompleted(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.updatingReservationDrivers = false;
            state.updateReservationDriversResult = apiCallSuccess;
            state.reservationDrivers = action.payload;
        },
        updateReservationDriversError(state: ReservationsState) {
            state.updatingReservationDrivers = false;
            state.updateReservationDriversResult = apiCallError;
        },

        fetchReservationPending(state: ReservationsState) {
            state.fetchingReservation = true;
            state.fetchReservationResult = undefined;
        },
        fetchReservationCompleted(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.fetchingReservation = false;
            state.fetchReservationResult = apiCallSuccess;
            state.reservation = action.payload;
        },
        fetchReservationError(state: ReservationsState) {
            state.fetchingReservation = false;
            state.fetchReservationResult = apiCallError;
        },

        extendReservationPending(state: ReservationsState) {
            state.extendReservationPending = true;
            state.extendReservationError = false;
        },
        extendReservationCompleted(state: ReservationsState) {
            state.extendReservationPending = false;
        },
        extendReservationError(state: ReservationsState) {
            state.extendReservationPending = false;
            state.extendReservationError = true;
        },

        fetchReservationExtendabilityPending(state: ReservationsState) {
            state.fetchReservationExtendabilityPending = true;
            state.fetchReservationExtendabilityError = false;
        },
        fetchReservationExtendabilityCompleted(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.fetchReservationExtendabilityPending = false;
            state.reservationExtendability = action.payload;
        },
        fetchReservationExtendabilityError(state: ReservationsState) {
            state.fetchReservationExtendabilityPending = false;
            state.fetchReservationExtendabilityError = true;
        },

        cancelReservationPending(state: ReservationsState) {
            state.cancelReservationPending = true;
            state.cancelReservationResult = undefined;
        },
        cancelReservationCompleted(
            state: ReservationsState,
            action: PayloadAction<object>
        ) {
            state.cancelReservationPending = false;
            state.cancelReservationResult = apiCallSuccess;
            state.showCancelReservationSuccessModal = true;
            state.cancelReservationSuccessModalText = action.payload;
        },
        cancelReservationError(state: ReservationsState) {
            state.cancelReservationPending = false;
            state.cancelReservationResult = apiCallError;
        },
        hideCancellationSuccessModal(state: ReservationsState) {
            state.showCancelReservationSuccessModal = false;
            state.cancelReservationSuccessModalText = undefined;
        },

        putReservationPending(state: ReservationsState) {
            state.putReservationPending = true;
            state.putReservationResult = undefined;
        },
        putReservationCompleted(state: ReservationsState) {
            state.putReservationPending = false;
            state.putReservationResult = apiCallSuccess;
        },
        putReservationError(
            state: ReservationsState,
            action: PayloadAction<object>
        ) {
            state.putReservationPending = false;
            state.putReservationResult = apiCallError;
            state.putReservationErrorType = action.payload;
        },

        preLoadActiveReservationsPending(state: ReservationsState) {
            state.preLoadingActiveReservations = true;
        },
        preLoadActiveReservationsCompleted(state: ReservationsState) {
            state.preLoadingActiveReservations = false;
        },
        preLoadActiveReservationsError(state: ReservationsState) {
            state.preLoadingActiveReservations = false;
        },

        updateReservationUserNotePending(state: ReservationsState) {
            state.updatingReservationUserNote = true;
            state.updateReservationUserNoteResult = undefined;
        },
        updateReservationUserNoteCompleted(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.updatingReservationUserNote = false;
            state.updateReservationUserNoteResult = apiCallSuccess;
            state.reservation = action.payload;
        },
        updateReservationUserNoteError(state: ReservationsState) {
            state.updatingReservationUserNote = false;
            state.updateReservationUserNoteResult = apiCallError;
        },

        fetchCarDistanceCompleted(
            state: ReservationsState,
            action: PayloadAction<{ id: string; distance: string }>
        ) {
            state.fetchingReservation = false;
            state.carDistances = {
                ...state.carDistances,
                [action.payload.id]: action.payload.distance,
            };
        },

        fetchingFindCarInfoPending(state: ReservationsState) {
            state.fetchingFindCarInfo = true;
            state.fetchingFindCarInfoResult = undefined;
        },
        fetchingFindCarInfoCompleted(state: ReservationsState) {
            state.fetchingFindCarInfo = false;
            state.fetchingFindCarInfoResult = apiCallSuccess;
        },
        fetchingFindCarInfoError(state: ReservationsState) {
            state.fetchingFindCarInfo = false;
            state.fetchingFindCarInfoResult = apiCallError;
        },

        setUnseenReservations(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.fetchingFindCarInfo = false;
            state.unseenReservations = action.payload;
        },

        resetSelectedReservation(state: ReservationsState) {
            state.reservation = undefined;
        },

        fetchEntityMessagesCompleted(
            state: ReservationsState,
            action: PayloadAction<object[]>
        ) {
            state.entityMessages = action.payload;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase('CLEAR_STATE', () => initialState);
    },
});

export const {
    fetchActiveReservationsPending,
    fetchActiveReservationsCompleted,
    fetchActiveReservationsError,
    clearActiveReservations,
    fetchHistoricReservationsPending,
    fetchHistoricReservationsCompleted,
    fetchHistoricReservationsError,
    dialToOpenPending,
    dialToOpenCompleted,
    dialToOpenError,
    updateReservationsAfterExtension,
    resetReservationExtensionHighLight,
    fetchReservationDriversPending,
    fetchReservationDriversCompleted,
    fetchReservationDriversError,
    setReservationDriverState,
    updateReservationDriversPending,
    updateReservationDriversCompleted,
    updateReservationDriversError,
    fetchReservationPending,
    fetchReservationCompleted,
    fetchReservationError,
    extendReservationPending,
    extendReservationCompleted,
    extendReservationError,
    fetchReservationExtendabilityPending,
    fetchReservationExtendabilityCompleted,
    fetchReservationExtendabilityError,
    cancelReservationPending,
    cancelReservationCompleted,
    cancelReservationError,
    hideCancellationSuccessModal,
    putReservationPending,
    putReservationCompleted,
    putReservationError,
    preLoadActiveReservationsPending,
    preLoadActiveReservationsCompleted,
    preLoadActiveReservationsError,
    updateReservationUserNotePending,
    updateReservationUserNoteCompleted,
    updateReservationUserNoteError,
    fetchCarDistanceCompleted,
    fetchingFindCarInfoPending,
    fetchingFindCarInfoCompleted,
    fetchingFindCarInfoError,
    fetchEntityMessagesCompleted,
    resetSelectedReservation,
} = reservationsSlice.actions;

// export default reservationsSlice.reducer;

const reservationsPersistConfig = {
    key: 'reservations',
    storage: storage,
    whitelist: ['preLoadedReservations'],
};
const reducer = combineReducers({
    data: persistReducer(reservationsPersistConfig, reservationsSlice.reducer),
    messages: reservationMessagesReducer,
});

export default reducer;
