import { createSlice } from '@reduxjs/toolkit';
import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../../utilities/constants';
import { InvoiceLine } from '../../../../../types/admin';
import { InvoiceLineState } from './types';

const initialState: InvoiceLineState = {
    savingInvoiceLine: false,
    fetchingInvoiceLines: false,
    invoiceLines: {},
};

const adminBillingSlice = createSlice({
    name: 'invoice',
    initialState,
    reducers: {
        postInvoiceLinePending(state: InvoiceLineState) {
            state.saveInvoiceLineResult = undefined;
            state.savingInvoiceLine = true;
        },
        postInvoiceLineCompleted(
            state: InvoiceLineState,
            action: {
                payload: { id: string; index: number; line: InvoiceLine };
            }
        ) {
            let copy = [...state.invoiceLines[action.payload.id]];
            copy.push(action.payload.line);
            state.savingInvoiceLine = false;
            state.saveInvoiceLineResult = apiCallSuccess;
            state.invoiceLines = {
                ...state.invoiceLines,
                [action.payload.id]: copy,
            };
        },
        postInvoiceLineError(state: InvoiceLineState) {
            state.saveInvoiceLineResult = apiCallError;
            state.savingInvoiceLine = false;
        },
        updateInvoiceLine(
            state: InvoiceLineState,
            action: {
                payload: { id: string; index: number; line: InvoiceLine };
            }
        ) {
            let copy = [...state.invoiceLines[action.payload.id]];
            copy[action.payload.index] = action.payload.line;
            state.invoiceLines = {
                ...state.invoiceLines,
                [action.payload.id]: copy,
            };
        },
        putUpdatedInvoiceLinePending(state: InvoiceLineState) {
            state.saveInvoiceLineResult = undefined;
            state.savingInvoiceLine = true;
        },
        putUpdatedInvoiceLineCompleted(
            state: InvoiceLineState,
            action: {
                payload: { id: string; index: number; line: InvoiceLine };
            }
        ) {
            let copy = [...state.invoiceLines[action.payload.id]];
            copy[action.payload.index] = action.payload.line;
            state.savingInvoiceLine = false;
            state.saveInvoiceLineResult = apiCallSuccess;
            state.invoiceLines = {
                ...state.invoiceLines,
                [action.payload.id]: copy,
            };
        },
        putUpdatedInvoiceLineError(state: InvoiceLineState) {
            state.saveInvoiceLineResult = apiCallError;
            state.savingInvoiceLine = false;
        },
        deleteInvoiceLineCompleted(
            state: InvoiceLineState,
            action: { payload: { id: string; lineId: string } }
        ) {
            state.invoiceLines = {
                ...state.invoiceLines,
                [action.payload.id]: state.invoiceLines[
                    action.payload.id
                ].filter((line) => line.id !== action.payload.lineId),
            };
        },
        fetchingInvoiceLinesPending(state: InvoiceLineState) {
            state.fetchingInvoiceLines = true;
        },
        fetchInvoiceLinesCompleted(
            state: InvoiceLineState,
            action: { payload: { id: string; lines: Array<InvoiceLine> } }
        ) {
            state.fetchInvoiceLinesResult = apiCallSuccess;
            state.fetchingInvoiceLines = false;
            state.invoiceLines = {
                ...state.invoiceLines,
                [action.payload.id]: action.payload.lines,
            };
        },
        fetchInvoiceLinesError(state: InvoiceLineState) {
            state.fetchInvoiceLinesResult = apiCallError;
            state.fetchingInvoiceLines = false;
        },
        clearLocalInvoiceLines(state: InvoiceLineState) {
            state.invoiceLines = {};
        },
    },
});

export const {
    postInvoiceLinePending,
    postInvoiceLineCompleted,
    postInvoiceLineError,
    updateInvoiceLine,
    putUpdatedInvoiceLinePending,
    putUpdatedInvoiceLineCompleted,
    putUpdatedInvoiceLineError,
    deleteInvoiceLineCompleted,
    fetchingInvoiceLinesPending,
    fetchInvoiceLinesCompleted,
    fetchInvoiceLinesError,
    clearLocalInvoiceLines,
} = adminBillingSlice.actions;

const invoiceLineReducer = adminBillingSlice.reducer;
export default invoiceLineReducer;
