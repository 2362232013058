import React from 'react';
import { P } from '../../Typography';

export const OfflineAlert = () => {
    if (navigator.onLine) return <></>;

    return (
        <div className="offlineAlertWrapper">
            <div className="offlineAlert">
                <P>Du er offline</P>
                <P small>Det er ikke alt som funker nå</P>
            </div>
        </div>
    );
};
