import {
    apiCallError,
    apiCallSuccess,
} from '../../../../../../utilities/constants';
import { handleActions } from 'redux-actions';
import actions from './actions';

const defaultEntityMessageState = {
    fetchingAdminCars: false,
    fetchAdminCarsResult: undefined,
    adminCars: undefined,
};

const entityMessageReducer = handleActions(
    {
        [actions.fetchAdminEntityMessagesPending]: (state) => ({
            ...state,
            fetchingEntityMessages: true,
            fetchEntityMessagesResult: undefined,
        }),
        [actions.fetchAdminEntityMessagesCompleted]: (state, action) => ({
            ...state,
            fetchingEntityMessages: false,
            fetchEntityMessagesResult: apiCallSuccess,
            entityMessages: action.payload,
        }),
        [actions.fetchAdminEntityMessagesError]: (state) => ({
            ...state,
            fetchingEntityMessages: false,
            fetchEntityMessagesResult: apiCallError,
        }),

        [actions.fetchAdminEntityMessagesMetadataPending]: (state) => ({
            ...state,
            fetchingEntityMessagesMetadata: true,
            fetchEntityMessagesMetadataResult: undefined,
        }),
        [actions.fetchAdminEntityMessagesMetadataCompleted]: (
            state,
            action
        ) => ({
            ...state,
            fetchingEntityMessagesMetadata: false,
            fetchEntityMessagesMetadataResult: apiCallSuccess,
            entityMessagesMetadata: action.payload,
        }),
        [actions.fetchAdminEntityMessagesMetadataError]: (state) => ({
            ...state,
            fetchingEntityMessagesMetadata: false,
            fetchEntityMessagesMetadataResult: apiCallError,
        }),

        [actions.fetchAdminEntityMessagePending]: (state) => ({
            ...state,
            fetchingEntityMessage: true,
            fetchEntityMessageResult: undefined,
        }),
        [actions.fetchAdminEntityMessageCompleted]: (state, action) => ({
            ...state,
            fetchingEntityMessage: false,
            fetchEntityMessageResult: apiCallSuccess,
            entityMessage: action.payload,
        }),
        [actions.fetchAdminEntityMessageError]: (state) => ({
            ...state,
            fetchingEntityMessage: false,
            fetchEntityMessageResult: apiCallError,
        }),

        [actions.deleteAdminEntityMessageCompleted]: (state) => ({
            ...state,
            entityMessage: undefined,
        }),

        [actions.saveAdminEntityMessagePending]: (state) => ({
            ...state,
            fetchingEntityMessage: true,
            fetchEntityMessageResult: undefined,
        }),
        [actions.saveAdminEntityMessageCompleted]: (state, action) => ({
            ...state,
            savingEntityMessage: false,
            saveEntityMessageResult: apiCallSuccess,
            entityMessage: action.payload,
        }),
        [actions.saveAdminEntityMessageError]: (state) => ({
            ...state,
            savingEntityMessage: false,
            saveEntityMessageResult: apiCallError,
        }),

        CLEAR_STATE: () => defaultEntityMessageState,
    },
    defaultEntityMessageState
);

export default entityMessageReducer;
